import { yupResolver } from "@hookform/resolvers/yup";
import { Microphone } from "assets/images";
import {
  Button,
  CustomAudioPlayer,
  Input,
  Recorder,
  Spinner,
} from "components";
import AudioVisualizer from "components/AudioVisualizer";
import { toastEmitter } from "components/Toast";
import moment from "moment";
import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { alphaNumericRegx, getRequiredText } from "shared/resources";
import * as yup from "yup";

const validationSchema = yup.object().shape({
  file_name: yup.string().required(getRequiredText("Name")),
  valid_file: yup.mixed(),
});

const RecordForm = ({
  onSuccess = () => null,
  mediaUploadLoad = false,
  mediaUploadAction = () => null,
  type = "",
}) => {
  const [isRecording, setIsRecording] = useState(false);
  const [playRecordedFile, setPlayRecordedFile] = useState(false);
  const [blobUrl, setBlobUrl] = useState("");
  const [recordedFile, setRecordedFile] = useState(null);
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      file_name: "",
      valid_file: null,
    },
    mode: "onChange",
  });

  const getAudioBlob = (metaData) => {
    const file = new File(
      metaData?.buffer,
      `greetingRecording${moment().unix()}.mp3`,
      {
        type: metaData?.blob.type,
        lastModified: Date.now(),
        size: metaData?.blob.size,
      }
    );

    setRecordedFile(file);
    setValue("valid_file", file);
    const blobURL = window.URL.createObjectURL(metaData?.blob);
    setBlobUrl(blobURL);
  };

  const handleSave = async (values) => {
    const res = await mediaUploadAction({
      type: "greeting",
      file: recordedFile,
    });
    if (res) {
      onSuccess({
        action_type: type,
        name: values?.file_name,
        filename: res?.file_name,
        file: recordedFile,
        type: "R",
        duration: res?.duration,
      });
    } else {
      return null;
    }
  };

  const handleStartRecording = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(function () {
        setIsRecording(true);
      })
      .catch(function () {
        toastEmitter(
          "error",
          "Error accessing microphone. Microphone permission denied. Please grant microphone access to continue recording."
        );
      });
  };
  return (
    <div
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          handleSubmit(handleSave)();
          e.preventDefault();
          e.stopPropagation();
        }
      }}
    >
      <div>
        <Controller
          name="file_name"
          control={control}
          render={({ field }) => {
            const { onChange } = field;
            return (
              <Input
                {...field}
                onChange={(e) => {
                  if (alphaNumericRegx.test(e.target.value)) {
                    onChange(e.target.value);
                  } else {
                    return;
                  }
                }}
                onWhitebg={true}
                label="Name"
                error={errors?.file_name?.message}
                maxLength={50}
              />
            );
          }}
        />
      </div>

      {isRecording && (
        <div className="flex flex-col gap-1 mt-5">
          <div className="text-grey-800 xxl:text-base xl:text-sm	 lg:text-sm md:text-xs	 font-medium">
            {t("overview.recordGreeting")}
          </div>
          <div className="flex items-center justify-between border border-green rounded-md px-3 py-2.5 xxl:h-[56px] xl:h-[45px] lg:h-[45px] md:h-[41px] sm:h-[40px] xs:h-[40px] ">
            <span className="bg-green-100 xxl:w-[45px] xl:w-[35px] lg:w-[35px] md:w-[35px] xxl:h-[45px] xl:h-[35px] lg:h-[35px] md:h-[35px] rounded-full text-green flex items-center justify-center">
              <Microphone />
            </span>
            <AudioVisualizer barWidth={2} height={30} />
            <div className="font-medium text-sm text-grey-600 min-w-[45px] flex justify-center items-center">
              <Recorder getAudioBlob={getAudioBlob} />
            </div>
          </div>
        </div>
      )}
      {!isRecording && !playRecordedFile && (
        <div className="flex flex-col gap-1 mt-5">
          <div className="text-grey-800 xxl:text-base xl:text-sm	lg:text-sm md:text-xs font-medium">
            {t("overview.recordGreeting")}
          </div>
          <Button
            background="transparent"
            border="border-green"
            type="button"
            onClick={() => handleStartRecording()}
          >
            <div className="text-green font-medium text-sm">
              {t("overview.startRecording")}
            </div>
          </Button>
        </div>
      )}
      <div className="w-full flex justify-center">
        {playRecordedFile && blobUrl && (
          <div className="flex flex-col gap-5 mt-5">
            <label className="text-grey-800 xxl:text-base xl:text-sm	lg:text-sm md:text-xs font-medium">
              {t("overview.recordedGreeting")}
            </label>
            <CustomAudioPlayer
              srcfile={recordedFile}
              barWidth={2}
              withIcon={false}
            />
          </div>
        )}
      </div>

      <div className="flex justify-between mt-10 gap-4">
        {isRecording ? (
          <>
            <Button
              background="transparent"
              border="border-green"
              color="text-green"
              type="button"
              onClick={() => {
                setIsRecording(false);
                setTimeout(() => {
                  setRecordedFile(null);
                }, 500);
              }}
            >
              {t("overview.cancel")}
            </Button>
            <Button
              type="button"
              onClick={() => {
                setPlayRecordedFile(true);
                setIsRecording(false);
              }}
            >
              {t("overview.save")}
            </Button>
          </>
        ) : null}
        {recordedFile && blobUrl && (
          <>
            <Button
              type="button"
              border="border-green"
              background="transparent"
              onClick={() => {
                setIsRecording(true);
                setPlayRecordedFile(false);
                setBlobUrl("");
              }}
            >
              <div className="text-green">{t("overview.recordAgain")}</div>
            </Button>
            <Button
              type="button"
              disabled={!playRecordedFile || mediaUploadLoad}
              onClick={() => {
                handleSubmit(handleSave)();
              }}
            >
              {mediaUploadLoad ? <Spinner /> : "Save"}
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default RecordForm;
