import { Button } from "components";
import { Edit, StopCircle, Trash } from "lucide-react";
import React from "react";
import FlowChart from "./FlowChart";

const Flow = () => {
  return (
    <div className="relative h-[90vh]">
      <div className="py-2 px-4 border-b border-grey-400 w-full">
        <div className="my-4 flex justify-between">
          <div className="flex justify-between items-center gap-4">
            <p className="font-bold text-[24px]" contentEditable>
              Template Edit
            </p>
            <Edit className="w-5 h-5 cursor-pointer" />
          </div>
          <div className="flex items-center gap-4 w-[150px]">
            <p className="text-sm flex gap-2 text-grey-500">
              <span>ID</span>
              <span> #</span> <span>44</span>
            </p>
            <Button>Live</Button>
          </div>
        </div>
      </div>
      {/* Flow design */}
      <FlowChart />
      <div className="absolute bottom-[10px] flex w-full justify-between p-4 border-t border-grey-400">
        <div className="flex items-center gap-4">
          <button className="w-[100px] rounded-full gap-2 justify-center items-center flex h-[40px] text-[14px] text-danger border-danger border">
            <Trash className="w-5 h-5" />
            <span>Delete</span>
          </button>
          <button className="w-[100px] rounded-full gap-2 justify-center items-center flex h-[40px] text-[14px] text-danger border-danger border">
            <StopCircle className="w-5 h-5" />
            <span>Stop</span>
          </button>
        </div>
        <div className="flex items-center gap-4">
          <button className="w-[180px] rounded-full gap-2 justify-center items-center flex h-[40px] text-[14px] text-black border">
            Duplicate
          </button>
          <button className="w-[200px] rounded-full gap-2 justify-center items-center flex h-[40px] text-[14px] text-black border">
            Save as Draft
          </button>
          <div className="w-[200px]">
            <Button>Save as Live</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Flow;
