import { IntercomIcon } from "assets/images";
import { Button } from "components";
import React, { useState, useEffect } from "react";
import { routes } from "api/routes";
import { getToken } from "shared/resources";

const Intercom = () => {
  const CLIENT_ID = process.env.REACT_APP_INTERCOM_CLIENT_ID;
  const BACKEND_URL = process.env.REACT_APP_BASE_API_URL;
  // const REDIRECT_URI = "http://localhost:3000";

  const [setMessage] = useState("");

  const generateState = () => {
    return (
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15)
    );
  };

  const handleAuthorize = () => {
    const state = generateState();
    sessionStorage.setItem("oauthState", state);
    const authUrl = `https://app.intercom.com/oauth?client_id=${CLIENT_ID}&state=${state}`;
    window.location.href = authUrl;
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    const state = urlParams.get("state");

    if (code && state) {
      const savedState = sessionStorage.getItem("oauthState");
      if (savedState !== state) {
        setMessage("State mismatch! Possible CSRF attack.");
      } else {
        setMessage(
          "Authorization code received! Exchanging for an access token..."
        );
        fetch(`${BACKEND_URL}${routes.CREATE_INTERCOM_INTEGRATION.URL}`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify({ code }),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.access_token) {
              setMessage("Access token received! You're all set.");
            } else {
              setMessage(
                "Failed to exchange authorization code for access token."
              );
            }
          })
          .catch((error) => {
            console.error("Error:", error);
            setMessage("An error occurred while exchanging the code.");
          });
      }
    }
  }, []);

  return (
    <div
      className={`h-[236px] bg-[#F1F1F1] shadow-md flex flex-col justify-center px-6`}
    >
      <div className="flex flex-col gap-7">
        <div className="flex items-center space-x-2">
          <IntercomIcon className="w-7 h-7" />
          <p className="">Intercom</p>
        </div>
        <div className="flex flex-col gap-6">
          <p className="font-bold text-[#0D121D]">
            This integration allows you to handle the live chat in Intercom when
            the bot automation is paused.
          </p>

          <div className="w-[400px]">
            <Button onClick={handleAuthorize}>Connect Intercom Account</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Intercom;
