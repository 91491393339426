import { EyeClose, EyeOpen, Search } from "assets/images";
import Spinner from "components/Spinner";
import React, { useId, useState } from "react";

export const baseInputLabelClasses =
  "text-grey-800 xxl:text-base xl:text-sm	lg:text-sm md:text-xs sm:text-sm xs:text-sm font-medium";

export const baseInputClasses =
  "w-full border  rounded-md  focus:outline-none focus:ring focus:bg-inherit font-medium text-base text-grey-900";

const Input = (
  {
    type = "text",
    placeholder = "",
    extraClasses = "",
    label = "",
    error = "",
    helperText = "",
    optional = false,
    onWhitebg = false,
    isServerValidationLoading = false,
    withSearchIcon = false,
    height = "xxl:h-[56px] xl:h-[45px] lg:h-[45px] md:h-[41px] sm:h-[40px] xs:h-[40px]",
    required = false,
    ...rest
  },
  ref
) => {
  const id = useId();
  const [showPassword, setShowPassword] = useState(false);
  const conditioalClasses = error
    ? "border-danger focus:border-danger ring ring-danger-100 focus:ring-danger-100 bg-danger-100 focus:bg-danger-100"
    : `border-grey-400 focus:border-green focus:ring-green-200 ${
        onWhitebg ? "bg-grey-100 " : "bg-transparent"
      } `;
  return (
    <div className="w-full">
      <div className="flex flex-col gap-1">
        {label || isServerValidationLoading ? (
          <div className="flex justify-between items-center">
            {label ? (
              <label htmlFor={label} className={baseInputLabelClasses}>
                {label}
                {optional ? (
                  <span className="text-grey-700 text-base italic ">
                    (Optional)
                  </span>
                ) : null}
                {required ? <span className="text-danger">*</span> : null}
              </label>
            ) : null}
            {isServerValidationLoading ? <Spinner /> : null}
          </div>
        ) : null}
        <div className="relative flex items-center ">
          {withSearchIcon ? (
            <div className="absolute left-2.5  font-medium text-grey-700">
              <Search />
            </div>
          ) : null}
          <input
            {...rest}
            ref={ref}
            id={id}
            autoComplete={type === "password" ? "new-password" : "off"}
            type={showPassword ? "text" : type}
            placeholder={placeholder}
            className={
              extraClasses +
              " " +
              baseInputClasses +
              " " +
              conditioalClasses +
              " " +
              height
            }
          />

          {type === "password" ? (
            <span
              className="absolute right-4"
              onClick={() => setShowPassword((prev) => !prev)}
            >
              {showPassword ? (
                <EyeOpen className="xxl:w-[22px] xl:w-[20px] lg:w-[20px] md:w-[18px] sm:w-[18px] xs:w-[15px]" />
              ) : (
                <EyeClose className="xxl:w-[22px] xl:w-[20px] lg:w-[20px] md:w-[18px] sm:w-[18px] xs:w-[15px]" />
              )}
            </span>
          ) : null}
        </div>
      </div>

      <div className="flex  flex-col">
        {error ? (
          <small className="text-danger text-xs	font-normal mt-1">{error}</small>
        ) : null}

        {helperText ? (
          <small className="text-grey-700 text-sm">{helperText}</small>
        ) : null}
      </div>
    </div>
  );
};
export default React.forwardRef(Input);
