import React, { useState } from "react";
import Personal from "./Personal";
import { useAuth } from "hooks/useAuth";
import { ADMIN, MEMBER, OWNER } from "shared/resources";
import Business from "./Business";
import { useTranslation } from "react-i18next";

const Profile = () => {
  const [tab, setTab] = useState("personal");
  const { t } = useTranslation();
  const { user } = useAuth();
  const SettingsArray = [
    {
      id: 1,
      label: t("profile.personal"),
      value: "personal",
      access: [ADMIN, OWNER, MEMBER],
    },
    {
      id: 2,
      label: t("profile.business"),
      value: "business",
      access: [ADMIN, OWNER],
    },
  ];
  return (
    <div className="overflow-auto w-full px-6 pt-6 pb-6">
      <div className="flex items-center justify-between mb-5">
        <div className="text-sm text-grey-700">{t("profile.manageFonu")}</div>
      </div>
      <div className="table w-full rounded-md border border-grey-400 bg-white ">
        <div className="flex items-center w-full border-b border-grey-300 gap-[34px] px-6 pt-6 ">
          {SettingsArray.map((setting) => {
            if (
              setting?.access?.length === 0 ||
              !setting.access.includes(user?.role_id)
            ) {
              return;
            }
            return (
              <div
                key={setting?.id}
                onClick={() => setTab(setting?.value)}
                className="flex justify-center items-center group relative"
              >
                <div
                  className={`${
                    tab === setting?.value
                      ? "text-grey-900 font-semibold"
                      : "text-grey-600"
                  } text-base  cursor-pointer mb-2`}
                >
                  {setting?.label}
                </div>
                {tab === setting?.value && (
                  <div className="group-hover:visible w-6 h-0.5 bg-grey-900  absolute top-[31px] visible rounded-full"></div>
                )}
              </div>
            );
          })}
        </div>

        {tab === "personal" ? <Personal /> : <Business />}
      </div>
    </div>
  );
};

export default Profile;
