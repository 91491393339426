/* global FB */
import { useEffect } from "react";

const useFacebookChannel = ({ appId, graphApiVersion, configId }) => {
  useEffect(() => {
    const loadFacebookSDK = () => {
      if (!window.FB) {
        const script = document.createElement("script");
        script.src = "https://connect.facebook.net/en_US/sdk.js";
        script.async = true;
        script.defer = true;
        script.crossOrigin = "anonymous";
        script.onload = () => {
          window.fbAsyncInit = () => {
            FB.init({
              appId: appId,
              autoLogAppEvents: true,
              xfbml: true,
              version: graphApiVersion || "v21.0",
            });
          };
        };
        document.body.appendChild(script);
      }
    };

    loadFacebookSDK();

    const handleMessageEvent = (event) => {
      if (
        event.origin !== "https://www.facebook.com" &&
        event.origin !== "https://web.facebook.com"
      )
        return;

      try {
        const data = JSON.parse(event.data);
        if (data.type === "WA_EMBEDDED_SIGNUP") {
          console.log("message event: ", data);
        }
      } catch {
        console.log("message event: ", event.data);
      }
    };

    window.addEventListener("message", handleMessageEvent);

    return () => {
      window.removeEventListener("message", handleMessageEvent);
    };
  }, [appId, graphApiVersion]);

  const fbLoginCallback = (response) => {
    if (response.authResponse) {
      const code = response.authResponse.code;
      console.log("response: ", code);
    } else {
      console.log("response: ", response);
    }
  };

  const launchWhatsAppSignup = () => {
    if (window.FB) {
      FB.login(fbLoginCallback, {
        config_id: configId,
        response_type: "code",
        override_default_response_type: true,
        extras: {
          setup: {},
          featureType: "",
          sessionInfoVersion: "3",
        },
      });
    } else {
      console.error("Facebook SDK not loaded yet.");
    }
  };

  return {
    launchWhatsAppSignup,
  };
};

export default useFacebookChannel;
