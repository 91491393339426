import { Button, Input } from "components";
import React from "react";

const Gemini = () => {
  return (
    <div className="bg-[#f1f1f1] shadow-md h-[350px] w-[90%] m-auto">
      <div className="p-6 border-b border-black/40 font-bold text-[14px]">
        <p>
          <span className="text-blue-light">Sign Up</span> for a Gemini account
        </p>
      </div>
      <div className="p-6">
        <form className="mt-7 flex flex-col w-[80%] m-auto gap-5">
          <div className="flex items-center justify-center gap-4">
            <label className="w-[250px]">Api Key</label>
            <Input placeholder="Api Key" />
          </div>

          <div className="w-full justify-between flex items-center gap-4">
            <div className="w-[250px]"></div>
            <div className="w-full justify-between flex items-center">
              <div className="w-[100px]">
                <Button type="button">Save</Button>
              </div>
              <div className="w-[100px]">
                <button className="bg-[#EF4444] xxl:h-[56px] xl:h-[45px] lg:h-[45px] md:h-[41px] sm:h-[40px] xs:h-[40px] w-full rounded-md text-white">
                  Clear
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Gemini;
