import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Input from "components/Input";
import useCreateContact from "hooks/useCreateContact";
import { Spinner } from "components";
import { Plus, Trash } from "assets/images";
import useAllBatches from "hooks/useAllBatches";
import { useTranslation } from "react-i18next";

function AddNewContact({ handleContactModalClose, mutate }) {
  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm({
    mode: "onSubmit",
  });

  const { mutateAsync: createContactMutate, isPending: createContactLoading } =
    useCreateContact();
  const [additionalFields, setAdditionalFields] = useState([]);
  const [additionalDetails, setAdditionalDetails] = useState([]);
  const { allBatchesMutate, allBatchesData } = useAllBatches();
  const [selectedBatchUuid, setSelectedBatchUuid] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    allBatchesMutate();
  }, [allBatchesMutate]);
  const handlePhoneChange = (e) => {
    const { value } = e.target;
    let phoneNumber = value;

    if (phoneNumber.length > 17) {
      phoneNumber = phoneNumber.slice(0, 17);
    }

    setValue("phone", phoneNumber);
    return phoneNumber;
  };

  const handleAddNewFields = (e) => {
    e.preventDefault();
    setAdditionalFields([
      ...additionalFields,
      {
        title: `title_${additionalFields.length}`,
        content: `content_${additionalFields.length}`,
      },
    ]);
  };

  const handleDeleteField = (index) => {
    setAdditionalFields(additionalFields.filter((_, i) => i !== index));
    setAdditionalDetails(additionalDetails.filter((_, i) => i !== index));
  };
  // console.log(allBatchesData);
  return (
    <div className="relative">
      <h2 className="text-[20px] font-bold text-center mb-6">
        {t("overview.createNewContact")}
      </h2>
      <p className="text-[18px] font-semibold">
        {t("overview.contactDetails")}
      </p>
      <form
        className="flex flex-col gap-4 my-8"
        onSubmit={handleSubmit(async (values) => {
          if (!selectedBatchUuid) {
            return;
          }
          await createContactMutate({
            ...values,
            batch_uuid: selectedBatchUuid,
            additional_details: JSON.stringify(additionalDetails),
          });
          handleContactModalClose();
          mutate({
            page: 1,
            limit: 1000,
            batch_uuid: selectedBatchUuid,
          });
        })}
      >
        <button
          type="submit"
          className="bg-green-400 text-white absolute right-0 top-11 w-[100px] h-[40px] rounded flex items-center justify-center"
        >
          {createContactLoading ? <Spinner /> : t("overview.submit")}
        </button>
        <div>
          <Controller
            name=""
            control={control}
            rules={{ required: t("overview.selectBatch") }}
            render={({ field }) => (
              <select
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                  setSelectedBatchUuid(e.target.value);
                }}
                required={true}
              >
                <option value="">{t("overview.selectBatch")}</option>
                {allBatchesData?.data?.data?.result.map((batch) => (
                  <option value={batch.uuid} key={batch.uuid}>
                    {batch.name}
                  </option>
                ))}
              </select>
            )}
          />
        </div>
        <div>
          <Controller
            name="first_name"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                onWhitebg={true}
                label={t("overview.firstName")}
                maxLength={50}
                error={errors?.first_name?.message}
                required={true}
                onChange={(event) => {
                  setValue("first_name", event.target.value);
                }}
              />
            )}
          />
        </div>
        <div>
          <Controller
            name="last_name"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                onWhitebg={true}
                label={t("overview.lastName")}
                error={errors?.last_name?.message}
                maxLength={50}
                onChange={(event) => {
                  setValue("last_name", event.target.value);
                }}
              />
            )}
          />
        </div>
        <div>
          <Controller
            name="phone"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                onWhitebg={true}
                label={t("overview.phone")}
                error={errors?.phone?.message}
                autoComplete="off"
                maxLength={14}
                onChange={(e) => field.onChange(handlePhoneChange(e))}
                required={true}
              />
            )}
          />
        </div>
        <div>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                onWhitebg={true}
                label={t("overview.email")}
                error={errors?.email?.message}
                maxLength={50}
                onChange={(event) => {
                  setValue("email", event.target.value);
                }}
              />
            )}
          />
        </div>

        <div className="flex flex-col gap-3 w-full text-[16px]">
          <p className="text-[18px] py-3 font-bold">
            {t("overview.residentAddress")}
          </p>
          <div>
            <Controller
              name="street"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  onWhitebg={true}
                  label={t("overview.street")}
                  error={errors?.street?.message}
                  maxLength={50}
                  onChange={(event) => {
                    setValue("street", event.target.value);
                  }}
                />
              )}
            />
          </div>

          <div>
            <Controller
              name="city"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  onWhitebg={true}
                  label={t("overview.city")}
                  error={errors?.city?.message}
                  maxLength={50}
                  onChange={(event) => {
                    setValue("city", event.target.value);
                  }}
                />
              )}
            />
          </div>
          <div>
            <Controller
              name="state"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  onWhitebg={true}
                  label={t("overview.state")}
                  error={errors?.state?.message}
                  maxLength={50}
                  onChange={(event) => {
                    setValue("state", event.target.value);
                  }}
                />
              )}
            />
          </div>

          <div>
            <Controller
              name="postal_code"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  onWhitebg={true}
                  label={t("overview.postalCode")}
                  error={errors?.postal_code?.message}
                  maxLength={50}
                  onChange={(event) => {
                    setValue("postal_code", event.target.value);
                  }}
                />
              )}
            />
          </div>
          <div>
            <Controller
              name="country"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  onWhitebg={true}
                  label={t("overview.country")}
                  error={errors?.country?.message}
                  maxLength={50}
                  onChange={(event) => {
                    setValue("country", event.target.value);
                  }}
                />
              )}
            />
          </div>
        </div>
        <p className="text-[18px] py-3 font-bold">Work Details</p>
        <div className="flex flex-col gap-3 w-full text-[16px]">
          <div>
            <Controller
              name="company_name"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  onWhitebg={true}
                  label={t("overview.company")}
                  placeholder={t("overview.enterCompany")}
                  error={errors?.company_name?.message}
                  maxLength={50}
                  onChange={(event) => {
                    setValue("company_name", event.target.value);
                  }}
                />
              )}
            />
          </div>
        </div>
        <div>
          <Controller
            name="job_title"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                onWhitebg={true}
                placeholder={t("overview.jobTitle")}
                label={t("overview.jobTitle")}
                error={errors?.job_title?.message}
                maxLength={50}
                onChange={(event) => {
                  setValue("job_title", event.target.value);
                }}
              />
            )}
          />
        </div>
        <div className="flex justify-between w-full items-center">
          <p className="text-[18px] py-3 font-bold">
            {t("overview.additionalDetails")}
          </p>
          <button
            onClick={handleAddNewFields}
            className="flex gap-2 w-[110px] h-[40px] items-center justify-center hover:bg-black/50 hover:border-0 hover:text-white duration-500 border-black/20 border text-[14px] rounded-md"
          >
            <Plus className="h-[20px] w-[20px]" />
            {t("overview.addField")}
          </button>
        </div>
        {additionalFields.map((_, index) => (
          <div key={index} className="flex gap-3">
            <div className="w-[40%]">
              <Controller
                name="title"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    onWhitebg={true}
                    label={t("overview.title")}
                    maxLength={50}
                    placeholder="Title"
                    onChange={(event) => {
                      const clonedAdditionalDetails = [...additionalDetails];
                      clonedAdditionalDetails[index] = {
                        ...clonedAdditionalDetails[index],
                        key: event.target.value.trim(),
                      };
                      setAdditionalDetails(clonedAdditionalDetails);
                    }}
                  />
                )}
              />
            </div>
            <div className="w-[60%] relative">
              <Controller
                name="content"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    onWhitebg={true}
                    label={t("overview.content")}
                    maxLength={50}
                    placeholder={t("overview.content")}
                    onChange={(event) => {
                      const clonedAdditionalDetails = [...additionalDetails];
                      clonedAdditionalDetails[index] = {
                        ...clonedAdditionalDetails[index],
                        value: event.target.value.trim(),
                      };
                      setAdditionalDetails(clonedAdditionalDetails);
                    }}
                  />
                )}
              />
              <div
                onClick={() => handleDeleteField(index)}
                className="absolute top-[3px] right-0 cursor-pointer"
              >
                <Trash className="w-[20px] h-[20px]" />
              </div>
            </div>
          </div>
        ))}
        <div className="flex gap-4 ml-auto mt-6 justify-end items-center">
          <button
            onClick={handleContactModalClose}
            className="border-danger border text-danger hover:bg-danger hover:text-white duration-500 w-[100px] h-[40px] rounded"
          >
            {t("overview.close")}
          </button>
        </div>
      </form>
    </div>
  );
}

export default AddNewContact;
