import React, {
  useState,
  useMemo,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useQuery } from "@tanstack/react-query";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import Button from "components/Button";
import {
  Refresh,
  NoRecord,
  PreviousDoubleLeft,
  PreviousDoubleRight,
  PreviousLeft,
  PreviousRight,
  SortingIcon,
  Filter,
  Download,
} from "assets/images";
import Input from "components/Input";
import Tabs from "components/Tabs";
import TableLoader from "./TableLoader";
import GlobalFilters from "components/GlobalFilters";
import AsideModal from "components/AsideModal";
import LinearLoader from "components/LinearLoader";
import { useDebounce } from "hooks";
import { useAuth } from "hooks/useAuth";
import Spinner from "components/Spinner";
import axios from "axios";
import { routes } from "api/routes";
import { toastEmitter } from "components/Toast";
import { getToken } from "shared/resources";
import { Calendar, ChevronLeft } from "lucide-react";
import DateRange from "./DateRange";
import { useTranslation } from "react-i18next";

const pageNumberListLimit = 5;

const TableManager = (
  {
    selectedData = [],
    fetcherKey = "",
    fetcherFn = () => {},
    defaultRows = 10,
    columns = [],
    tableActions = [],
    shouldShowColumnName = true,
    extraParams = {},
    showPagination = true,
    showHeader = true,
    shouldFilter = false,
    filterInitialValues,
    filters,
    setFilters,
    openFilter,
    setFrom,
    setTo,
    setOpenFilter,
    currentTab = "",
    isSearchable = false,
    downloadBtn = false,
    downloadSessions = false,
    calendarBtn = false,
    shouldRefresh = true,
    searchPlaceholder = "",
    searchLabel = "",
    tabList = [],
    bulkActions = [],
    back = false,
    setTab,
    fromDate,
    toDate,
    bulkActionsAccKey = "uuid",
    checkboxSelectableFn = () => {},
    noRecordItem: NoRecordItem = () => {
      const { t } = useTranslation();
      return (
        <div className="w-full grid place-content-center text-grey-600 gap-3 flex-col sm:h-[calc(100vh_-_309px)] xs:h-full ">
          <div className="flex items-center justify-center content-center flex-col gap-1 h-full p-3 xs:h-[450px] sm:h-auto">
            <NoRecord />
            <>{t("overview.noRecordFound")}</>
          </div>
        </div>
      );
    },
  },
  ref
) => {
  const { user } = useAuth();

  const memoizedColumns = useMemo(() => columns, [columns]);
  const [search, setSearch] = useState("");
  const debouncedSearchValue = useDebounce(search, 1000);
  const [sorting, setSorting] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [maxPageNumberListLimit, setMaxPageNumberListLimit] = useState(5);
  const [minPageNumberListLimit, setMinPageNumberListLimit] = useState(0);
  const memoizedSorting = useMemo(() => sorting, [sorting]);
  const [loading, setLoading] = useState(false);
  const [bulkRows, setBulkRows] = useState([]);
  const [showRangeModal, setShowRangeModal] = useState(false);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 50,
  });
  const multipleRowsSelected = bulkRows && bulkRows.length > 0 ? true : false;


  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  let payload = {
    page: pageIndex + 1,
    limit: pageSize,
    sort: memoizedSorting[0] || {},
    search: debouncedSearchValue,
    filter:
      filters?.map(
        ({
          /* eslint-disable no-unused-vars */
          placeholder,
          type,
          shouldFilterOptionDisabled,
          filterOptions,
          selectOptions,
          label,
          filterName,
          position,
          /* eslint-enable no-unused-vars */
          ...rest
        }) => ({ ...rest })
      ) ?? [],
    ...extraParams,
  };

  const {
    isLoading,
    data: tbldata,
    isFetching,
    isRefetching,
    refetch,
  } = useQuery({
    queryFn: ({ queryKey }) => fetcherFn(queryKey[1] || {}),
    queryKey: [`${fetcherKey}`, { ...payload }],
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    retry: false,
  });

  const tableInstance = useReactTable({
    debugAll: false,
    data: tbldata?.data?.data || [],
    columns: memoizedColumns || [],
    columnResizeMode: "onChange",
    enableRowSelection: (row) => {
      if (row?.original?.uuid === user?.uuid) {
        return false;
      }

      return checkboxSelectableFn(row?.original);
    },

    state: {
      columnVisibility,
      pagination,
      sorting: memoizedSorting,
      rowSelection,
    },
    manualPagination: true,
    manualSorting: true,
    pageCount: tbldata?.data?.pagination?.lastPage ?? -1,
    onColumnVisibilityChange: setColumnVisibility,
    onPaginationChange: setPagination,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
  });

  useImperativeHandle(
    ref,
    () => ({
      getTableRecordsLength() {
        return tbldata?.data?.pagination?.total ?? 0;
      },
    }),
    [tbldata?.data?.pagination, isLoading, isFetching]
  );

  useEffect(() => {
    if (columns && columns.length > 0) {
      let newColumns = {};
      for (let index = 0; index < columns.length; index++) {
        const column = columns[index];
        const visbility = column?.meta?.hasAccess ?? true;
        if (visbility) {
          newColumns[column?.accessorKey] = visbility;
        } else {
          newColumns[column?.accessorKey] = false;
        }
      }
      setColumnVisibility(newColumns);
    }
  }, [columns]);

  useEffect(() => {
    if (rowSelection && Object.keys(rowSelection).length > 0) {
      const rowsData = tableInstance.getSelectedRowModel()?.flatRows;
      const arr = [];
      for (const elem in rowsData) {
        arr.push(rowsData[elem]?.original?.[bulkActionsAccKey]);
      }
      setBulkRows(arr);
    } else {
      setBulkRows([]);
    }
  }, [rowSelection]);

  const handleNextPage = () => {
    tableInstance?.nextPage();
    if (pageIndex === tableInstance?.getPageCount() - 1) {
      return false;
    } else {
      if (pageIndex + 2 > maxPageNumberListLimit) {
        setMaxPageNumberListLimit(maxPageNumberListLimit + pageNumberListLimit);
        setMinPageNumberListLimit(minPageNumberListLimit + pageNumberListLimit);
      }
    }
  };
  const handlePreviousPage = () => {
    tableInstance?.previousPage();
    if (pageIndex === 0) {
      return;
    } else {
      if (pageIndex % pageNumberListLimit === 0) {
        setMaxPageNumberListLimit(maxPageNumberListLimit - pageNumberListLimit);
        setMinPageNumberListLimit(minPageNumberListLimit - pageNumberListLimit);
      }
    }
  };
  const handleLastPage = () => {
    tableInstance?.setPageIndex(tableInstance?.getPageCount() - 1);
    const min = tableInstance.getPageCount() - 5;
    const max = tableInstance.getPageCount();

    setMaxPageNumberListLimit(max);
    setMinPageNumberListLimit(min);
  };
  const handleFirstPage = () => {
    tableInstance?.setPageIndex(0);
    const min = 0;
    const max = 5;
    setMaxPageNumberListLimit(max);
    setMinPageNumberListLimit(min);
  };

  const handleMultiSelectResetCallback = () => {
    tableInstance?.resetRowSelection(true);
    refetch();
  };

  const handleModalClose = () => {
    setShowRangeModal(false);
  };

  const handleDateRangeApply = (from, to) => {
    setFrom(from);
    setTo(to);
  };

  const handleCallExport = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}${routes.CALL_EXPORT.URL}`,
        { type: currentTab, from: fromDate, to: toDate },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = `${currentTab.toLowerCase()}-call-logs.csv`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
        return (window.location.href = "/");
      } else {
        toastEmitter("error", error?.response?.data?.error?.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleCallSessionExport = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      // If only one session is selected, use single session download logic
      if (selectedData.length === 1) {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_API_URL}${routes.CALL_SESSION_SINGLE_EXPORT.URL}`,
          { session_uuid: selectedData[0] },
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
            responseType: "blob",
          }
        );
        const url = window.URL.createObjectURL(new Blob([response.data]));
        
        // Get the session name from the current table data
        const session = tbldata?.data?.data?.find(item => item.uuid === selectedData[0]);
        const sanitizedSessionName = session?.name?.replace(/[^a-z0-9]/gi, "_").toLowerCase() + "_" + session?.uuid;
        const fileName = `${sanitizedSessionName}-session.csv`;
        
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      } else {
        // Original multiple sessions logic with zip file
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_API_URL}${routes.CALL_SESSION_EXPORT.URL}`,
          {
            type: currentTab,
            from: fromDate,
            to: toDate,
            selectedBatches: selectedData.length > 0 ? selectedData : "",
          },
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
            responseType: "blob",
          }
        );
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const fileName = "bulk-dialing-sessions.zip";
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      }

      // Common download logic
    } catch (error) {
      // ... error handling remains the same
    } finally {
      setLoading(false);
    }
  };

  const { t } = useTranslation();
  const handleGoBack = () => {
    window.history.back();
  };
  return (
    <>
      {showHeader && (
        <div className="flex items-end justify-between gap-2 mb-4 xxl:flex-row xl:flex-row	lg:flex-row md:flex-row sm:flex-col xs:flex-col">
          <div className="flex items-end gap-4 w-full">
            {isSearchable ? (
              <div className=" flex flex-col gap-5 w-full">
                {searchLabel ? (
                  <div className=" text-grey-700 text-sm xs:hidden sm:block">
                    {searchLabel}
                  </div>
                ) : null}
                <Input
                  name="search"
                  value={search}
                  height="h-10"
                  onChange={(e) => setSearch(e.target.value)}
                  extraClasses="pl-10 mb-0 placeholder:text-grey-600 placeholder:font-normal placeholder:text-sm"
                  placeholder={searchPlaceholder}
                  withSearchIcon={true}
                />
              </div>
            ) : null}
            {back ? (
              <div
                className="cursor-pointer flex gap-2 items-center"
                onClick={handleGoBack}
              >
                <ChevronLeft />
                <p>Back</p>
              </div>
            ) : null}
            <span className="xs:hidden sm:flex">
              {shouldFilter && (
                <span onClick={() => setOpenFilter(true)}>
                  <div className="border-grey-400 border h-10 rounded-md flex items-center justify-center  w-28 ">
                    <div className="flex items-center gap-2 text-sm px-2 py-2 font-medium text-grey-600 cursor-pointer	">
                      <Filter /> {t("search.filter")}
                    </div>
                    {filters && filters.length > 0 ? (
                      <span>{filters?.length}</span>
                    ) : null}
                  </div>
                </span>
              )}
            </span>
          </div>

          <div className="flex items-end justify-end gap-2 w-full xxl:flex-row xl:flex-row	lg:flex-row md:flex-row sm:flex-col xs:flex-col">
            {tabList && tabList.length > 0 ? (
              <Tabs tabList={tabList} currentTab={currentTab} height="h-10" />
            ) : null}

            <span className="xs:hidden sm:flex">
              {multipleRowsSelected &&
                bulkActions &&
                bulkActions.length > 0 &&
                bulkActions.map(({ id, component: Component }) => (
                  <Component
                    key={id}
                    data={bulkRows}
                    callback={handleMultiSelectResetCallback}
                  />
                ))}

              {isLoading
                ? null
                : tableActions && tableActions.length > 0
                ? tableActions.map(
                    ({ id, component: ElmComponent, access }) => {
                      if (!access) return null;
                      return <span key={id}>{ElmComponent}</span>;
                    }
                  )
                : null}
            </span>

            <div className="flex justify-center items-center gap-2">
              {shouldRefresh ? (
                <div className="w-32">
                  <Button
                    rounded={true}
                    height="h-10"
                    extraClasses=" px-3"
                    background="grey-200 "
                    border="none"
                    onClick={() => {
                      refetch();
                    }}
                  >
                    <div className="text-black flex items-center xs:text-sm  gap-2">
                      {isRefetching ? (
                        <Spinner />
                      ) : (
                        <>
                          {t("overview.refresh")} <Refresh />
                        </>
                      )}
                    </div>
                  </Button>
                </div>
              ) : null}

              {downloadBtn ? (
                <div className="w-16">
                  <Button
                    rounded={true}
                    height="h-10"
                    extraClasses=" px-2"
                    background="grey-200 "
                    border="none"
                    onClick={handleCallExport}
                  >
                    {loading ? <Spinner /> : <Download color="black" />}
                  </Button>
                </div>
              ) : null}

              {downloadSessions ? (
                <div className="w-16">
                  <Button
                    rounded={true}
                    height="h-10"
                    extraClasses=" px-2"
                    background="grey-200 "
                    border="none"
                    onClick={handleCallSessionExport}
                  >
                    {loading ? <Spinner /> : <Download color="black" />}
                  </Button>
                </div>
              ) : null}

              {calendarBtn ? (
                <div onClick={() => setShowRangeModal(true)}>
                  <Button
                    rounded={true}
                    height="h-10"
                    extraClasses="px-3"
                    background="grey-200 "
                    border="none"
                  >
                    <Calendar color="black" size={20} />
                  </Button>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      )}
      <div className="flex flex-col justify-between sm:h-[calc(100%_-_96px)] xs:h-[calc(100%_-_25%)] ">
        <div className="table w-full  bg-white rounded-md border border-grey-400 sm:min-h-[calc(100%_-_3rem)] xs:min-h-[auto]">
          <table className="w-full table table-auto ">
            {shouldShowColumnName && (
              <thead className="relative   xxl:h-auto xl:h-auto lg:h-auto md:h-auto sm:h-auto xs:h-[1px] xxl:overflow-auto xl:overflow-auto lg:overflow-auto md:overflow-auto sm:overflow-auto xs:overflow-hidden xxl:relative xl:relative  lg:relative md:relative sm:relative  xs:absolute">
                {tableInstance?.getHeaderGroups()?.map((headerGroup, index) => {
                  return (
                    <tr
                      key={`header_group${index}`}
                      className="xxl:table-row   xl:table-row   lg:table-row   md:table-row  sm:table-row  xs:block"
                    >
                      {headerGroup?.headers?.map((header) => {
                        return (
                          <th
                            className="py-5 px-5 text-black text-sm font-medium	 text-start  border-grey-400 border-b whitespace-nowrap	 bg-white z-20 first-of-type:rounded-l-lg last-of-type:rounded-r-lg  

                                "
                            key={header?.id}
                            style={{
                              textAlign:
                                header?.column?.columnDef?.meta?.textAlign ??
                                "left",

                              width:
                                header?.column?.columnDef?.meta?.width ||
                                `${(
                                  100 / header?.headerGroup?.headers?.length
                                ).toFixed(2)}%`,
                              cursor: header?.column?.columnDef?.meta
                                ?.shouldPointer
                                ? "pointer"
                                : "",
                            }}
                          >
                            {header?.isPlaceholder ? null : header?.column
                                .columnDef.meta?.isSortable ? (
                              <div
                                className="flex items-center gap-1"
                                onClick={header?.column?.getToggleSortingHandler()}
                              >
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                                {{
                                  asc: (
                                    <PreviousRight
                                      width={14}
                                      height={13}
                                      style={{ rotate: "90deg" }}
                                    />
                                  ),
                                  desc: (
                                    <PreviousLeft
                                      width={14}
                                      height={13}
                                      style={{ rotate: "90deg" }}
                                    />
                                  ),
                                }[header.column.getIsSorted()] ?? (
                                  <SortingIcon width={14} height={13} />
                                )}
                              </div>
                            ) : (
                              flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )
                            )}
                          </th>
                        );
                      })}
                    </tr>
                  );
                })}
                <tr>
                  {isLoading && (
                    <th className="absolute w-full bottom-0">
                      <LinearLoader />
                    </th>
                  )}
                </tr>
              </thead>
            )}
            <tbody>
              {isLoading ? (
                <TableLoader
                  columnLength={tableInstance?.getAllColumns()?.length}
                  defaultRows={defaultRows}
                />
              ) : tableInstance?.getRowModel()?.rows?.length > 0 ? (
                tableInstance?.getRowModel()?.rows?.map((row, index) => {
                  return (
                    <tr
                      key={row?.id}
                      className={` whitespace-nowrap xxl:table-row   xl:table-row   lg:table-row   md:table-row  sm:table-row  xs:block xxl:border-0   xl:border-0    lg:border-0  md:border-0   sm:border-0   xs:border-b-4  border-green-400
                      ${rowSelection?.[row?.index] ? "bg-green" : ""} ${
                        index % 2 === 0
                          ? "sm:bg-grey-100 xs:bg-white "
                          : "  sm:bg-grey-200  xs:bg-grey-200"
                      }`}
                    >
                      {row?.getVisibleCells()?.map((cell) => {
                        if (
                          cell?.column?.id === "check" ||
                          cell?.column?.id === "action"
                        ) {
                          return (
                            <td
                              className=" sm:px-5 text-sm font-medium border-grey-400 xs:hidden  xxl:border-b  xl:border-b lg:border-b md:border-b sm:border-b xs:border-0  xxl:h-[64.5px] xl:h-[64.5px] lg:h-[64.5px] md:h-[64.5px] sm:h-[64.5px] xs:h-[40px]  xxl:py-3 xl:py-3 lg:py-3 md:py-3 sm:py-3 xs:p-3   xxl:table-cell  xl:table-cell    lg:table-cell  md:table-cell   sm:table-cell    xxl:justify-between   xl:justify-between   lg:justify-between   md:justify-between  sm:justify-between  xs:justify-between xxl:items-center   xl:items-center    lg:items-center  md:items-center   sm:items-center   xs:items-center"
                              key={cell?.id}
                              style={{
                                textAlign:
                                  cell?.column?.columnDef?.meta?.textAlign ??
                                  "left",
                                width:
                                  cell?.column?.columnDef?.meta?.width ||
                                  `${(
                                    100 / cell?.headerGroup?.headers?.length
                                  ).toFixed(2)}%`,
                              }}
                            >
                              <span
                                className=" text-black text-sm font-medium  text-start   whitespace-nowrap   z-20  xxl:hidden   xl:hidden    lg:hidden  md:hidden   sm:hidden   xs:block"
                                aria-hidden="true"
                              >
                                {flexRender(
                                  cell.column.columnDef.header,
                                  cell.getContext()
                                )}
                              </span>
                              <div>
                                {cell?.column?.id === "action" ||
                                cell?.column?.columnDef?.meta?.colType ===
                                  "action"
                                  ? flexRender(
                                      cell.column.columnDef.cell,
                                      cell.getContext()
                                    )
                                  : cell?.getValue() ||
                                    parseInt(cell?.getValue()) === 0
                                  ? flexRender(
                                      cell.column.columnDef.cell,
                                      cell.getContext()
                                    )
                                  : !cell?.getValue()
                                  ? "---"
                                  : cell?.getValue() || "---"}
                              </div>
                            </td>
                          );
                        }

                        return (
                          <td
                            className=" sm:px-5 text-sm font-medium border-grey-400  xxl:border-b  xl:border-b lg:border-b md:border-b sm:border-b xs:border-b xxl:h-[64.5px] xl:h-[64.5px] lg:h-[64.5px] md:h-[64.5px] sm:h-[64.5px] xs:h-[50px]  xxl:py-3 xl:py-3 lg:py-3 md:py-3 sm:py-3 xs:p-3   xxl:table-cell  xl:table-cell    lg:table-cell  md:table-cell   sm:table-cell   xs:flex xxl:justify-between   xl:justify-between   lg:justify-between   md:justify-between  sm:justify-between  xs:justify-between xxl:items-center   xl:items-center    lg:items-center  md:items-center   sm:items-center   xs:items-center"
                            key={cell?.id}
                            style={{
                              textAlign:
                                cell?.column?.columnDef?.meta?.textAlign ??
                                "left",
                              width:
                                cell?.column?.columnDef?.meta?.width ||
                                `${(
                                  100 / cell?.headerGroup?.headers?.length
                                ).toFixed(2)}%`,
                            }}
                          >
                            <span
                              className=" text-black text-sm font-medium    text-start   whitespace-nowrap   z-20  xxl:hidden   xl:hidden    lg:hidden  md:hidden   sm:hidden   xs:block"
                              aria-hidden="true"
                            >
                              {flexRender(
                                cell.column.columnDef.header,
                                cell.getContext()
                              )}
                            </span>
                            <div>
                              {cell?.column?.id === "action" ||
                              cell?.column?.columnDef?.meta?.colType ===
                                "action" ||
                              cell?.column?.id === "callStatus"
                                ? flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                  )
                                : cell?.getValue() ||
                                  parseInt(cell?.getValue()) === 0
                                ? flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                  )
                                : !cell?.getValue()
                                ? "---"
                                : cell?.getValue()
                                ? cell?.getValue()
                                : "---"}
                            </div>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td
                    colSpan={tableInstance?.getAllColumns()?.length}
                    align="center"
                  >
                    <NoRecordItem />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="flex items-center justify-between gap-3 px-3">
          {showPagination
            ? !isLoading &&
              tableInstance?.getRowModel()?.rows?.length > 0 && (
                <div className="flex justify-between w-full items-center">
                  <div className="flex items-center gap-3 text-sm mt-2">
                    <div>Page </div>
                    <div>
                      {tableInstance?.getState()?.pagination?.pageIndex + 1}{" "}
                    </div>
                    {t("overview.of")}{" "}
                    <div>{tableInstance?.getPageCount()}</div>
                    {/* <div>|</div> */}
                    {/* <div>Show</div> */}
                    {/* <select
                    className="border-0 bg-transparent focus:outline-none	text-sm"
                    value={String(
                      tableInstance?.getState()?.pagination?.pageSize,
                    )}
                    onChange={(e) => {
                      tableInstance?.setPageSize(Number(e.target.value));
                    }}
                  >
                    <option value="10">10</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="150">150</option>
                  </select>
                  <div>records per page</div> */}
                  </div>
                  <div className="flex items-center gap-2 mt-2">
                    <div>
                      <button
                        className="disabled:cursor-not-allowed bg-transparent  w-[30px] h-[30px] flex justify-center items-center rounded-md  cursor-pointer"
                        onClick={() => handleFirstPage()}
                        disabled={!tableInstance?.getCanPreviousPage()}
                      >
                        <PreviousDoubleLeft width={18} height={18} />
                      </button>
                    </div>
                    <div>
                      <button
                        className="disabled:cursor-not-allowed bg-transparent w-[30px] h-[30px]  flex justify-center items-center rounded-md cursor-pointer"
                        onClick={() => handlePreviousPage()}
                        disabled={!tableInstance?.getCanPreviousPage()}
                      >
                        <PreviousLeft width={18} height={18} />
                      </button>
                    </div>
                    <div className="flex items-center gap-2">
                      {tableInstance?.getPageOptions()?.map((page, index) => {
                        const number = page + 1;
                        if (
                          number < maxPageNumberListLimit + 1 &&
                          number > minPageNumberListLimit
                        ) {
                          return (
                            <button
                              className={`disabled:cursor-not-allowed bg-transparent  min-w-[30px] h-[30px] flex justify-center items-center rounded-md  cursor-pointer px-2 text-sm
                            ${
                              tableInstance?.getState()?.pagination
                                ?.pageIndex === index
                                ? "text-green font-semibold"
                                : "text-grey-900"
                            }
                            `}
                              key={page}
                              onClick={() => tableInstance?.setPageIndex(index)}
                            >
                              {page + 1}
                            </button>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </div>
                    <div>
                      <button
                        className="disabled:cursor-not-allowed bg-transparent w-[30px] h-[30px] flex justify-center items-center rounded-md  cursor-pointer"
                        onClick={() => handleNextPage()}
                        disabled={!tableInstance?.getCanNextPage()}
                      >
                        <PreviousRight width={18} height={18} />
                      </button>
                    </div>
                    <div>
                      <button
                        className="disabled:cursor-not-allowed bg-transparent w-[30px] h-[30px] flex justify-center items-center rounded-md  cursor-pointer"
                        onClick={() => handleLastPage()}
                        disabled={!tableInstance?.getCanNextPage()}
                      >
                        <PreviousDoubleRight width={18} height={18} />
                      </button>
                    </div>
                  </div>
                </div>
              )
            : null}
        </div>
      </div>

      {openFilter && (
        <AsideModal
          handleClose={() => setOpenFilter(false)}
          footerComponent={null}
          headerComponent={null}
          shouldCloseOnClickOutside={true}
        >
          <GlobalFilters
            initialValues={filterInitialValues}
            isSearching={isLoading || isFetching}
            setFilters={setFilters}
            filters={filters}
            setOpenFilter={setOpenFilter}
            openFilter={openFilter}
          />
        </AsideModal>
      )}
      {showRangeModal && (
        <div
          className="fixed inset-0 z-50 animate-modalAnimation flex items-center justify-center bg-black bg-opacity-50"
          onClick={handleModalClose}
        >
          <div
            className="bg-white p-4 rounded-lg w-full sm:w-[400px] h-[300px] overflow-hidden"
            onClick={(e) => e.stopPropagation()}
          >
            <DateRange
              handleModalClose={handleModalClose}
              fromDate={fromDate}
              toDate={toDate}
              setFromDate={setFrom}
              setToDate={setTo}
              setTab={setTab}
              handleApply={handleDateRangeApply}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default forwardRef(TableManager);
