import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { formInitialValues } from "./constants";
import PhoneNumberForm from "./PhoneNumberForm";
import BillingDetailsForm from "./BillingDetailsForm";
import UploadDocumentsForm from "./UploadDocumentsForm";
import { Button } from "components";
import { Arrow } from "assets/images";
// import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
const PortNumberForm = () => {
  const [readView, setReadView] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    // trigger,
  } = useForm({
    // resolver: yupResolver(formValidationSchema),
    defaultValues: formInitialValues,
    mode: "onChange",
  });

  const onSubmit = (values) => {
    navigate(
      `/phone-numbers/port-number/status?phone=${values?.phone_details?.phone_number}`
    );
  };

  const handleNext = async () => {
    // const isValid = await trigger();
    // if (!isValid) {
    setReadView(true);
    // }
  };
  return (
    <div className=" bg-white w-full h-full overflow-auto px-6 pt-6 pb-[114px]">
      <div className="text-grey-900 text-xl font-semibold">
        {t("overview.portExistingNumber")}
      </div>

      <div className="text-grey-700 text-sm mb-16">
        {t("overview.threeSteps")}
      </div>
      <div>
        <PhoneNumberForm
          control={control}
          errors={errors}
          watch={watch}
          readView={readView}
        />
      </div>
      {/* <div className="w-full max-w-[991px] h-[1px] bg-grey-400 my-[60px] "></div> */}
      <div>
        <BillingDetailsForm
          control={control}
          errors={errors}
          watch={watch}
          setValue={setValue}
          readView={readView}
        />
      </div>
      <div>
        <UploadDocumentsForm
          control={control}
          errors={errors}
          watch={watch}
          setValue={setValue}
          readView={readView}
        />
      </div>
      <div className="flex gap-6">
        <div className="w-10">&nbsp;</div>
        <div className="mt-[100px] ">
          {readView ? (
            <div className="flex gap-6 ">
              <div className="w-[80px]">
                <Button
                  extraClasses="mt-10 "
                  type="button"
                  background="transparent"
                  onClick={() => setReadView(false)}
                >
                  <div className="text-black flex items-center">
                    <Arrow className="rotate-180 text-black" />{" "}
                    {t("overview.back")}
                  </div>
                </Button>
              </div>
              <div className="w-[334px]">
                <Button
                  extraClasses="mt-10"
                  type="button"
                  onClick={handleSubmit(onSubmit)}
                >
                  {t("overview.submit")}
                </Button>
              </div>
            </div>
          ) : (
            <div className="w-[334px]">
              <Button
                extraClasses="mt-10"
                type="button"
                onClick={() => handleNext()}
              >
                {t("overview.next")}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PortNumberForm;
