/* eslint-disable no-unused-vars */
import { MenuIcon, PhoneCall, Prohibit, UserList, Trash } from "assets/images";
import { Avatar, Dropdown } from "components";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { routes } from "api/routes";
import { getToken } from "shared/resources";

const Header = ({ chatId = "", conversation, endConversation }) => {
  if (!chatId) return "Zigi";

  return (
    <>
      <div className="flex items-center gap-3">
        {chatId !== "Zigi Bot" && (
          <div className="relative">
            <Avatar
              name={`${
                chatId === "0"
                  ? "Deanna Curtis"
                  : conversation?.metadata?.company_name ||
                    conversation?.metadata?.name ||
                    chatId
              }`}
            />
          </div>
        )}

        <div>
          <div className={"font-medium text-sm tracking-[0.41px]"}>
            {chatId === "0"
              ? "Deanna Curtis"
              : conversation?.metadata?.company_name ||
                conversation?.metadata?.name ||
                chatId}
          </div>
          {chatId !== "Zigi Bot" && conversation?.metadata?.phone && (
            <div
              className={"font-medium text-sm text-grey-600  tracking-[0.41px]"}
            >
              +234 78 48 4584 878
            </div>
          )}
        </div>
      </div>
      <div className="flex items-center gap-4 pr-2">
        <div>{conversation?.metadata?.status}</div>
        <div>
          <PhoneCall className="text-green" />
        </div>
        <div>
          {chatId !== "Zigi Bot" && (
            <Trash
              className="text-red-700 w-[18px] h-[18px]"
              onClick={endConversation}
            />
          )}
        </div>
        <div>
          <Dropdown
            menuWidth="w-max"
            closeOnClickOutside={true}
            dropList={{
              component: ({ item }) => {
                const Icon = item?.icon ?? null;
                return (
                  <span className="w-full h-full flex  justify-start gap-2 items-center text py-2 px-3 ">
                    {Icon}
                    <div className="text-sm text-grey-900">{item?.title}</div>
                  </span>
                );
              },
              data: [
                {
                  id: 1,
                  title: "View contact details",
                  icon: <UserList className="text-grey-700 w-5 h-5" />,
                },
                {
                  id: 2,
                  title: "Block contact",
                  icon: (
                    <Prohibit className="text-grey-700 w-[18px] h-[18px]" />
                  ),
                },
              ],
            }}
            showcaret={false}
          >
            <div className="cursor-pointer w-1">
              <MenuIcon />
            </div>
          </Dropdown>
        </div>
      </div>
    </>
  );
};

export default Header;
