import React from "react";
import AutoReplyInput from "./AutoReplyInput";
import { Controller } from "react-hook-form";
import SwitchInput from "components/SwitchInput";
import { useTranslation } from "react-i18next";

const AdvanceSettings = ({ watch, control, setValue }) => {
  const isDeleteMessageEnabled = watch("delete_sms") === "Y";
  const { t } = useTranslation();
  return (
    <>
      <div className="flex flex-col gap-1">
        <div className="text-grey-900 text-base font-semibold">
          {t("overview.autoReplies")}
        </div>
        <div className="text-grey-700 text-sm font-medium">
          {t("overview.textMes")}
        </div>
      </div>
      <div className="flex flex-col gap-1 mt-6">
        <div className="text-grey-900 text-base font-semibold">
          {t("overview.duringBusHour")}
        </div>
        <div className="flex flex-col gap-4 mt-2">
          <AutoReplyInput
            inputLabel={t("overview.setIncomingMes")}
            messageLabel={t("overview.onInMes")}
            valueKey={"during_business_hour.incoming_reply"}
            control={control}
            watch={watch}
            setValue={setValue}
          />

          <AutoReplyInput
            inputLabel={t("overview.setMissedCall")}
            messageLabel={t("overview.onMissedCall")}
            valueKey={"during_business_hour.no_voicemail_reply"}
            control={control}
            watch={watch}
            setValue={setValue}
          />

          <AutoReplyInput
            inputLabel={t("overview.setMissedCallW")}
            messageLabel={t("overview.onMissedCallW")}
            valueKey={"during_business_hour.voicemail_reply"}
            control={control}
            watch={watch}
            setValue={setValue}
          />
        </div>
      </div>
      <div className="flex flex-col gap-1 mt-7">
        <div className="text-grey-900 text-base font-semibold">
          {t("overview.afterBusHours")}
        </div>
        <div className="flex flex-col gap-4 mt-2">
          <AutoReplyInput
            inputLabel={t("overview.setIncomingMes")}
            messageLabel={t("overview.onInMes")}
            valueKey={"closed_business_hour.incoming_reply"}
            control={control}
            watch={watch}
            setValue={setValue}
          />
          <AutoReplyInput
            inputLabel={t("overview.setMissedCall")}
            messageLabel={t("overview.onMissedCall")}
            valueKey={"closed_business_hour.no_voicemail_reply"}
            control={control}
            watch={watch}
            setValue={setValue}
          />
          <AutoReplyInput
            inputLabel={t("overview.setMissedCallW")}
            messageLabel={t("overview.onMissedCallW")}
            valueKey={"closed_business_hour.voicemail_reply"}
            control={control}
            watch={watch}
            setValue={setValue}
          />
        </div>
      </div>
      <div className="flex justify-between mt-7">
        <div className="flex flex-col gap-1">
          <div className="text-grey-900 text-base font-semibold">
            {isDeleteMessageEnabled
              ? t("overview.disableDeleteSms")
              : t("overview.enableDeleteSms")}
          </div>
          <div className="text-grey-700 text-sm font-medium">
            {isDeleteMessageEnabled
              ? t("overview.disableDeleteSmsOpt")
              : t("overview.enableDeleteSmsOpt")}
          </div>
        </div>

        <Controller
          name={"delete_sms"}
          control={control}
          render={({ field }) => (
            <SwitchInput
              {...field}
              name="delete_sms"
              label={""}
              onChange={(e) => {
                field.onChange(e.target.checked ? "Y" : "N");
              }}
              checked={watch("delete_sms") === "Y"}
            />
          )}
        />
      </div>
    </>
  );
};
export default AdvanceSettings;
