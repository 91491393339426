import { getMemberListing } from "api";
import { X } from "assets/images";
import { Avatar, Button, Spinner } from "components";
import ListManager from "components/ListManager";
import { useAssignUnassignMember } from "hooks";
import { useAuth } from "hooks/useAuth";
import { LoadingItem } from "pages/Dashboard";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const MemberListItem = ({ item, ...rest }) => {
  const { user } = useAuth();

  return (
    <label
      htmlFor={item?.uuid}
      className="flex items-center justify-between py-3 border-grey-300 border-b cursor-pointer "
    >
      <div className="flex gap-3">
        <div className="relative">
          <Avatar
            name={`${item?.first_name} ${item?.last_name}`}
            mediaUrl={`${process.env.REACT_APP_BASE_API_URL}${
              user?.company_uuid || user?.uuid
            }/profile/${item?.profile_pic}`}
          />
        </div>
        <div className="flex flex-col gap-1">
          <div className="flex items-center text-sm font-medium	 text-grey-900">
            {item?.first_name} {item?.last_name ?? ""}&nbsp;&nbsp;
          </div>
        </div>
      </div>
      {item?.status === 0 ? null : (
        <div className="flex text-xs text-green gap-1">
          <input
            className="checked:bg-green checked:hover:bg-green checked:active:bg-green checked:focus:bg-green focus:outline-none focus:ring-1 focus:ring-green"
            id={item?.uuid}
            type="checkbox"
            value={item?.uuid}
            onChange={(e) => {
              if (rest?.itemValue.includes(e.target.value)) {
                rest.itemValue.splice(
                  rest.itemValue.indexOf(e.target.value),
                  1
                );
                rest?.onChangeItemHandler([...rest.itemValue]);
              } else {
                rest?.onChangeItemHandler([...rest.itemValue, e.target.value]);
              }
            }}
            checked={rest?.itemValue?.includes(item?.uuid)}
            disabled={item?.status === 0}
          />
        </div>
      )}
    </label>
  );
};

const InviteTeamToModal = ({ handleClose = () => null, showModal }) => {
  const [selectedMembers, setSelectedMembers] = useState([]);
  const itemElement = showModal?.elementData;
  const handleSuccess = () => {
    handleClose();
  };

  const {
    mutateAsync: assignUnassignTeamMutate,
    isPending: assignUnassignTeamLoad,
  } = useAssignUnassignMember({
    onSuccessFn: handleSuccess,
  });

  useEffect(() => {
    if (itemElement && itemElement?.members && itemElement.members.length > 0) {
      const data2set = itemElement.members.map((item) => item?.uuid);
      setSelectedMembers(data2set);
    }
  }, [itemElement]);
  const { t } = useTranslation();
  return (
    <div className="min-w-[460px]">
      <div className="flex justify-between items-start py-4 px-6">
        <div className="flex flex-col gap-1">
          <div className="text-xl font-semibold text-black">
            {t("overview.inviteTeam")}
          </div>
          <div className="text-sm  text-grey-700">
            {t("overview.inviteInfo")}
          </div>
        </div>
        <button type="button" onClick={() => handleClose()}>
          <X className="text-grey-600 w-6 h-6" />
        </button>
      </div>

      <div className=" px-6 py-3 border-grey-300 border-t overflow-auto xxl:max-h-[330px] xl:max-h-[330px] lg:max-h-[330px] md:max-h-[254px]">
        <ListManager
          actionFn={getMemberListing}
          queryKey="getMemberListing"
          listItem={MemberListItem}
          loadingComponent={LoadingItem}
          onChangeItemHandler={setSelectedMembers}
          itemValue={selectedMembers}
        />
      </div>

      <div className=" mt-10 px-6 pb-6">
        <Button
          onClick={() =>
            assignUnassignTeamMutate({
              member_uuids: [...selectedMembers],
              team_uuid: itemElement?.uuid,
            })
          }
          disabled={assignUnassignTeamLoad}
          extraClasses="justify-start pl-4 cursor-pointer"
          type="button"
        >
          {assignUnassignTeamLoad ? <Spinner /> : t("overview.sendInvite")}
        </Button>
      </div>
    </div>
  );
};

export default InviteTeamToModal;
