import { callsHistory } from "api";
import { MissedCall, PhoneIncoming, PhoneOutgoing, Play } from "assets/images";
import { Modal, PlayAudioModal, TableManager, Tooltip } from "components";
import GetFonuNumberName from "hooks/GetFonuNumberName";
import GetMemberName from "hooks/GetMemberName";
import { dropdownActions, initialModalState } from "pages/Dashboard/constants";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatDateTime, formatPhoneNumber } from "shared/resources";

const filterInitialValues = {
  filter: [
    {
      name: "",
      a_filter_type: "",
      placeholder: "Enter name",
      type: "text",
      shouldFilterOptionDisabled: false,
      label: "Name",
      filterName: "name",
      position: 1,
    },

    {
      dob: "date",
      a_filter_type: "",
      placeholder: "Select date",
      type: "date-input",
      shouldFilterOptionDisabled: true,
      label: "Date",
      filterName: "date",
      position: 3,
    },
  ],
};

const All = () => {
  const [tab, setTab] = useState("ALL");
  const [showModal, setShowModal] = useState(initialModalState);
  const [filters, setFilters] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const { t } = useTranslation();

  const callDirectionIconLookup = useMemo(() => {
    return {
      inbound: (
        <Tooltip title="Incoming call">
          <PhoneIncoming className="text-green-400" />
        </Tooltip>
      ),
      missed: (
        <Tooltip title="Missed call">
          <MissedCall className="text-danger" />
        </Tooltip>
      ),
      outbound: (
        <Tooltip title="Outgoing call">
          <PhoneOutgoing className="text-green" />
        </Tooltip>
      ),
    };
  }, []);
  const columns = useMemo(
    () => [
      {
        accessorKey: "start_stamp",
        header: () => t("overview.date"),
        cell: (props) => {
          const [date, time] = formatDateTime(props?.getValue());
          return (
            <span>
              {date}&nbsp;&#183;&nbsp;{time}
            </span>
          );
        },
      },
      {
        accessorKey: "caller_id_number",
        header: () => t("overview.from"),
        cell: (props) => {
          return formatPhoneNumber(`+${props?.getValue()}`);
        },
      },
      {
        accessorKey: "xml_cdr_uuid",
        header: () => t("overview.to"),
        cell: (props) => {
          const element = props?.row?.original;
          const isOutboundCall = element?.direction === "Outbound";

          return (
            <div className="flex items-start gap-1 ">
              {callDirectionIconLookup[element?.direction?.toLowerCase()]}
              <div className="flex flex-col">
                <div>
                  <GetFonuNumberName
                    id={
                      isOutboundCall
                        ? element?.destination_number
                        : element?.caller_destination
                    }
                  />
                </div>
                <div className="text-grey-600">
                  {formatPhoneNumber(
                    isOutboundCall
                      ? element?.destination_number
                      : element?.caller_destination
                  )}
                </div>
              </div>
            </div>
          );
        },
      },
      {
        accessorKey: "accountcode",
        header: () => t("overview.member"),
        cell: (props) => {
          return (
            <div className="flex items-start gap-1 ">
              <div className="flex flex-col">
                <div>
                  {props.row.original?.forward_type === "EXTENSION" ? (
                    <GetMemberName id={props.row.original?.forward_value} />
                  ) : (
                    <GetMemberName id={props.row.original?.extension} />
                  )}
                </div>
              </div>
            </div>
          );
        },
      },
      {
        accessorKey: "duration",
        header: () => t("overview.duration"),
      },
      {
        accessorKey: "status",
        header: () => t("overview.status"),
        cell: (props) => {
          const statusValue = props?.getValue()?.toLowerCase();

          const translatedStatus = (() => {
            switch (statusValue) {
              case "cancel":
                return t("overview.cancel");
              case "success":
                return t("overview.success");
              case "allotted_timeout":
                return t("overview.allotted_timeout");
              case "low_credit":
                return t("overview.lowCredit");
              case "no_user_response":
                return t("overview.noUserResponse");
              default:
                return statusValue?.split("_")?.join(" ");
            }
          })();

          return <span className="capitalize">{translatedStatus}</span>;
        },
        meta: {
          width: "5%",
        },
      },
      {
        accessorKey: "action",
        header: () => "",
        cell: (props) => {
          const element = props?.row?.original || {};
          const isAction = props?.row?.original?.recording_file ?? false;
          if (!isAction) return;
          return (
            <div className="flex gap-3">
              <span
                className="text-green cursor-pointer"
                onClick={() => {
                  setShowModal({
                    isShow: true,
                    modalType: dropdownActions.PLAY_GREETING,
                    elementData: element,
                    type: "center",
                  });
                }}
              >
                <Play className="w-6 h-6" />
              </span>
            </div>
          );
        },
        meta: {
          colType: "action",
          width: "5%",
        },
      },
    ],
    []
  );

  function handleCloseModal() {
    setShowModal(initialModalState);
  }
  const { isShow, type } = showModal;
  return (
    <>
      <TableManager
        {...{
          fetcherKey: "callsHistory",
          fetcherFn: callsHistory,
          columns,
          showPagination: true,
          isSearchable: true,
          tabList: [
            {
              id: 1,
              label: t("overview.all"),
              onclick: () => setTab("ALL"),
              value: "ALL",
            },
            {
              id: 2,
              label: t("overview.outgoing"),
              onclick: () => setTab("OUTGOING"),
              value: "OUTGOING",
            },
            {
              id: 3,
              label: t("overview.incoming"),
              onclick: () => setTab("INCOMING"),
              value: "INCOMING",
            },
            {
              id: 4,
              label: t("overview.missed"),
              onclick: () => setTab("MISSED"),
              value: "MISSED",
            },
          ],
          currentTab: tab,
          searchPlaceholder: t("search.searchWithNumber"),
          filters,
          setFilters,
          openFilter,
          setOpenFilter,
          filterInitialValues,
          extraParams: {
            request_type: "history-list",
            type: tab,
          },
        }}
      />
      {isShow && type === "center" ? (
        <Modal
          handleClose={handleCloseModal}
          headerComponent={null}
          footerComponent={null}
          shouldCloseOnClickOutside={false}
        >
          <PlayAudioModal
            type="recording"
            fileNameAccessorKey={"recording_file"}
            showModal={showModal}
            handleClose={handleCloseModal}
          />
        </Modal>
      ) : null}
    </>
  );
};

export default All;
