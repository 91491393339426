import { ChevronDown, ChevronUp, Timer } from "lucide-react";
import React, { useState } from "react";
import ReactFlow, { Background, Controls, Handle, Position } from "reactflow";
import "reactflow/dist/style.css";

const ICON_COLORS = {
  red: "text-danger bg-danger/40",
  yellow: "text-[#e8be17] bg-[#e8be17]/40",
  purple: "text-[#7e19e3] bg-[#7e19e3]/40",
  blue: "text-blue-fb bg-blue-fb/40",
  orange: "text-[#e37019] bg-[#e37019]/40",
  green: "text-green bg-green/40",
};

const CustomNode = ({ data }) => {
  const { icon: Icon, color = "green" } = data;
  const iconColorClass = ICON_COLORS[color] || ICON_COLORS.green;

  return (
    <div>
      <div className="flex gap-4 items-center h-[100px] w-[250px] cursor-pointer border border-black/70 rounded-md hover:bg-grey-100/50">
        <div
          className={`rounded-l-md h-[100px] flex justify-center items-center w-7 ${iconColorClass}`}
        >
          <Icon className={`w-4 h-4`} />
        </div>
        <div className="flex flex-col px-1">
          <p className="text-[14px] text-grey-400">{data.label}</p>
          <p className="font-bold text-[16px]">{data.description}</p>
        </div>
      </div>
      <Handle type="target" position={Position.Left} />
      <Handle type="source" position={Position.Right} />
    </div>
  );
};
const FlowChart = () => {
  const nodeTypes = { customNode: CustomNode };

  const nodes = [
    {
      id: "1",
      type: "customNode",
      position: { x: 0, y: 0 },
      data: {
        label: "Greetings",
        description: "Hello! Welcome to [Healthcare Provider].",
        icon: require("lucide-react").Zap,
        color: "green",
      },
    },
    {
      id: "2",
      type: "customNode",
      position: { x: 300, y: -300 },
      data: {
        label: "Action",
        description: "Schedule an appointment",
        icon: require("lucide-react").MessageSquare,
        color: "red",
      },
    },
    {
      id: "3",
      type: "customNode",
      position: { x: 300, y: -150 },
      data: {
        label: "Action",
        description: "Speak to a doctor",
        icon: require("lucide-react").Phone,
        color: "blue",
      },
    },
    {
      id: "4",
      type: "customNode",
      position: { x: 300, y: 0 },
      data: {
        label: "Action",
        description: "Refill prescriptions",
        icon: require("lucide-react").PaintBucket,
        color: "yellow",
      },
    },
    {
      id: "5",
      type: "customNode",
      position: { x: 300, y: 150 },
      data: {
        label: "Action",
        description: "Get test results",
        icon: require("lucide-react").FileUser,
        color: "purple",
      },
    },
    {
      id: "16",
      type: "customNode",
      position: { x: 300, y: 300 },
      data: {
        label: "Follow-Up and Feedback Collection",
        description: "Follow up and feedback collection",
        icon: require("lucide-react").UserRoundCheck,
        color: "orange",
      },
    },
    {
      id: "6",
      type: "customNode",
      position: { x: 700, y: -830 },
      data: {
        label: "Appointment Schedule",
        description: "What is your preferred date and time?",
        icon: require("lucide-react").MessageSquare,
        color: "red",
      },
    },
    {
      id: "7",
      type: "customNode",
      position: { x: 940, y: -710 },
      data: {
        label: "Appointment Schedule",
        description: "Do you want to know available doctors?",
        icon: require("lucide-react").MessageSquare,
        color: "red",
      },
    },
    {
      id: "8",
      type: "customNode",
      position: { x: 1160, y: -590 },
      data: {
        label: "Appointment Schedule",
        description: "Do you want to confirm appointment details?",
        icon: require("lucide-react").MessageSquare,
        color: "red",
      },
    },
    {
      id: "9",
      type: "customNode",
      position: { x: 1380, y: -470 },
      data: {
        label: "Appointment Schedule",
        description: "Do you want to send a confirmation message?",
        icon: require("lucide-react").MessageSquare,
        color: "red",
      },
    },
    {
      id: "10",
      type: "customNode",
      position: { x: 1600, y: -350 },
      data: {
        label: "Speak to a doctor...",
        description: "Do you have complex queries?",
        icon: require("lucide-react").Phone,
        color: "blue",
      },
    },
    {
      id: "11",
      type: "customNode",
      position: { x: 1820, y: -230 },
      data: {
        label: "Speak to a doctor...",
        description:
          "I'll connect you to a healthcare professional. Please wait.",
        icon: require("lucide-react").Phone,
        color: "blue",
      },
    },
    {
      id: "12",
      type: "customNode",
      position: { x: 2040, y: -110 },
      data: {
        label: "Speak to a doctor...",
        description: "You should visit a specialist urgently.",
        icon: require("lucide-react").Phone,
        color: "blue",
      },
    },
    {
      id: "13",
      type: "customNode",
      position: { x: 2040, y: 110 },
      data: {
        label: "Refill Prescriptions",
        description: "Verify your previous prescriptions.",
        icon: require("lucide-react").PaintBucket,
        color: "yellow",
      },
    },
    {
      id: "14",
      type: "customNode",
      position: { x: 1820, y: 230 },
      data: {
        label: "Refill Prescriptions",
        description: "Ask for refill confirmation.",
        icon: require("lucide-react").PaintBucket,
        color: "yellow",
      },
    },
    {
      id: "15",
      type: "customNode",
      position: { x: 1600, y: 350 },
      data: {
        label: "Refill Prescriptions",
        description: "Notify the pharmacy or arrange delivery.",
        icon: require("lucide-react").PaintBucket,
        color: "yellow",
      },
    },
    {
      id: "17",
      type: "customNode",
      position: { x: 700, y: 830 },
      data: {
        label: "Follow-Up and Feedback Collection",
        description: "Send post-service follow-up.",
        icon: require("lucide-react").UserRoundCheck,
        color: "orange",
      },
    },
    {
      id: "18",
      type: "customNode",
      position: { x: 940, y: 710 },
      data: {
        label: "Follow-Up and Feedback Collection",
        description: "How would you rate your experience today?",
        icon: require("lucide-react").UserRoundCheck,
        color: "orange",
      },
    },
    {
      id: "19",
      type: "customNode",
      position: { x: 1160, y: 590 },
      data: {
        label: "Get test results",
        description: "What is your card number?",
        icon: require("lucide-react").FileUser,
        color: "purple",
      },
    },
    {
      id: "20",
      type: "customNode",
      position: { x: 1380, y: 470 },
      data: {
        label: "Get test results",
        description: "Would you like to see your test results?",
        icon: require("lucide-react").FileUser,
        color: "purple",
      },
    },
  ];

  const edges = [
    { id: "e1-2", source: "1", target: "2", type: "smoothstep" },
    { id: "e1-3", source: "1", target: "3", type: "smoothstep" },
    { id: "e1-4", source: "1", target: "4", type: "smoothstep" },
    { id: "e1-16", source: "1", target: "16", type: "smoothstep" },
    { id: "e1-5", source: "1", target: "5", type: "smoothstep" },
    { id: "e2-6", source: "2", target: "6", type: "smoothstep" },
    { id: "e2-7", source: "2", target: "7", type: "smoothstep" },
    { id: "e2-8", source: "2", target: "8", type: "smoothstep" },
    { id: "e2-9", source: "2", target: "9", type: "smoothstep" },
    { id: "e3-10", source: "3", target: "10", type: "smoothstep" },
    { id: "e3-11", source: "3", target: "11", type: "smoothstep" },
    { id: "e3-12", source: "3", target: "12", type: "smoothstep" },
    { id: "e4-13", source: "4", target: "13", type: "smoothstep" },
    { id: "e4-14", source: "4", target: "14", type: "smoothstep" },
    { id: "e4-15", source: "4", target: "15", type: "smoothstep" },
    { id: "e16-17", source: "16", target: "17", type: "smoothstep" },
    { id: "e16-18", source: "16", target: "18", type: "smoothstep" },
    { id: "e5-19", source: "5", target: "19", type: "smoothstep" },
    { id: "e5-20", source: "5", target: "20", type: "smoothstep" },
  ];

  const Actions = [
    {
      id: "1",
      label: "Action",
      description: "Create call task",
      icon: require("lucide-react").PhoneCall,
      action: "createCall",
    },
    {
      id: "2",
      label: "Action",
      description: "Send SMS",
      icon: require("lucide-react").MessageCircleMore,
      action: "sendSMS",
    },
    {
      id: "3",
      label: "Action",
      description: "Cancel task",
      icon: require("lucide-react").XCircle,
      action: "cancelTask",
    },
    {
      id: "4",
      label: "Action",
      description: "Webhook",
      icon: require("lucide-react").Webhook,
      action: "webhook",
    },
    {
      id: "5",
      label: "Action",
      description: "Assign Agent",
      icon: require("lucide-react").Headphones,
      action: "assignAgent",
    },
    {
      id: "6",
      label: "Action",
      description: "Update task",
      icon: require("lucide-react").LoaderPinwheel,
      action: "updateTask",
    },
    {
      id: "7",
      label: "Action",
      description: "Schedule Callback",
      icon: require("lucide-react").Calendar,
      action: "callbacks",
    },
  ];
  const [isCollapsed, setIsCollapsed] = useState(true);
  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className="h-[76.5%] w-full relative">
      <div
        className={`${
          isCollapsed ? "h-14" : "h-[560px]"
        } bg-white absolute right-6 z-50 w-80 overflow-auto transition-all duration-300`}
      >
        <div
          className="cursor-pointer flex gap-4 my-4 items-center"
          onClick={toggleCollapse}
        >
          {isCollapsed ? (
            <div>
              <ChevronDown />
            </div>
          ) : (
            <div>
              <ChevronUp />
            </div>
          )}

          <span className="truncate font-bold text-2xl">Building Blocks</span>
        </div>
        {!isCollapsed && (
          <div className="">
            <div className="bg-grey-600/50 my-3 p-3 font-bold text-lg">
              <p>Actions</p>
            </div>
            <div className="grid grid-cols-2 gap-4 p-2">
              {Actions.map((action, index) => {
                return (
                  <div key={index}>
                    <div className="flex gap-4 items-center h-[50px] w-full cursor-pointer border border-black/70 rounded-md hover:bg-grey-100/50">
                      <div className="bg-green-400/40 rounded-l-md h-[50px] flex justify-center items-center w-6">
                        <action.icon className="w-4 h-4 text-green" />
                      </div>
                      <div className="flex flex-col gap-">
                        <p className="text-[12px] text-grey-400">
                          {action.label}
                        </p>
                        <p className="font-bold text-[12px]">
                          {action.description}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="bg-grey-600/50 my-3 p-3 font-bold text-lg">
              <p>Timing</p>
            </div>
            <div className="grid grid-cols-2 gap-4 p-2">
              <div>
                <div className="flex gap-4 items-center h-[50px] w-full cursor-pointer border border-black/70 rounded-md hover:bg-grey-100/50">
                  <div className="bg-secondary-light h-[50px] rounded-l-md flex justify-center items-center w-6">
                    <Timer className="w-4 h-4 text-secondary-dark" />
                  </div>
                  <div className="flex flex-col gap-">
                    <p className="text-[12px] text-grey-400">Timing</p>
                    <p className="font-bold text-[12px]">Wait 30 seconds</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        fitView
        style={{ background: "#f9fafb" }}
      >
        <Background color="#aaa" gap={16} />
        <Controls />
      </ReactFlow>
    </div>
  );
};

export default FlowChart;
