import { Button, Input } from "components";
import React, { useState } from "react";
const { getToken } = require("shared/resources");
const { routes } = require("api/routes");

const Freshchats = () => {
  const [formData, setFormData] = useState({
    domain: "",
    apiKey: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}${routes.CREATE_FRESHCHAT_INTEGRATION.URL}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify({
            metadata: {
              domain: formData.domain,
              apiKey: formData.apiKey,
            },
            channel: "freshchat",
            status: "active",
          }),
        }
      );

      if (!response.ok) throw new Error("Failed to save configuration");

      setFormData({ domain: "", apiKey: "" });
    } catch (error) {
      console.error("Error saving Freshchat configuration:", error);
    }
  };

  return (
    <div className="bg-[#f1f1f1] shadow-md h-[550px] w-[90%] m-auto">
      <div className="p-6 border-b border-black/40 font-bold text-[14px]">
        <p>
          <span className="text-blue-light">Sign Up</span> for a FreshChat
          account
        </p>
      </div>
      <div className="p-6">
        <p className="font-bold">
          This integration allows you to handle the live chat in FreshChat when
          the bot automation is paused
        </p>
        <form
          className="mt-7 flex flex-col w-[80%] m-auto gap-5"
          onSubmit={handleSubmit}
        >
          <div className="flex items-center justify-center gap-4">
            <label className="w-[150px]">Domain</label>
            <div className="flex flex-col gap-1 w-full">
              <Input
                name="domain"
                value={formData.domain}
                onChange={handleInputChange}
                placeholder="https://domain-org.freshworks.com"
              />
              <p className="text-[12px] text-[#87909B]">
                e.g https://domain-org.freshworks.com go to Admin Settings -&gt;
                Mobile App for Chat{" "}
              </p>
            </div>
          </div>
          <div className="flex items-center justify-center gap-4">
            <label className="w-[150px]">Api Key</label>
            <div className="flex flex-col gap-1 w-full">
              <Input
                name="apiKey"
                value={formData.apiKey}
                onChange={handleInputChange}
                placeholder="Api Key"
              />
              <p className="text-[12px] text-[#87909B]">
                You can get API Key from FreshChat portal, go to Personal
                Settings -&gt; API Settings -&gt; API Details for Chat
              </p>
            </div>
          </div>

          <div className="w-full justify-between flex items-center gap-4">
            <div className="w-[250px]"></div>
            <div className="w-full justify-between flex items-center">
              <div className="w-[100px]">
                <Button type="submit">Save</Button>
              </div>
              <div className="w-[100px]">
                <Button background="danger">Clear</Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Freshchats;
