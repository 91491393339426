import { Button, Input } from "components";
import React from "react";

const Settings = () => {
  return (
    <div className="bg-[#f1f1f1] shadow-md h-[440px] rounded-md w-full mt-8">
      <div className="p-6 border-b border-black/40 font-bold text-[14px]">
        <p>
          <span className="text-blue-light">Sign Up</span> for an Open AI
          account
        </p>
      </div>
      <div className="p-6 mt-8">
        <form className="mt-7 flex flex-col w-full m-auto gap-5">
          <div className="flex items-center justify-center gap-4">
            <label className="w-[300px]">Api Key</label>
            <Input placeholder="Api Key" />
          </div>
          <div className="flex items-center justify-center gap-4">
            <label className="w-[300px]">Organization id (optional)</label>
            <Input placeholder="Organization id (optional)" />
          </div>

          <div className="w-full justify-between flex items-center gap-4">
            <div className="w-[300px]"></div>
            <div className="w-full justify-between flex items-center">
              <div className="w-[100px]">
                <Button type="button">Save</Button>
              </div>
              <div className="w-[100px]">
                <button className="bg-[#EF4444] xxl:h-[56px] xl:h-[45px] lg:h-[45px] md:h-[41px] sm:h-[40px] xs:h-[40px] w-full rounded-md text-white">
                  Clear
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Settings;
