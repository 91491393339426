import { getMemberListing } from "api";
import { X } from "assets/images";
import { Avatar, Button, Spinner } from "components";
import ListManager from "components/ListManager";
import useAssignUnassignMemberNumber from "hooks/useAssignUnassignMemberNumber";
import { useAuth } from "hooks/useAuth";
import { LoadingItem } from "pages/Dashboard";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const MemberListItem = ({ item, ...rest }) => {
  const { user } = useAuth();
  const isDisabled = item?.status === 0;
  return (
    <label
      htmlFor={item?.uuid}
      className="flex items-center justify-between py-3 border-grey-300 border-b cursor-pointer "
    >
      <div className="flex gap-3">
        <div className="relative">
          <Avatar
            name={`${item?.first_name} ${item?.last_name}`}
            mediaUrl={`${process.env.REACT_APP_BASE_API_URL}${
              user?.company_uuid || user?.uuid
            }/profile/${item?.profile_pic}`}
          />
        </div>
        <div className="flex flex-col gap-1">
          <div className="flex items-center text-sm font-medium	 text-grey-900">
            {item?.first_name} {item?.last_name ?? ""}&nbsp;&nbsp;
          </div>
        </div>
      </div>
      {isDisabled ? null : (
        <div className="flex text-xs text-green gap-1">
          <input
            className="checked:bg-green checked:hover:bg-green checked:active:bg-green checked:focus:bg-green focus:outline-none focus:ring-1 focus:ring-green"
            id={item?.uuid}
            type="checkbox"
            value={item?.uuid}
            onChange={(e) => {
              if (rest?.itemValue.includes(e.target.value)) {
                rest.itemValue.splice(
                  rest.itemValue.indexOf(e.target.value),
                  1
                );
                rest?.onChangeItemHandler([...rest.itemValue]);
              } else {
                rest?.onChangeItemHandler([...rest.itemValue, e.target.value]);
              }
            }}
            checked={rest?.itemValue?.includes(item?.uuid)}
          />
        </div>
      )}
    </label>
  );
};

const AssignToModal = ({ handleClose = () => null, showModal }) => {
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [hasData, setHasdata] = useState(false);
  const { t } = useTranslation();

  const itemElement = useMemo(() => showModal?.elementData, [showModal]);
  const onSuccessFn = () => {
    handleClose();
  };

  const { mutate: assignTeamMutate, isPending: assignTeamLoad } =
    useAssignUnassignMemberNumber({ onSuccessFn });

  useEffect(() => {
    if (
      itemElement &&
      itemElement?.assigned_members &&
      itemElement?.assigned_members?.length > 0
    ) {
      const oldSelectedTeams = itemElement?.assigned_members?.map(
        (item) => item?.uuid
      );
      setSelectedMembers(oldSelectedTeams);
    }
  }, []);

  const handleAssignTeam = () => {
    const payload = {
      members: selectedMembers,
      uuid: itemElement?.uuid,
    };
    assignTeamMutate(payload);
  };

  return (
    <div className="min-w-[460px]">
      <div className="flex justify-between items-start py-4 px-6">
        <div className="flex flex-col gap-1">
          <div className="text-xl font-semibold text-black">
            {t("overview.assign")} {itemElement?.did_number}
          </div>
          <div className="text-sm  text-grey-700">
            {t("overview.selectTeamMember")}
          </div>
        </div>
        <button type="button" onClick={() => handleClose()}>
          <X className="text-grey-600 w-6 h-6" />
        </button>
      </div>

      <div className=" px-6 py-3 border-grey-300 border-t overflow-auto xxl:max-h-[330px] xl:max-h-[330px] lg:max-h-[330px] md:max-h-[254px]">
        <ListManager
          setHasdata={setHasdata}
          actionFn={getMemberListing}
          queryKey="getMemberListing"
          listItem={MemberListItem}
          loadingComponent={LoadingItem}
          onChangeItemHandler={setSelectedMembers}
          itemValue={selectedMembers}
        />
      </div>

      <div className=" mt-10 px-6 pb-6">
        <Button
          extraClasses="justify-start pl-4 cursor-pointer"
          type="button"
          onClick={() => handleAssignTeam()}
          disabled={assignTeamLoad || !hasData}
        >
          {assignTeamLoad ? <Spinner /> : t("overview.assign")}
        </Button>
      </div>
    </div>
  );
};

export default AssignToModal;
