import { ZigiIcon } from "assets/images";
import { Button } from "components";
import React from "react";

const Zigi = () => {
  return (
    <div
      className={`h-[309px] bg-[#F1F1F1] shadow-md flex flex-col justify-center px-6`}
    >
      <div className="flex flex-col gap-7">
        <div className="flex items-center space-x-2">
          <ZigiIcon className="w-8 h-8" />
          <p className="pt-1">Zigi</p>
        </div>
        <div className="flex flex-col gap-6">
          <p className="font-bold text-[#0D121D]">
            Connect Zigi to your Fonu Account
          </p>
          <p className="text-[#474A4E] text-[14px]">
            You can create Zigi bot on connected MTN accounts...
          </p>
          <div className="w-[400px]">
            <Button>Connect to Zigi</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Zigi;
