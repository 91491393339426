import { Plus, SmsIcon, X } from "assets/images";
import { Button, Input } from "components";
import React, { useState } from "react";

function SmsSocials() {
  const [showModal, setShowModal] = useState(false);
  return (
    <div className="flex flex-col gap-3">
      <div className="flex gap-2 items-center">
        <SmsIcon />
        <p>Sms</p>
      </div>
      <p>You can create SMS flow on added phone number.</p>
      <Button
        onClick={() => setShowModal(true)}
        width="300"
        extraClasses="w-[300px] h-[40px] gap-3"
      >
        <Plus />
        Add Phone Number
      </Button>
      {showModal && (
        <div
          className="fixed inset-0 z-50 animate-modalAnimation flex items-center justify-center bg-black bg-opacity-50"
          onClick={() => setShowModal(false)}
        >
          <div
            className="bg-white p-4 rounded-lg w-full sm:w-[800px] relative h-[400px] overflow-scroll"
            onClick={(e) => e.stopPropagation()}
          >
            <div
              onClick={() => setShowModal(false)}
              className="absolute cursor-pointer right-6 top-6"
            >
              <X />
            </div>
            <p className="font-semibold text-lg mt-12">Add Phone Number</p>
            <form className="flex gap-4 items-center mt-4">
              <Input placeholder={"SeamC"} type="text" className="w-[50%]" />
              <div className="flex items-center w-full">
                <input
                  type="text"
                  placeholder="+2348012345677"
                  className="w-full px-4 py-2 border-grey-400 focus:border-green rounded-l-md xxl:h-[56px] xl:h-[45px] lg:h-[45px] md:h-[41px] sm:h-[40px] xs:h-[40px] focus:ring-green-200"
                />
                <button
                  type="button"
                  className="w-full px-4 py-2 text-white bg-green-400 rounded-r-md xxl:h-[56px] xl:h-[45px] lg:h-[45px] md:h-[41px] sm:h-[40px] xs:h-[40px]"
                >
                  Find Number
                </button>
              </div>
            </form>
            <table className="w-full mt-8 ">
              <thead className="w-full text-center border-b h-16 border-[#D9D9D9]">
                <tr>
                  <th className="w-[100px]">Phone Number</th>
                  <th className="w-[100px]">Provider</th>
                  <th className="w-[100px]">Voice</th>
                  <th className="w-[100px]">SMS</th>
                  <th className="w-[100px]">Data</th>
                </tr>
              </thead>
              <tbody className="w-full text-center h-12">
                <tr>
                  <td>+2348012345677</td>
                  <td>MTN</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}

export default SmsSocials;
