import { getTransactionList } from "api";
import { TableManager } from "components";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { formatDateTime } from "shared/resources";

export const Transactions = () => {
  const { t } = useTranslation();
  const columns = useMemo(
    () => [
      {
        accessorKey: "created_at",
        header: () => t("overview.date"),
        cell: (props) => {
          const [date] = formatDateTime(props?.getValue());
          return <span>{date}</span>;
        },
      },
      {
        accessorKey: "bill_no",
        header: () => t("overview.transactionNo"),
      },

      {
        accessorKey: "type",
        header: () => t("overview.type"),
      },
      {
        accessorKey: "desc",
        header: () => t("overview.description"),
        cell: (props) => (
          <span
            style={{
              textTransform: "capitalize",
            }}
          >
            {props?.getValue()?.toLowerCase() ?? ""}
          </span>
        ),
      },

      {
        accessorKey: "total_amount",
        header: () => t("overview.amount"),
        meta: {
          isSortable: true,
        },
      },
      {
        accessorKey: "status",
        header: () => t("overview.status"),
      },
    ],

    []
  );

  return (
    <div className="overflow-auto w-full px-6 pt-6 pb-6">
      <TableManager
        {...{
          fetcherKey: "getTransactionList",
          fetcherFn: getTransactionList,
          columns,
          name: "Billings",
          showPagination: true,
          isSearchable: true,
          searchLabel: t("overview.manageBillings"),
          searchPlaceholder: t("overview.searchByTransaction"),
        }}
      />
    </div>
  );
};
