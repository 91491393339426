import { CustomSelectServer, Input, ReadViewInput } from "components";
import React from "react";
import { Controller } from "react-hook-form";
import DocumentUploadInput from "./DocumentUploadInput";
import { Check } from "assets/images";
import { useTranslation } from "react-i18next";

const IDTypeItem = ({ item }) => {
  return (
    <div
      key={item.value}
      value={item?.value}
      className="w-full h-full flex  justify-start gap-2 items-center py-2 px-3"
    >
      {item?.label}
    </div>
  );
};
const UploadDocumentsForm = ({
  control,
  watch,
  errors,
  setValue,
  readView,
}) => {
  const isStepDone =
    watch("document_details.id_type") &&
    watch("document_details.id_number") &&
    watch("document_details.id_expiry_date") &&
    watch("document_details.id_file");
  const { t } = useTranslation();

  return (
    <div className="flex gap-6  max-w-[991px]">
      {isStepDone ? (
        <div className="rounded-full w-10 min-w-[40px] min-h-[40px] h-10 text-white flex items-center justify-center bg-green">
          <Check />
        </div>
      ) : (
        <div className="rounded-full w-10 min-w-[40px] min-h-[40px] h-10 text-green flex items-center justify-center bg-green-200">
          3
        </div>
      )}
      <div className="flex flex-col  w-full">
        <div className="text-grey-900 text-lg font-semibold">
          {t("overview.uploadDocuments")}
        </div>
        <div className="text-grey-700 text-sm mb-8">
          {t("overview.uploadStory")}
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex  justify-between items-center gap-4 ">
            {readView ? (
              <ReadViewInput
                labelText={t("overview.validIdType")}
                valueText={watch("document_details.id_type")}
              />
            ) : (
              <Controller
                name="document_details.id_type"
                control={control}
                render={({ field }) => {
                  return (
                    <CustomSelectServer
                      {...field}
                      height="xxl:h-14 xl:h-12"
                      onWhitebg={true}
                      label={t("overview.validIdType")}
                      placeholder={t("overview.selectOption")}
                      error={errors?.document_details?.id_type?.message}
                      ItemComponent={IDTypeItem}
                      labelKey={"name"}
                      valueKey={"uuid"}
                      isMulti={false}
                      actionFn={() => []}
                      queryKey={"idType"}
                    />
                  );
                }}
              />
            )}

            {readView ? (
              <ReadViewInput
                labelText={t("overview.validIdNum")}
                valueText={watch("document_details.id_number")}
              />
            ) : (
              <Controller
                name="document_details.id_number"
                control={control}
                render={({ field }) => {
                  return (
                    <Input
                      {...field}
                      label={t("overview.validIdNum")}
                      autoComplete="off"
                      maxLength="30"
                      error={errors?.document_details?.id_number?.message}
                    />
                  );
                }}
              />
            )}
          </div>
          <div className="flex  justify-between items-start gap-4">
            {readView ? (
              <div className="mt-4 w-full">
                <ReadViewInput
                  labelText={t("overview.validIdExpiry")}
                  valueText={watch("document_details.id_expiry_date")}
                />
              </div>
            ) : (
              <Controller
                name="document_details.id_expiry_date"
                control={control}
                render={({ field }) => {
                  return (
                    <Input
                      {...field}
                      type="date"
                      label={t("overview.validIdExpiry")}
                      autoComplete="off"
                      error={errors?.document_details?.id_expiry_date?.message}
                    />
                  );
                }}
              />
            )}

            <DocumentUploadInput
              setValue={setValue}
              watch={watch}
              label={t("overview.uploadValidId")}
              valueKey="document_details.id_file"
              readView={readView}
              error={errors?.document_details?.id_file?.message}
            />
          </div>
        </div>
        <div></div>
        <div className="text-grey-900 text-lg font-semibold mt-8">Others</div>
        <div className="text-grey-700 text-sm mb-5">
          {t("overview.uploadPassportStory")}
        </div>

        <div className="flex  justify-between items-center gap-4">
          <DocumentUploadInput
            setValue={setValue}
            watch={watch}
            label={t("overview.uploadPassport")}
            valueKey="document_details.passport_photo"
            readView={readView}
            error={errors?.document_details?.passport_photo?.message}
          />

          <DocumentUploadInput
            setValue={setValue}
            watch={watch}
            label={t("overview.uploadProofOfAdd")}
            valueKey="document_details.address_proof"
            readView={readView}
            error={errors?.document_details?.address_proof?.message}
          />
        </div>
      </div>
    </div>
  );
};

export default UploadDocumentsForm;
