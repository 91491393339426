import { yupResolver } from "@hookform/resolvers/yup";
import { X } from "assets/images";
import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { addIvrFormInitialValues, ivrFormValidationSchema } from "../constants";
import {
  Button,
  CustomAudioPlayer,
  CustomSelectServer,
  Input,
  Skeletons,
  Spinner,
} from "components";
import { greetingList } from "api";
import KeyActions from "./KeyActions";
import ForwardToInput from "pages/PhoneNumbers/SetForwardingModal/ForwardToInput";
import { useCreateIVR, useUpdateIVR } from "hooks";
import { useAuth } from "hooks/useAuth";
import { useTranslation } from "react-i18next";

const loader = () => (
  <div className="w-9 h-9 rounded-full">
    <Skeletons type="circle" />
  </div>
);

const VoicemailItem = ({ item }) => {
  return (
    <div
      key={item?.filename}
      value={item?.filename}
      className="w-full h-full flex  justify-start gap-2 items-center py-2 px-3"
    >
      {item?.name}
    </div>
  );
};

const AddIVRModal = ({
  handleClose = () => null,
  showModal,
  isEdit = false,
}) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { mutate: createIVRMutate, isPending: createIVRLoad } = useCreateIVR({
    handleSuccess,
  });
  const { mutate: updateIVRMutate, isPending: updateIVRLoad } = useUpdateIVR({
    handleSuccess,
  });
  const itemElement = showModal?.elementData ?? {};
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    clearErrors,
    setValue,
  } = useForm({
    resolver: yupResolver(ivrFormValidationSchema),
    defaultValues: addIvrFormInitialValues,
    mode: "onChange",
  });

  useEffect(() => {
    if (isEdit) {
      setValue("name", itemElement?.name);
      setValue("greeting_short", itemElement?.greeting_short);
      setValue("ivr_option", itemElement?.ivr_option);
      setValue("max_failures", itemElement?.max_failures);
      setValue("max_timeouts", itemElement?.max_timeouts);
      setValue("invalid_sound", itemElement?.invalid_sound);
      if (itemElement?.exec_on_max_failures) {
        setValue(
          "exec_on_max_failures.type",
          itemElement?.exec_on_max_failures?.type
        );
        setValue(
          "exec_on_max_failures.value",
          itemElement?.exec_on_max_failures?.value
        );
      }
      if (itemElement?.exec_on_max_timeouts) {
        setValue("exec_on_max_timeouts", itemElement?.exec_on_max_timeouts);
      }
    }
  }, [isEdit]);

  function handleSuccess() {
    handleClose();
  }
  const onSubmit = (values) => {
    if (isEdit) {
      updateIVRMutate({ uuid: itemElement?.uuid, ...values });
    } else {
      createIVRMutate(values);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="min-w-[1050px]">
      <div className="flex justify-between items-start pt-4 px-6 ">
        <div className="flex flex-col gap-1">
          <div className="text-xl font-semibold text-black">
            {isEdit
              ? t("overview.updatePhoneMenu")
              : t("overview.createPhoneMenu")}
          </div>
          <div className="text-sm  text-grey-700">
            {t("overview.enterPhoneMenu")}
          </div>
        </div>

        <button type="button" onClick={() => handleClose()}>
          <X className="text-grey-600 w-6 h-6" />
        </button>
      </div>
      <div className="px-6 overflow-auto xxl:max-h-[610px] xl:max-h-[460px] lg:max-h-[400px] md:max-h-[345px]">
        <div className=" flex items-center gap-6 mt-12">
          <div className="w-1/2">
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  onWhitebg={true}
                  label={t("overview.name")}
                  error={errors?.name?.message}
                  maxLength={50}
                />
              )}
            />
          </div>
          <div className="w-1/2 flex items-end gap-1">
            <Controller
              name={"greeting_short"}
              control={control}
              render={({ field }) => (
                <CustomSelectServer
                  {...field}
                  height="xxl:h-14 xl:h-12"
                  onWhitebg={true}
                  label={t("overview.greetingMessage")}
                  placeholder={t("overview.selectGreeting")}
                  error={errors?.greeting_short?.message}
                  ItemComponent={VoicemailItem}
                  labelKey={"name"}
                  valueKey={"filename"}
                  isMulti={false}
                  actionFn={greetingList}
                  queryKey={"greetingList"}
                />
              )}
            />
            {watch("greeting_short") ? (
              <span className="mb-1">
                <CustomAudioPlayer
                  loadingItem={loader}
                  minimal={true}
                  srcUrl={`${process.env.REACT_APP_BASE_API_URL}${
                    user?.company_uuid || user?.uuid
                  }/greeting/${watch("greeting_short")}`}
                />
              </span>
            ) : null}
          </div>
        </div>

        <div className="mt-5">
          <div className="text-base font-semibold mb-[10px]">
            {t("overview.keyActions")}
          </div>
          <KeyActions
            clearErrors={clearErrors}
            control={control}
            errors={errors}
            setValue={setValue}
            watch={watch}
          />
        </div>
        <div className="mt-[30px]">
          <div className="text-base font-semibold mb-[10px]">
            {t("overview.inCase")}
          </div>

          <div className="flex items-center gap-6">
            <div className="min-w-[160px]">
              <Controller
                name={"max_failures"}
                control={control}
                render={({ field }) => {
                  return (
                    <Input
                      {...field}
                      label={t("overview.allowRetries")}
                      error={errors?.max_failures?.message}
                      autoComplete="off"
                      onWhitebg={true}
                      type="number"
                      placeholder=""
                    />
                  );
                }}
              />
            </div>
            <div className="w-full">
              <ForwardToInput
                clearErrors={clearErrors}
                forward_type_key="exec_on_max_failures.type"
                forward_value_key="exec_on_max_failures.value"
                forward_type_error={errors?.exec_on_max_failures?.type?.message}
                forward_value_error={
                  errors?.exec_on_max_failures?.value?.message
                }
                control={control}
                watch={watch}
                setValue={setValue}
              />
            </div>
            <div className="w-full flex gap-1 items-end">
              <Controller
                name={"invalid_sound"}
                control={control}
                render={({ field }) => (
                  <CustomSelectServer
                    {...field}
                    height="xxl:h-14 xl:h-12"
                    onWhitebg={true}
                    label={t("overview.invalidMes")}
                    placeholder={t("overview.selectMessage")}
                    error={errors?.invalid_sound?.message}
                    ItemComponent={VoicemailItem}
                    labelKey={"name"}
                    valueKey={"filename"}
                    isMulti={false}
                    actionFn={greetingList}
                    queryKey={"greetingList"}
                  />
                )}
              />
              {watch("invalid_sound") ? (
                <span className="mb-1">
                  <CustomAudioPlayer
                    loadingItem={loader}
                    minimal={true}
                    srcUrl={`${process.env.REACT_APP_BASE_API_URL}${
                      user?.company_uuid || user?.uuid
                    }/greeting/${watch("invalid_sound")}`}
                  />
                </span>
              ) : null}
            </div>
          </div>
        </div>
        <div className="mt-5">
          <div className="text-base font-semibold mb-[10px]">
            {t("overview.inCaseOfNoInput")}
          </div>

          <div className="flex items-center gap-6 mb-1">
            <div className="min-w-[160px]">
              <Controller
                name={"max_timeouts"}
                control={control}
                render={({ field }) => {
                  return (
                    <Input
                      {...field}
                      label={t("overview.allowRetries")}
                      error={errors?.max_timeouts?.message}
                      autoComplete="off"
                      onWhitebg={true}
                      type="number"
                      placeholder=""
                    />
                  );
                }}
              />
            </div>
            <ForwardToInput
              clearErrors={clearErrors}
              forward_type_key="exec_on_max_timeouts.type"
              forward_value_key="exec_on_max_timeouts.value"
              forward_type_error={errors?.exec_on_max_timeouts?.type?.message}
              forward_value_error={errors?.exec_on_max_timeouts?.value?.message}
              control={control}
              watch={watch}
              setValue={setValue}
            />
            {/* <Controller
            name={'no_input_sound'}
            control={control}
            render={({ field }) => (
              <CustomSelectServer
                {...field}
                height="xxl:h-14 xl:h-12"
                onWhitebg={true}
                label="Greeting message"
                placeholder="Select greeting"
                error={errors?.no_input_sound?.message}
                ItemComponent={VoicemailItem}
                labelKey={'name'}
                valueKey={'uuid'}
                isMulti={false}
                actionFn={greetingList}
                queryKey={'greetingList'}
              />
            )}
          /> */}
          </div>
        </div>
      </div>
      <div className=" pb-6 px-6  xxl:mt-14 xl:mt-[40px] lg:mt-[40px] md:mt-[40px] w-[191px] ml-auto">
        <Button type="submit" disabled={createIVRLoad || updateIVRLoad}>
          {createIVRLoad || updateIVRLoad ? <Spinner /> : t("overview.submit")}
        </Button>
      </div>
    </form>
  );
};

export default AddIVRModal;
