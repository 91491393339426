import { Backspace } from "assets/images";
import NameByNumber from "components/NameByNumber";
import Timer from "components/Timer";
import { useUserDetailsByParams } from "hooks";
import React, { useEffect, useState } from "react";
import { formatPhoneNumber } from "shared/resources";

const DialerUpperScreen = ({
  callSessions = [],
  phoneNumber,
  setPhoneNumber = () => null,
  handleMakeCall = () => null,
  inputRef,
  callActiveKey,
  action,
}) => {
  const [numberData, setNumberData] = useState(null);
  const isCallExist = callSessions.length > 0;
  const activeCallData =
    callSessions.filter((call) => call?.callID === callActiveKey)?.[0] ?? {};

  const { mutate: userDetailsMutate, data: userDetailsData } =
    useUserDetailsByParams();

  useEffect(() => {
    if (activeCallData && Object.keys(activeCallData).length > 0) {
      const payload = {};

      if (activeCallData?._number?.length > 5) {
        payload["did_number"] = activeCallData?._number;
      } else {
        payload["extension"] = activeCallData?._number;
      }
      userDetailsMutate(payload);
    }
  }, [activeCallData]);

  useEffect(() => {
    if (userDetailsData && userDetailsData?.data?.data) {
      setNumberData(userDetailsData?.data?.data);
    }
  }, [userDetailsData]);

  const handleChange = (event) => {
    setPhoneNumber(
      event.target.value
        .toString()
        .replace(/[^+0-9*#]/g, "")
        .slice(0, 14)
    );
  };

  const handleBackspace = () => {
    setPhoneNumber((prevPhoneNumber) => prevPhoneNumber.slice(0, -1));
  };

  const handleCallerID = (activeCallData = {}, numberData = {}) => {
    let callerIDSettings;
    let userData;
    if (activeCallData?._number?.length > 5) {
      callerIDSettings = numberData?.settings?.caller_id;
      userData = numberData?.user;
    } else {
      callerIDSettings = numberData?.did?.settings?.caller_id;
      userData = numberData;
    }

    if (activeCallData?._direction === "inbound") {
      return (
        <div className="text-white">
          <NameByNumber number={activeCallData?._number} />
        </div>
      );
    } else {
      switch (callerIDSettings?.name_outgoing_call) {
        case "A":
          return (
            <div className="text-white ">
              <NameByNumber number={activeCallData?._number} />
              {userData?.company_name && (
                <div className="text-sm text-grey-700 flex justify-center">
                  {userData.company_name ?? "NA"}
                </div>
              )}
              {userData?.phone && (
                <div className="text-sm text-grey-700 flex justify-center">
                  {formatPhoneNumber(userData?.phone) ?? "NA"}
                </div>
              )}
            </div>
          );
        case "PH":
          return (
            <div className="text-white flex justify-center">
              <NameByNumber number={activeCallData?._number} />
              <div className="text-sm text-grey-700 flex justify-center">
                {formatPhoneNumber(userData?.phone) ?? "NA"}
              </div>
            </div>
          );
        case "CO":
          return (
            <div className="text-white">
              <NameByNumber number={activeCallData?._number} />
              <div className="text-sm text-grey-700 flex justify-center">
                {userData?.company_name ?? "NA"}
              </div>
            </div>
          );
        case "CU":
          return (
            <div className="text-white">
              <NameByNumber number={activeCallData?._number} />
              <div className="text-xs text-grey-800">
                {callerIDSettings?.custom_name ?? "NA"}
              </div>
            </div>
          );
        default:
          return (
            <div className="text-white">
              <NameByNumber number={activeCallData?._number} />
            </div>
          );
      }
    }
  };

  return (
    <>
      {!isCallExist ? (
        <div className="dialogInput w-full xxl:px-8 xl:pt-4 lg:px-8 md:px-8 relative ">
          <input
            type="text"
            id="number"
            name="number"
            autoComplete="off"
            value={phoneNumber}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleMakeCall(e);
              }
            }}
            onChange={handleChange}
            ref={inputRef}
            placeholder="+234 13338549"
            className="w-full border-none focus:shadow-none focus:ring-0 xxl:text-4xl xl:text-[28px] lg:text-[29px] md:text-[28px] text-white placeholder:text-grey-500 placeholder:opacity-10 text-center bg-black bg-opacity-15"
          />
          <div className="text-3xl text-white  flex items-center justify-center cursor-pointer  absolute xxl:right-4 xl:right-2 lg:right-2 md:right-2 xxl:top-[30px] xl:top-[29px] lg:top-[15px] md:top-[11px]  ">
            {phoneNumber?.length > 0 ? (
              <Backspace className="w-7" onClick={handleBackspace} />
            ) : null}
          </div>
        </div>
      ) : (
        <>
          {!action && (
            <div className="text-3xl  flex flex-col justify-center items-center gap-3">
              {handleCallerID(activeCallData, numberData)}

              {!["ringing", "connecting"].includes(activeCallData?._status) ? (
                <div className="flex jusify-center items-center flex-col text-sm text-grey-700 gap-1">
                  <div className="capitalize">
                    {activeCallData?._status || "Connected"}
                  </div>
                  <div>
                    <Timer seconds={activeCallData?._joined_at} />
                  </div>
                </div>
              ) : (
                <div className="flex items-center text-sm text-grey-700 capitalize">
                  {activeCallData?._status || "Connected"}
                </div>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default DialerUpperScreen;
