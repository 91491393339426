import { Arrow, PortPhoneIcon, X } from "assets/images";
import { Button } from "components";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const PortNumberModal = ({ handleClose = () => null }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <div className="flex justify-end items-center p-4">
        <button type="button" onClick={() => handleClose()}>
          <X className="text-grey-600 w-6 h-6" />
        </button>
      </div>
      <div className="text-center px-16 pb-5 justify-center">
        <div className="flex justify-center mt-8">
          <PortPhoneIcon />
        </div>
        <div className="text-4xl text-black font-semibold mt-4 mb-1">
          {t("overview.portExistingNumber")}
        </div>
        <div className="text-grey-700 text-base mt-4 w-96 break-all">
          {t("overview.portDescription")}
        </div>
        <div className="mt-10 pb-14">
          <Button type="button" onClick={() => navigate("form")}>
            <div className="flex w-full px-5 justify-center items-center gap-2">
              {t("overview.continuePorting")}{" "}
              <Arrow className="text-grey-600" />
            </div>
          </Button>
        </div>
      </div>
    </>
  );
};

export default PortNumberModal;
