import React, { useState } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "lucide-react";
import { Link, useLocation } from "react-router-dom";
// import { useAuth } from "hooks/useAuth";
import { useTranslation } from "react-i18next";
import {
  CalendlyIcon,
  ClaudeAiIcon,
  CloudinaryIcon,
  DbIcon,
  FacebookMessenger,
  FreshworksIcon,
  GeminiIcon,
  GmailIcon,
  GoogleMapIcon,
  GoogleTagIcon,
  HubspotIcon,
  IntercomIcon,
  Mtn,
  OpenAiIcon,
  PaystackIcon,
  Phone,
  SBucketIcon,
  SendGridIcon,
  Sheet,
  ShopifyIcon,
  SlackIcon,
  SmtpIcon,
  ZapierIcon,
  ZohoIcon,
} from "assets/images";
import { Facebook } from "assets/images/signup";

export default function Sidebar() {
  const location = useLocation();
  const { t } = useTranslation();
  const integrations = [
    {
      id: "voice-sms",
      label: "Voice SMS",
      items: [
        {
          id: "phone-number",
          label: "Phone Number",
          path: "/settings/integrations/voice-sms",
          icon: Phone,
        },
      ],
    },
    {
      id: "ai",
      label: "Artificial Intelligence",
      items: [
        {
          id: "open-ai",
          label: "Open AI",
          path: "/settings/integrations/open-ai",
          icon: OpenAiIcon,
        },
        {
          id: "google-gemini",
          label: "Google Gemini",
          path: "/settings/integrations/google-gemini",
          icon: GeminiIcon,
        },
        {
          id: "claude-ai",
          label: "Claude AI",
          path: "/settings/integrations/claude-ai",
          icon: ClaudeAiIcon,
        },
      ],
    },
    {
      id: "e-commerce",
      label: "E-commerce",
      items: [
        {
          id: "shopify",
          label: "Shopify",
          path: "/settings/integrations/shopify",
          icon: ShopifyIcon,
        },
        {
          id: "facebook-mes",
          label: "Facebook Business",
          path: "/settings/integrations/facebook-business",
          icon: FacebookMessenger,
        },
      ],
    },
    {
      id: "payment-gateway",
      label: "payment-gateway",
      items: [
        {
          id: "momo-psb",
          label: "MoMo-PSB",
          path: "/settings/integrations/momo-psb",
          icon: Mtn,
        },
        {
          id: "paystack",
          label: "PayStack",
          path: "/settings/integrations/paystack",
          icon: PaystackIcon,
        },
      ],
    },
    {
      id: "calendar",
      label: "Calendar",
      items: [
        {
          id: "calendly",
          label: "Calendly",
          path: "/settings/integrations/calendly",
          icon: CalendlyIcon,
        },
      ],
    },
    {
      id: "data-storage",
      label: "Data/File Storage",
      items: [
        {
          id: "google-sheet",
          label: "Google Sheet",
          path: "/settings/integrations/google-sheet",
          icon: Sheet,
        },
        {
          id: "mongo-db",
          label: "Data Store (MongoDB)",
          path: "/settings/integrations/mongo-db",
          icon: DbIcon,
        },
        {
          id: "my-sql",
          label: "Data Store (MySQL)",
          path: "/settings/integrations/my-sql",
          icon: DbIcon,
        },
        {
          id: "postgresql",
          label: "Data Store (PostgreSQL)",
          path: "/settings/integrations/postgresql",
          icon: DbIcon,
        },
        {
          id: "cloudinary",
          label: "Cloudinary",
          path: "/settings/integrations/cloudinary",
          icon: CloudinaryIcon,
        },
        {
          id: "s3-storage",
          label: "S3 Storage",
          path: "/settings/integrations/s3-storage",
          icon: SBucketIcon,
        },
      ],
    },
    {
      id: "email",
      label: "Email Marketing",
      items: [
        {
          id: "smtp-profile",
          label: "SMTP Profile",
          path: "/settings/integrations/smtp-profile",
          icon: SmtpIcon,
        },
        {
          id: "gmail",
          label: "Gmail",
          path: "/settings/integrations/gmail",
          icon: GmailIcon,
        },
        {
          id: "sendgrid",
          label: "SendGrid",
          path: "/settings/integrations/sendgrid",
          icon: SendGridIcon,
        },
      ],
    },
    {
      id: "live-chat",
      label: "Live Chat Support",
      items: [
        {
          id: "intercom",
          label: "Intercom",
          path: "/settings/integrations/intercom",
          icon: IntercomIcon,
        },
        {
          id: "freshchats",
          label: "Freshchats",
          path: "/settings/integrations/freshchats",
          icon: FreshworksIcon,
        },
      ],
    },
    {
      id: "integration-platform",
      label: "Integration Platform",
      items: [
        {
          id: "zapier",
          label: "Zapier",
          path: "/settings/integrations/zapier",
          icon: ZapierIcon,
        },
      ],
    },
    {
      id: "map",
      label: "Map",
      items: [
        {
          id: "google-map",
          label: "Google Map",
          path: "/settings/integrations/google-map",
          icon: GoogleMapIcon,
        },
      ],
    },
    {
      id: "crm",
      label: "CRM",
      items: [
        {
          id: "zoho",
          label: "Zoho",
          path: "/settings/integrations/zoho",
          icon: ZohoIcon,
        },
        {
          id: "hubspot",
          label: "Hubspot",
          path: "/settings/integrations/hubspot",
          icon: HubspotIcon,
        },
        {
          id: "freshworks",
          label: "Freshworks",
          path: "/settings/integrations/freshworks",
          icon: FreshworksIcon,
        },
      ],
    },
    {
      id: "others",
      label: "Others",
      items: [
        {
          id: "facebook-ads",
          label: "Facebook Ads",
          path: "/settings/integrations/facebook-ads",
          icon: Facebook,
        },
        {
          id: "google-tag",
          label: "Google Tag Manager",
          path: "/settings/integrations/google-tag",
          icon: GoogleTagIcon,
        },
        {
          id: "slack-webhook",
          label: "Slack Webhook",
          path: "/settings/integrations/slack-webhook",
          icon: SlackIcon,
        },
      ],
    },
  ];

  const [activeDropdown, setActiveDropdown] = useState(null);

  const toggleDropdown = (id) =>
    setActiveDropdown((prev) => (prev === id ? null : id));

  return (
    <div className="overflow-x-auto xs:h-11 sm:h-full  xxl:min-w-[290px]   xl:min-w-[250px] lg:min-w-[300px] md:min-w-[230px] xs:rounded-md xs:m-2 xs:mb-0 xs:mt-0 sm:m-0 xs:p-[2px] xs:bg-grey-300 sm:bg-grey-100 sm:pt-10 sm:px-4 pb-10 flex md:flex-col xs:flex-row  gap-2 border-grey-300 border-r">
      <div className="sidebar-menu">
        {/* Map integrations */}
        {integrations.map((integration) => {
          const isActive = activeDropdown === integration.id;

          return (
            <div key={integration.id} className="relative w-full">
              <div
                onClick={() => toggleDropdown(integration.id)}
                className={`flex items-center gap-3 p-3 rounded-md cursor-pointer `}
              >
                {t(integration.label)}
                {isActive ? (
                  <ChevronUpIcon className="w-5 h-5 ml-auto" />
                ) : (
                  <ChevronDownIcon className="w-5 h-5 ml-auto" />
                )}
              </div>

              {isActive && (
                <div className="flex flex-col pl-8">
                  {integration.items.map((item) => {
                    const isItemActive = location.pathname === item.path;
                    return (
                      <Link
                        key={item.id}
                        to={item.path}
                        className={`py-2 pl-[2px] flex items-center text-gray-600 gap-2 rounded-md ${
                          isItemActive ? "bg-grey-300 pl-1" : ""
                        }`}
                      >
                        <item.icon className="w-7 h-7" />
                        <span>{t(item.label)}</span>
                      </Link>
                    );
                  })}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}
