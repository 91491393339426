import { Button, Input, Spinner } from "components";
import React from "react";
import { useForm, Controller } from "react-hook-form";
import {
  alphaNumericRegx,
  getRequiredText,
  handleSpeakText,
  textToSpeechRegx,
} from "shared/resources";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";

const validationSchema = yup.object().shape({
  name: yup.string().required(getRequiredText("Name")),
  speak_text: yup.string().required(getRequiredText("This")),
});

const TextToSpeech = ({
  onSuccess = () => null,
  mediaUploadLoad = false,
  type = "",
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: "",
      speak_text: "",
    },
    mode: "onChange",
  });
  const { t } = useTranslation();
  const handleSave = async (values) => {
    onSuccess({
      name: values?.name,
      filename: values?.speak_text,
      type: "S",
      action_type: type,
    });
  };

  return (
    <div
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          handleSubmit(handleSave)();
          e.preventDefault();
          e.stopPropagation();
        }
      }}
    >
      <div className="flex flex-col gap-1 mt-5">
        <Controller
          name="name"
          control={control}
          render={({ field }) => {
            const { onChange } = field;
            return (
              <Input
                {...field}
                onChange={(e) => {
                  if (alphaNumericRegx.test(e.target.value)) {
                    onChange(e.target.value);
                  } else {
                    return;
                  }
                }}
                onWhitebg={true}
                label={t("overview.name")}
                error={errors?.name?.message}
                maxLength={50}
              />
            );
          }}
        />

        <div className="text-grey-800 xxl:text-base xl:text-sm	lg:text-sm md:text-xs font-medium">
          {"overview.sthThatGreets"}
        </div>
        <Controller
          name="speak_text"
          control={control}
          render={({ field }) => {
            const { onChange } = field;
            return (
              <textarea
                {...field}
                onChange={(e) => {
                  if (textToSpeechRegx.test(e.target.value)) {
                    onChange(e.target.value);
                  } else {
                    return;
                  }
                }}
                cols="30"
                rows="5"
                className="w-full border  rounded-md  focus:outline-none focus:ring bg-grey-100 focus:bg-white font-medium text-base text-grey-900 resize-none border-grey-400 focus:border-green focus:ring-green-200"
              ></textarea>
            );
          }}
        />
      </div>
      <div className="flex justify-between gap-4 mt-8">
        <Button
          type="button"
          disabled={!watch("speak_text")}
          onClick={() => handleSpeakText(watch("speak_text"))}
        >
          {"overview.speakIt"}
        </Button>
        <Button
          type="button"
          onClick={handleSubmit(handleSave)}
          disabled={mediaUploadLoad}
        >
          {mediaUploadLoad ? <Spinner /> : t("overview.save")}
        </Button>
      </div>
    </div>
  );
};

export default TextToSpeech;
