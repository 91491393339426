import { Button, Spinner } from "components";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import CustomSelect from "components/CustomSelect";
import SwitchInput from "components/SwitchInput";
import { useDidList, useSetForwarding } from "hooks";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { IPRegex, formatPhoneNumber, getRequiredText } from "shared/resources";
import ForwardToInput from "pages/PhoneNumbers/SetForwardingModal/ForwardToInput";
import { useTranslation } from "react-i18next";

const initialValues = {
  uuid: "",
  forward_call_actions: {
    connect_as_web_call: true,
    during_business_hour: {
      type: "EXTENSION",
      value: "",
      timeout: 30,
    },
    failover: { type: "VOICEMAIL", value: "VOICEMAIL", timeout: 3 },
  },
};

const ForwardToItem = ({ item }) => {
  return (
    <div
      key={item.value}
      value={item?.value}
      className="w-full h-full flex  justify-start gap-2 items-center py-2 px-3"
    >
      {item?.label}
    </div>
  );
};

const PhoneNumberItem = ({ item }) => {
  return (
    <div
      key={item.uuid}
      value={item?.uuid}
      className="w-full h-full flex  justify-start gap-2 items-center py-2 px-3"
    >
      {formatPhoneNumber(item?.did_number)}
    </div>
  );
};

const TimeoutItem = ({ item }) => {
  return (
    <div
      key={item.value}
      value={item?.value}
      className="w-full h-full flex  justify-start gap-2 items-center py-2 px-3"
    >
      {item?.label}
    </div>
  );
};

const validationSchema = yup.object({
  uuid: yup.string().required(getRequiredText("Phone number")),
  forward_call_actions: yup.object({
    during_business_hour: yup.object({
      type: yup.string().required(getRequiredText("This field")),
      value: yup
        .string()
        .when("type", {
          is: (val) => !["HANGUP", "VOICEMAIL"].includes(val),
          then: yup.string().required(getRequiredText("This field")),
        })
        .when("type", {
          is: (val) => ["IP"].includes(val),
          then: yup
            .string()
            .required(getRequiredText("This field"))
            .matches(IPRegex, "Invalid IP Address"),
        }),
    }),
  }),
});

const Forwarding = () => {
  const {
    mutate: didListMutate,
    data: didListData,
    isPending: didListLoad,
  } = useDidList();
  const { t } = useTranslation();

  const handleSuccess = () => {
    console.log("success");
  };
  const { mutate: setForwardingMutate, isPending: setForwardingLoad } =
    useSetForwarding({ handleSuccess });

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    clearErrors,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: "onChange",
  });

  useEffect(() => {
    didListMutate();
  }, []);

  useEffect(() => {
    if (watch("uuid") && didListData?.data?.data) {
      const did_uuid = watch("uuid");
      const didListing = didListData?.data?.data || [];
      const refData =
        didListing?.filter((did) => did?.uuid === did_uuid)?.[0] ?? {};
      if (refData?.forward_call_actions) {
        setValue(
          "forward_call_actions.connect_as_web_call",
          refData?.forward_call_actions?.connect_as_web_call === "Y"
        );

        setValue(
          "forward_call_actions.during_business_hour.type",
          refData?.forward_call_actions?.during_business_hour?.type
        );
        setValue(
          "forward_call_actions.during_business_hour.value",
          refData?.forward_call_actions?.during_business_hour?.value
        );
        setValue(
          "forward_call_actions.during_business_hour.timeout",
          refData?.forward_call_actions?.during_business_hour?.timeout
        );
      } else {
        setValue("forward_call_actions", initialValues.forward_call_actions);
      }
    }
  }, [watch("uuid"), didListData?.data?.data]);

  const onSubmit = (values) => {
    const payload = {
      uuid: values?.uuid,
      forward_call_actions: {
        connect_as_web_call: values?.forward_call_actions?.connect_as_web_call
          ? "Y"
          : "N",
        during_business_hour: {
          ...values?.forward_call_actions?.during_business_hour,
          failover: ["TEAM", "EXTENSION"].includes(
            values?.forward_call_actions?.during_business_hour?.type
          )
            ? values?.forward_call_actions?.failover
            : undefined,
        },
      },
    };
    setForwardingMutate(payload);
  };

  const forwardTypeValue = watch(
    "forward_call_actions.during_business_hour.type"
  );

  const selectedUuid = watch("uuid");

  return (
    <div className="h-full">
      <div className="mb-6 text-sm text-grey-700">
        {t("overview.manageForwarding")}
      </div>
      <form
        className=" h-[calc(100%_-_44px)] overflow-auto pb-6 px-6 bg-white border border-grey-400 rounded-[10px]"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="max-w-[1002px] m-auto xs:mt-3 sm:mt-[10px] lg:mt-[40px] xxl:mt-[60px]">
          <div className="w-full">
            <Controller
              name={"uuid"}
              control={control}
              render={({ field }) => (
                <CustomSelect
                  {...field}
                  height="xxl:h-14 xl:h-12"
                  placeholder={t("overview.selectOption")}
                  onWhitebg={true}
                  label={t("overview.phoneNumber")}
                  error={errors?.uuid?.message}
                  ItemComponent={PhoneNumberItem}
                  labelKey={"did_number"}
                  valueKey={"uuid"}
                  isMulti={false}
                  optionsList={didListData?.data?.data || []}
                  shouldFormat={true}
                  isLoading={didListLoad}
                />
              )}
            />
          </div>
          <div className=" mt-5">
            <ForwardToInput
              clearErrors={clearErrors}
              forward_type_key="forward_call_actions.during_business_hour.type"
              forward_value_key="forward_call_actions.during_business_hour.value"
              forward_type_error={
                errors?.forward_call_actions?.during_business_hour?.type
                  ?.message
              }
              forward_value_error={
                errors?.forward_call_actions?.during_business_hour?.value
                  ?.message
              }
              control={control}
              watch={watch}
              setValue={setValue}
              selectedItem={selectedUuid}
            />
          </div>
          <div className=" mt-5">
            {["EXTENSION", "TEAM"].includes(forwardTypeValue) && (
              <div className="xs:flex-col sm:flex-row flex items-start justify-between w-full gap-6">
                <div className="xs:w-full sm:w-1/2">
                  <Controller
                    name={"forward_call_actions.during_business_hour.timeout"}
                    control={control}
                    render={({ field }) => (
                      <CustomSelect
                        {...field}
                        placeholder={t("overview.selectOption")}
                        onWhitebg={true}
                        label={t("overview.forwardFor")}
                        error={errors?.timeout?.message}
                        ItemComponent={TimeoutItem}
                        valueKey={"value"}
                        labelKey={"label"}
                        isMulti={false}
                        optionsList={Array.from(
                          { length: 30 },
                          (_, i) => i + 1
                        ).map((val) => ({
                          id: val,
                          label: `${val} seconds`,
                          value: val,
                        }))}
                      />
                    )}
                  />
                </div>
                <div className="xs:w-full sm:w-1/2">
                  <Controller
                    name={"forward_call_actions.failover.type"}
                    control={control}
                    render={({ field }) => (
                      <CustomSelect
                        {...field}
                        placeholder={t("overview.searchFailover")}
                        onWhitebg={true}
                        label={t("overview.forwardOption")}
                        error={errors?.timeout?.message}
                        ItemComponent={ForwardToItem}
                        valueKey={"value"}
                        labelKey={"label"}
                        isMulti={false}
                        optionsList={[
                          {
                            id: 1,
                            label: t("overview.voicemail"),
                            value: "VOICEMAIL",
                          },
                        ]}
                      />
                    )}
                  />
                </div>
              </div>
            )}
            {["EXTENSION"].includes(forwardTypeValue) && (
              <div className="flex items-start justify-between w-full gap-8 mt-6">
                <Controller
                  name={"forward_call_actions.connect_as_web_call"}
                  control={control}
                  render={({ field }) => (
                    <SwitchInput
                      {...field}
                      name={"connect_as_web_call"}
                      label={t("overview.connectAsWeb")}
                    />
                  )}
                />
              </div>
            )}
          </div>
          {["EXTENSION"].includes(forwardTypeValue) && (
            <div className=" mt-10">
              <div className="text-grey-700 w-full lg:w-[850px] font-semibold text-sm  text-clip">
                {t("overview.note")}
              </div>
            </div>
          )}
          <div className="mt-[73px]  flex justify-end gap-4">
            <Button
              extraClasses="justify-start cursor-pointer"
              type="submit"
              width="w-[191px]"
              disabled={setForwardingLoad}
            >
              {setForwardingLoad ? <Spinner /> : t("overview.confirm")}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Forwarding;
