import { useContext, createContext, useMemo, useState, useEffect } from "react";
import { dummyChatData } from "./config";

const ChatContext = createContext({
  getAllChats: () => {
    return [];
  },
});

export const Chat = ({ children }) => {
  const [allChats, setAllChats] = useState([]);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    setAllChats(dummyChatData);
  }, []);

  function getAllChats() {
    return allChats;
  }

  const values = useMemo(
    () => ({
      getAllChats,
      messages,
      setMessages,
    }),
    [allChats, messages]
  );
  return <ChatContext.Provider value={values}>{children}</ChatContext.Provider>;
};

export const useChat = () => useContext(ChatContext);
