import React, { useState } from "react";
import SwitchInput from "components/SwitchInput";
import { Button } from "components";
import { toastEmitter } from "components/Toast";

const AIAgents = () => {
  // const [enabled, setEnabled] = useState(true);
  const [ringDuration, setRingDuration] = useState(15);
  // const [interruptionSensitivity, setInterruptionSensitivity] = useState(10);
  // const [voiceSpeed, setVoiceSpeed] = useState(10);
  const [language, setLanguage] = useState("English (US)");
  // const [enableBackchannel, setEnableBackchannel] = useState(false);

  const handleSave = () => {
    toastEmitter("success", "Settings saved successfully!");
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen pl-[20%] w-[70%] flex flex-col ">
      <h1 className="text-2xl font-bold mb-4">AI Agent (Suki)</h1>
      <p className="text-gray-600 mb-4 font-thin text-[14px]">
        Setup and manage your AI Agent – that can automatically help your team
        whenever and however you want.
      </p>

      {/* Enable AI Agent */}
      <div className="p-4 px-0 mb-6">
        <div className="flex justify-between items-center">
          <div>
            <label className="text-lg font-medium text-[16px]">
              Enable AI Agent
            </label>
            <p className="text-gray-00 text-[14px]">
              Allow Suki to be in active use on this account.
            </p>
          </div>
          <SwitchInput name={"ai-agent"} />
        </div>
      </div>

      <hr className="border-gray-300" />

      {/* Name and Image */}
      <div className="flex justify-between p-4 px-0 mb-6">
        <div>
          <label className="text-lg font-medium block mb-2 text-[16px]">
            Name and Image
          </label>
          <p className="text-gray-600 font-thin text-[14px]">
            Name your AI agents and choose a display image.
          </p>
        </div>
        <div className="flex items-center gap-[10px]">
          <span className="text-gray-700 p-4 border rounded text-left w-[150px]">
            Suki Agent
          </span>
          <img
            src="https://via.placeholder.com/40"
            alt="Agent Avatar"
            className="w-10 h-10 rounded-full mr-4"
          />
        </div>
      </div>

      <hr className="border-gray-300" />

      {/* Ring Duration */}
      <div className=" p-4 px-0 mb-6 flex justify-between item-center">
        <div>
          <label className="text-lg font-medium block mb-2 text-[16px]">
            Ring Duration
          </label>
          <p className="text-gray-600 font-thin text-[14px]">
            The default number of seconds before calls are directed to AI Agent.
          </p>
        </div>
        <input
          type="number"
          value={ringDuration}
          onChange={(e) => setRingDuration(e.target.value)}
          className="w-[100px] h-[60px] border border-gray-300 rounded p-2"
          placeholder="Seconds"
        />
      </div>

      <hr className="border-gray-300" />

      {/* Transfer Prompt */}
      <div className=" p-4 px-0 mb-6 flex justify-between">
        <div>
          <label className="text-lg font-medium block mb-2 text-[16px]">
            Transfer Prompt
          </label>
          <p className="text-gray-600 font-thin text-[14px]">
            When customer requests human agent, select who to transfer to.
          </p>
        </div>

        <select className="w-[30%] border border-gray-300 rounded p-2">
          <option>Select</option>
          <option>Human Agent 1</option>
          <option>Human Agent 2</option>
        </select>
      </div>

      <hr className="border-gray-300" />

      {/* Interruption Sensitivity */}
      <div className=" p-4 px-0 mb-6 flex justify-between">
        <div>
          <label className="text-lg font-medium block mb-2 text-[16px]">
            Interruption Sensitivity
          </label>
          <p className="text-gray-600 font-thin text-[14px]">
            Control how sensitive AI can be interrupted by human.
          </p>
        </div>

        <select className="w-[30%] border border-gray-300 rounded p-2">
          <option>10</option>
          <option>10</option>
          <option>20</option>
        </select>
      </div>

      <hr className="border-gray-300" />

      {/* Voice Speed */}
      <div className=" p-4 px-0 mb-6 flex justify-between">
        <div>
          <label className="text-lg font-medium block mb-2 text-[16px]">
            Voice Speed
          </label>
          <p className="text-gray-600 font-thin text-[14px]">
            How fast do you want the AI to speak to a customer.
          </p>
        </div>
        <select className="w-[30%] border border-gray-300 rounded p-2">
          <option>10</option>
          <option>10</option>
          <option>20</option>
        </select>
      </div>

      <hr className="border-gray-300" />

      {/* Enable Backchannel */}
      <div className=" p-4 px-0 mb-6 flex justify-between">
        <div className="flex justify-between items-center">
          <div>
            <label className="text-lg font-medium text-[16px]">
              Enable Backchannel
            </label>
            <p className="text-gray-600 font-thin text-[14px]">
              Enable AI to use affirmations like “yeah” or “uh-huh” during
              conversations to indicate active listening and engagement.
            </p>
          </div>
          <SwitchInput name={"enable-backchannel"} />
        </div>
      </div>

      <hr className="border-gray-300" />

      {/* Language */}
      <div className=" p-4 px-0 mb-6 flex justify-between">
        <div>
          <label className="text-lg font-medium block mb-2 text-[16px]">
            Language
          </label>
          <p className="text-gray-600 font-thin text-[14px]">
            The language of the AI Agent.
          </p>
        </div>
        <select
          value={language}
          onChange={(e) => setLanguage(e.target.value)}
          className=" border border-gray-300 w-[30%] rounded p-2"
        >
          <option>English (US)</option>
          <option>English (UK)</option>
          <option>Spanish</option>
        </select>
      </div>

      <hr className="border-gray-300" />

      {/* End Call Setting */}
      <div className="p-4 px-0 mb-6 flex justify-between">
        <div>
          <label className="text-lg font-medium block mb-2 text-[16px]">
            End Call Setting
          </label>
          <p className="text-gray-600 font-thin text-[14px]">
            The number of seconds to end calls when customer has stayed silent{" "}
          </p>
        </div>
        <input
          type="number"
          value={10}
          className="border border-gray-300 rounded p-2 w-[20%]"
          placeholder="Seconds"
        />
      </div>
      <Button onClick={handleSave}>Save settings</Button>
    </div>
  );
};

export default AIAgents;
