import {
  FacebookMessenger,
  InstagramMessenger,
  LinkedIn,
  Omni,
  SmsIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappBusiness,
} from "assets/images";
import { Button } from "components";
import useFacebookChannel from "hooks/useFacebookChannel";
import { Link } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function OmniChannel() {
  const { launchWhatsAppSignup } = useFacebookChannel({
    appId: "9343719928976278",
    graphApiVersion: "v21.0",
    configId: "490887133306473",
  });
  const navigate = useNavigate();

  const [whatsappConfig, setWhatsappConfig] = useState(
    localStorage.getItem("whatsapp")
      ? JSON.parse(localStorage.getItem("whatsapp"))
      : null
  );

  useEffect(() => {
    console.log("whatsappConfig", whatsappConfig);
  }, [whatsappConfig]);

  return (
    <div>
      <div className="border border-grey-400 rounded-md shadow shadow-grey-500 w-full flex flex-col gap-3 justify-center p-4 h-44">
        <div className="flex gap-2 items-center">
          <Omni className="w-6 h-6" />
          <p className="text-xl">Omni Channel</p>
        </div>
        <p>
          A single Omni Channel bot with unified inbox for multiple channels.
        </p>
        {whatsappConfig && whatsappConfig.linked ? (
          <>
            {" "}
            <div className="w-[150px]">
              <Button
                onClick={() => {
                  navigate("/settings/all-bots");
                }}
              >
                Manage Bots
              </Button>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
      <table className="w-full mt-6 rounded-lg shadow shadow-grey-500">
        <thead>
          <tr className="text-left h-[60px] px-4  rounded-lg overflow-hidden">
            <th className="w-[300px] bg-grey-500 rounded-tl-lg px-4">
              Channel
            </th>
            <th className="w-[200px] bg-grey-500">Name</th>
            <th className="w-[70px] bg-grey-500 rounded-tr-lg"></th>
          </tr>
        </thead>
        <tbody>
          <tr className="border-b border-grey-400 hover:bg-grey-200 px-4">
            <td className="flex items-center gap-2 px-4 py-5">
              <span>
                <WhatsappBusiness className="w-7 h-7" />
              </span>
              <span>Whatsapp</span>
            </td>
            <td></td>
            <td className="flex items-end ml-auto justify-end mr-4">
              <button
                onClick={() => {
                  if (!whatsappConfig && !whatsappConfig?.linked) {
                    launchWhatsAppSignup();
                    localStorage.setItem(
                      "whatsapp",
                      JSON.stringify({ linked: true })
                    );
                    setWhatsappConfig({ linked: true });
                  } else if (whatsappConfig.botId) {
                    navigate("/settings/whatsapp");
                  } else {
                    navigate("/settings/whatsapp?step=2");
                  }
                }}
                className="border border-green hover:bg-green duration-300 hover:text-white text-green rounded-sm w-[100px] h-[40px] text-sm flex items-center gap-2 justify-center"
              >
                {whatsappConfig && whatsappConfig.linked ? (
                  whatsappConfig.botId ? (
                    <>
                      <span className="">Open Bots</span>
                    </>
                  ) : (
                    <>
                      <span className="">Create Bot</span>
                    </>
                  )
                ) : (
                  <>
                    {" "}
                    <Link />
                    <span className="">Link</span>
                  </>
                )}
              </button>
            </td>
          </tr>
          <tr className="border-b border-grey-400 hover:bg-grey-200 px-4">
            <td className="flex items-center gap-2 px-4 py-5">
              <span>
                <TelegramIcon className="w-7 h-7" />
              </span>
              <span>Telegram</span>
            </td>
            <td></td>
            <td className="flex items-end ml-auto justify-end mr-4">
              <button
                onClick={() => {}}
                className="border border-green hover:bg-green duration-300 hover:text-white text-green rounded-sm w-[100px] h-[40px] text-sm flex items-center gap-2 justify-center"
              >
                <Link />
                <span className="">Link</span>
              </button>
            </td>
          </tr>
          <tr className="border-b border-grey-400 hover:bg-grey-200 px-4">
            <td className="flex items-center gap-2 px-4 py-5">
              <span>
                <FacebookMessenger className="w-7 h-7" />
              </span>
              <span>Facebook</span>
            </td>
            <td></td>
            <td className="flex items-end ml-auto justify-end mr-4">
              <button
                onClick={launchWhatsAppSignup}
                className="border border-green hover:bg-green duration-300 hover:text-white text-green rounded-sm w-[100px] h-[40px] text-sm flex items-center gap-2 justify-center"
              >
                <Link />
                <span className="">Link</span>
              </button>
            </td>
          </tr>
          <tr className="border-b border-grey-400 hover:bg-grey-200 px-4">
            <td className="flex items-center gap-2 px-4 py-5">
              <span>
                <InstagramMessenger className="w-7 h-7" />
              </span>
              <span>Instagram messenger</span>
            </td>
            <td></td>
            <td className="flex items-end ml-auto justify-end mr-4">
              <button
                onClick={launchWhatsAppSignup}
                className="border border-green hover:bg-green duration-300 hover:text-white text-green rounded-sm w-[100px] h-[40px] text-sm flex items-center gap-2 justify-center"
              >
                <Link />
                <span className="">Link</span>
              </button>
            </td>
          </tr>{" "}
          <tr className="border-b border-grey-400 hover:bg-grey-200 px-4">
            <td className="flex items-center gap-2 px-4 py-5">
              <span>
                <TwitterIcon className="w-7 h-7" />
              </span>
              <span>Twitter</span>
            </td>
            <td></td>
            <td className="flex items-end ml-auto justify-end mr-4">
              <button className="border border-green hover:bg-green duration-300 hover:text-white text-green rounded-sm w-[100px] h-[40px] text-sm flex items-center gap-2 justify-center">
                <Link />
                <span className="">Link</span>
              </button>
            </td>
          </tr>
          <tr className="border-b border-grey-400 hover:bg-grey-200 px-4">
            <td className="flex items-center gap-2 px-4 py-5">
              <span>
                <LinkedIn className="w-7 h-7" />
              </span>
              <span>LinkedIn</span>
            </td>
            <td></td>
            <td className="flex items-end ml-auto justify-end mr-4">
              <button className="border border-green hover:bg-green duration-300 hover:text-white text-green rounded-sm w-[100px] h-[40px] text-sm flex items-center gap-2 justify-center">
                <Link />
                <span className="">Link</span>
              </button>
            </td>
          </tr>{" "}
          <tr className="border-b border-grey-400 hover:bg-grey-200 px-4">
            <td className="flex items-center gap-2 px-4 py-5">
              <span>
                <SmsIcon className="w-7 h-7" />
              </span>
              <span>SMS</span>
            </td>
            <td></td>
            <td className="flex items-end ml-auto justify-end mr-4">
              <button className="border border-green hover:bg-green duration-300 hover:text-white text-green rounded-sm w-[100px] h-[40px] text-sm flex items-center gap-2 justify-center">
                <Link />
                <span className="">Link</span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default OmniChannel;
