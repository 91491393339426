import { bulkCallSessions } from "api";
import {
  Button,
  Modal,
  PlayAudioModal,
  Spinner,
  TableManager,
} from "components";
import React, { useEffect, useMemo, useState } from "react";
import { ADMIN, OWNER, formatDateTime } from "shared/resources";
import { dropdownActions, initialModalState } from "./constants";
import { useAuth } from "hooks/useAuth";
import { Loader2 } from "lucide-react";
import { Download } from "assets/images";
import { toastEmitter } from "components/Toast";
import axios from "axios";
import { routes } from "api/routes";
import { getToken } from "shared/resources";
import useBulkDeleteSession from "hooks/useBulkDeleteSession";
import BulkDeleteSessionModal from "./BulkDeleteSessionModal";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const BulkDeleteSessionComponent = ({ data = [], callback = () => null }) => {
  const { mutate, isPending, status } = useBulkDeleteSession();
  const [showModal, setShowModal] = useState(initialModalState);

  useEffect(() => {
    if (status && status === "success") {
      callback();
    }
  }, [status]);

  const ModalLookup = {
    [dropdownActions.DELETE_BULK_SESSION]: (
      <BulkDeleteSessionModal
        handleClose={callback}
        data={data}
        deleteFn={() => mutate({ uuid: data.join(",") })}
        isLoading={isPending}
      />
    ),
  };

  const { isShow, modalType } = showModal;

  return (
    <div className="w-64">
      <Button
        height="h-10"
        type="button"
        background="danger"
        onClick={() =>
          setShowModal({
            isShow: true,
            modalType: dropdownActions.DELETE_BULK_SESSION,
            elementData: {},
          })
        }
      >
        {isPending ? <Spinner /> : `Delete ${data?.length} session(s)`}
      </Button>
      {isShow ? (
        <Modal
          handleClose={callback}
          headerComponent={null}
          footerComponent={null}
          shouldCloseOnClickOutside={false}
        >
          {ModalLookup[modalType]}
        </Modal>
      ) : null}
    </div>
  );
};

const filterInitialValues = {
  filter: [
    {
      name: "",
      a_filter_type: "",
      placeholder: "Enter name",
      type: "text",
      shouldFilterOptionDisabled: false,
      label: "Name",
      filterName: "name",
      position: 1,
    },

    {
      dob: "date",
      a_filter_type: "",
      placeholder: "Select date",
      type: "date-input",
      shouldFilterOptionDisabled: true,
      label: "Date",
      filterName: "date",
      position: 3,
    },
  ],
};

function BulkCallDetails() {
  const { user } = useAuth();
  const { t } = useTranslation();
  const [filters, setFilters] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [showPlayModal, setPlayModal] = useState(initialModalState);
  const [tab, setTab] = useState("ALL");
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const navigate = useNavigate();

  const checkboxSelectableFn = () => {
    return true;
  };

  const singleSessionDownload = async (element) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}${routes.CALL_SESSION_SINGLE_EXPORT.URL}`,
        { session_uuid: element.uuid },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const sanitizedSessionName =
        element.name.replace(/[^a-z0-9]/gi, "_").toLowerCase() +
        "_" +
        element.uuid;
      const sessionFileName = `${sanitizedSessionName}-session.csv`;
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = sessionFileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
        return (window.location.href = "/");
      } else {
        toastEmitter("error", error?.response?.data?.error?.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "check",
        header: (props) => {
          return (
            <input
              className="checked:bg-green checked:hover:bg-green checked:active:bg-green checked:focus:bg-green focus:outline-none focus:ring-1 focus:ring-green"
              type="checkbox"
              checked={props?.table?.getIsAllRowsSelected()}
              onChange={props?.table?.getToggleAllRowsSelectedHandler()}
            />
          );
        },
        cell: (props) => {
          return (
            <input
              key={props?.row?.id}
              className="checked:bg-green checked:hover:bg-green checked:active:bg-green checked:focus:bg-green focus:outline-none focus:ring-1 focus:ring-green"
              type="checkbox"
              checked={props?.row?.getIsSelected()}
              onChange={(e) => {
                props?.row?.getToggleSelectedHandler()(e);
                if (props?.row?.getIsSelected()) {
                  setSelectedData((prevSelectedData) =>
                    prevSelectedData.filter(
                      (id) => id !== props?.row?.original?.uuid
                    )
                  );
                } else {
                  setSelectedData((prevSelectedData) => [
                    ...prevSelectedData,
                    props?.row?.original?.uuid,
                  ]);
                }
              }}
            />
          );
        },
        meta: {
          colType: "action",
          width: "5%",
          hasAccess: [OWNER, ADMIN].includes(user?.role_id),
        },
      },
      {
        accessorKey: "created_at",
        header: () => t("overview.date"),
        cell: (props) => {
          const [date, time] = formatDateTime(props?.getValue());
          return (
            <span>
              {date}&nbsp;&#183;&nbsp;{time}
            </span>
          );
        },
      },
      {
        accessorKey: "first_name",
        header: () => t("overview.member"),
        cell: (props) => {
          const element = props?.row?.original || {};
          return (
            <span>
              {element.first_name} {element.last_name}
            </span>
          );
        },
      },
      {
        accessorKey: "name",
        header: () => t("overview.batchName"),
        cell: (props) => {
          const element = props?.row?.original || {};
          return <span>{element.name}</span>;
        },
      },
      {
        accessorKey: "successful_count",
        header: () => t("overview.successfulCalls"),
        cell: (props) => {
          const element = props?.row?.original || {};
          return (
            <span>
              {element.successful_count}/
              {element.successful_count + element.failed_count}{" "}
              {t("overview.successful")}
            </span>
          );
        },
      },
      {
        accessorKey: "total_contacts",
        header: () => t("overview.totalContacts"),
        cell: (props) => {
          const element = props?.row?.original || {};
          return (
            <span>
              {element.total_contacts} {t("overview.contacts")}
            </span>
          );
        },
      },
      {
        accessorKey: "action",
        header: () => "",
        cell: (props) => {
          const element = props?.row?.original || {};

          return (
            <div className="flex gap-3">
              <span
                className="text-green cursor-pointer"
                onClick={() => {
                  navigate(`/phone-numbers/bulk-call-details/${element.uuid}`);
                }}
              >
                {t("overview.viewSessionLogs")}
              </span>
            </div>
          );
        },
        meta: {
          colType: "action",
          width: "5%",
        },
      },
      {
        accessorKey: "action",
        header: () => "",
        cell: (props) => {
          const element = props?.row?.original || {};
          return (
            <span
              className="flex justify-end cursor-pointer items-end"
              onClick={(e) => {
                e.preventDefault();
                singleSessionDownload(element);
              }}
            >
              {loading ? <Loader2 className="animate-spin" /> : <Download />}
            </span>
          );
        },
      },
    ],
    []
  );

  const bulkActions = [
    {
      id: 1,
      component: BulkDeleteSessionComponent,
    },
  ];
  const { isShow, type } = showPlayModal;
  function handleClosePlayModal() {
    setPlayModal(initialModalState);
  }

  return (
    <>
      <TableManager
        {...{
          fetcherKey: "bulkCallSessions",
          fetcherFn: bulkCallSessions,
          columns,
          showPagination: true,
          bulkActionsAccKey: "uuid",
          isSearchable: true,
          searchLabel: t("overview.manageBulk"),
          searchPlaceholder: t("overview.searchWithMemberName"),
          filters,
          setFilters,
          openFilter,
          setOpenFilter,
          filterInitialValues,
          bulkActions,
          checkboxSelectableFn,
          downloadSessions: true,
          calendarBtn: true,
          setTab,
          setFrom,
          setTo,
          fromDate: from,
          toDate: to,
          selectedData,
          tabList: [
            {
              id: 1,
              label: t("overview.all"),
              onclick: () => setTab("ALL"),
              value: "ALL",
            },
            {
              id: 2,
              label: t("overview.daily"),
              onclick: () => setTab("DAILY"),
              value: "DAILY",
            },
            {
              id: 3,
              label: t("overview.weekly"),
              onclick: () => setTab("WEEKLY"),
              value: "WEEKLY",
            },
            {
              id: 4,
              label: t("overview.monthly"),
              onclick: () => setTab("MONTHLY"),
              value: "MONTHLY",
            },
          ],
          currentTab: tab,
          extraParams: {
            type: tab,
            from: from,
            to: to,
          },
        }}
      />

      {isShow && type === "center" && (
        <Modal
          handleClose={handleClosePlayModal}
          headerComponent={null}
          footerComponent={null}
          shouldCloseOnClickOutside={false}
        >
          <PlayAudioModal
            type="recording"
            fileNameAccessorKey={"recording_file"}
            showModal={showPlayModal}
            handleClose={handleClosePlayModal}
            customLabel="Recording"
          />
        </Modal>
      )}
    </>
  );
}

export default BulkCallDetails;
