import { Arrow, X } from "assets/images";
import { Button, Modal, Spinner } from "components";
import React, { useState, useMemo } from "react";
import SavedCardListing from "./SavedCardListing";
import useChangePlan from "hooks/useChangePlan";
import { dropdownActions, initialModalState } from "pages/Profile/constants";
import PaymentConfirmationModal from "components/PaymentConfirmationModal";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

const BuyPlanModal = ({
  handleClose = () => null,
  showModal = {},
  isMonthly = true,
}) => {
  const { t } = useTranslation();
  const activeOptionOptions = [
    {
      id: 1,
      label: t("payment.newMethod"),
      value: "NEW_CARD",
    },
    {
      id: 2,
      label: t("payment.savedCards"),
      value: "SAVED_CARD",
    },
    {
      id: 3,
      label: t("payment.fonuWallet"),
      value: "WALLET",
    },
  ];
  const [activeOption, setActiveOption] = useState("");
  const [isSavedCard, setIsSavedCard] = useState("N");
  const [showModalPayment, setShowModalPayment] = useState(initialModalState);

  const handleBack = () => {
    setActiveOption("");
  };
  const { isPending: changePlanLoad, mutate: changePlanMutate } = useChangePlan(
    {
      handleSuccess: () => {
        window.location.reload();
      },
    }
  );
  const { setValue, handleSubmit } = useForm({
    defaultValues: {},
    mode: "onChange",
  });

  const ModalLookup = useMemo(() => {
    return {
      [dropdownActions.PURCHASE_CONFIRMATION]: (
        <PaymentConfirmationModal
          handleClose={handleModalClose}
          continueCallBack={handleSubmit(onSubmit)}
          setValue={setValue}
        />
      ),
    };
  }, [showModalPayment]);

  function handleModalClose() {
    setShowModalPayment(initialModalState);
  }

  function onSubmit(values) {
    changePlanMutate({
      plan_uuid: showModal?.elementData,
      payment_type: activeOption,
      is_saved_card: activeOption === "NEW_CARD" ? isSavedCard : "N",
      plan_action: showModal?.plan_action ?? undefined,
      plan_type: isMonthly ? "MONTHLY" : "YEARLY",
      source: values?.source,
    });
  }
  const { isShow, modalType } = showModalPayment;

  return (
    <div className="min-w-[500px]">
      <div className="flex justify-between items-start py-4 px-6">
        <div className="flex flex-col gap-1">
          <div className="text-xl font-semibold text-black">
            {t("overview.buy")} {t("overview.plan")}
          </div>
          <div className="text-sm  text-grey-700">
            {t("overview.choosePayment")}
          </div>
        </div>
        {activeOption === "SAVED_CARD" ? (
          <button type="button" onClick={() => handleBack()}>
            <Arrow className="rotate-180 text-black" />
          </button>
        ) : (
          <button type="button" onClick={() => handleClose()}>
            <X className="text-grey-600 w-6 h-6" />
          </button>
        )}
      </div>
      {activeOption === "SAVED_CARD" ? (
        <div className="px-6">
          <SavedCardListing planId={showModal?.elementData} />
        </div>
      ) : (
        <>
          <div className="p-6 flex flex-col gap-2 mt-5">
            {activeOptionOptions.map(({ id, label, value }) => {
              return (
                <label
                  htmlFor={value}
                  key={id}
                  className="h-10 flex items-center justify-between px-4 cursor-pointer font-semibold border-b-2 border-grey-400 "
                  onClick={() => setActiveOption(value)}
                >
                  {label}
                  <input
                    className="checked:bg-green checked:hover:bg-green checked:active:bg-green checked:focus:bg-green focus:outline-none focus:ring-1 focus:ring-green"
                    id={value}
                    type="radio"
                    value={value}
                    onChange={(e) => {
                      setActiveOption(e.target.value);
                    }}
                    checked={value === activeOption}
                  />
                </label>
              );
            })}
          </div>
          {activeOption === "NEW_CARD" ? (
            <div className="w-full pl-10 gap-2 flex items-center">
              <input
                className="checked:bg-green checked:hover:bg-green checked:active:bg-green checked:focus:bg-green focus:outline-none focus:ring-1 focus:ring-green"
                type="checkbox"
                id="save_card"
                checked={isSavedCard === "Y"}
                onChange={(e) => {
                  setIsSavedCard(e.target.checked ? "Y" : "N");
                }}
              />
              <label className="cursor-pointer" htmlFor="save_card">
                {t("overview.futureQuestion")}
              </label>
            </div>
          ) : null}
          <div className="py-4 px-6">
            <Button
              extraClasses="mt-5"
              type="button"
              onClick={() => {
                if (activeOption === "NEW_CARD") {
                  setShowModalPayment({
                    modalType: dropdownActions.PURCHASE_CONFIRMATION,
                    isShow: true,
                    elementData: {},
                  });
                } else {
                  handleSubmit(onSubmit)();
                }
              }}
              disabled={changePlanLoad || !activeOption}
            >
              {changePlanLoad ? <Spinner /> : t("payment.continue")}
            </Button>
          </div>
        </>
      )}
      {isShow ? (
        <Modal
          handleClose={handleModalClose}
          headerComponent={null}
          footerComponent={null}
          shouldCloseOnClickOutside={false}
        >
          {ModalLookup[modalType]}
        </Modal>
      ) : null}
    </div>
  );
};

export default BuyPlanModal;
