import { Avatar, Skeletons } from "components";
import { useDidListWithCache } from "hooks";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { formatPhoneNumber } from "shared/resources";
import { Unassigned, Assigned, Folder, Plus } from "assets/images";

const loader = (
  <>
    {[...Array(3)].map((_, index) => (
      <div key={index} className="flex gap-3 items-center">
        <div className="w-9 h-9 rounded-full">
          <Skeletons type="circle" />
        </div>
        <div className="flex flex-col gap-1">
          <div className="flex items-center text-sm font-medium text-grey-900">
            <div className="w-24">
              <Skeletons />
            </div>
          </div>
        </div>
      </div>
    ))}
  </>
);

const CustomLink = ({ nav, index }) => {
  const navigate = useNavigate();
  const { chatId } = useParams();

  useEffect(() => {
    if (!chatId && index === 0) {
      navigate(`/live-chat/${nav.id}`);
    }
  }, [chatId, index, nav, navigate]);

  return (
    <Link
      key={nav.id}
      to={`/live-chat/${nav.id}`}
      className={`xxl:text-base xl:text-base lg:text-sm md:text-sm flex items-center gap-3 p-2 rounded-md hover:bg-grey-300 cursor-pointer ${
        nav.isActive ? "bg-grey-300" : ""
      }`}
    >
      <Avatar mediaUrl={nav.icon} name={nav.name} type="icon" />
      <div className="flex flex-col">
        <span className="font-semibold text-sm">{nav.name}</span>
        <span className="text-xs font-medium text-grey-600">
          {formatPhoneNumber(nav.number)}
        </span>
      </div>
    </Link>
  );
};

const Sidebar = () => {
  const location = useLocation();
  const { isLoading } = useDidListWithCache();
  const [mutatedRoutes, setMutatedRoutes] = useState([]);

  const manualItems = [
    { id: "0", name: "Support", number: "08123456789", icon: "icon1.png" },
    { id: "1", name: "Sales", number: "09098765432", icon: "icon2.png" },
    { id: "2", name: "Marketing", number: "07011223344", icon: "icon3.png" },
    { id: "3", name: "HR", number: "08033445566", icon: "icon4.png" },
    { id: "4", name: "Tech", number: "09122334455", icon: "icon5.png" },
  ];

  useEffect(() => {
    const routes = manualItems.map((item) => ({
      ...item,
      isActive: location.pathname === `/live-chat/${item.id}`,
    }));
    setMutatedRoutes(routes);
  }, [location.pathname]);

  return (
    <div className="xxl:min-w-[290px] xl:min-w-[250px] lg:min-w-[256px] md:min-w-[230px] pt-7 flex flex-col border-grey-300 border-r h-full">
      {/* Header */}
      <div className="font-medium text-base text-grey-600 pb-[22px] px-4">
        Inboxes
      </div>

      {/* Scrollable Content */}
      <div className="flex-1 overflow-y-auto px-4">
        {/* Sidebar Links */}
        <div className="flex flex-col gap-2.5 mb-2">
          {isLoading
            ? loader
            : mutatedRoutes.map((nav, index) => (
                <CustomLink key={nav.id} nav={nav} index={index} />
              ))}
        </div>

        {/* Categories */}
        <div className="pt-[40px]">
          {/* Unassigned */}
          <div className="flex items-center justify-between mb-2 cursor-pointer hover:bg-grey-200 rounded-md p-2">
            <div className="flex items-center gap-2">
              <Unassigned />
              <span className="text-sm font-medium text-grey-800">
                Unassigned
              </span>
            </div>
            <div className="flex justify-center items-center min-w-[18px] min-h-[18px] rounded-full bg-green text-white text-[11px] font-medium px-[6px]  tracking-[0.7px]">
              5
            </div>
          </div>

          {/* Assigned to You */}
          <div className="flex items-center justify-between mb-4 cursor-pointer hover:bg-grey-200 rounded-md p-2">
            <div className="flex items-center gap-2">
              <Assigned />
              <span className="text-sm font-medium text-grey-800">
                Assigned to you
              </span>
            </div>
            <div className="flex justify-center items-center min-w-[18px] min-h-[18px] rounded-full bg-green text-white text-[11px] font-medium px-[6px]  tracking-[0.7px]">
              3
            </div>
          </div>

          {/* Folders */}
          <div className="flex items-center justify-between cursor-pointer hover:bg-grey-200 rounded-md m-2 mt-[50px]">
            <div className="font-medium text-sm text-grey-500 mb-2">
              Folders
            </div>
            <Plus />
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex items-center justify-between p-2 cursor-pointer hover:bg-grey-200 rounded-md">
              <div className="flex items-center gap-2">
                <Folder />
                <span className="text-sm text-grey-800">General</span>
              </div>
              <div className="flex justify-center items-center min-w-[18px] min-h-[18px] rounded-full bg-green text-white text-[11px] font-medium px-[6px]  tracking-[0.7px]">
                99+
              </div>
            </div>

            <div className="flex items-center justify-between p-2 cursor-pointer hover:bg-grey-200 rounded-md">
              <div className="flex items-center gap-2">
                <Folder />
                <span className="text-sm text-grey-800">User Reviews</span>
              </div>
              <div className="flex justify-center items-center min-w-[18px] min-h-[18px] rounded-full bg-green text-white text-[11px] font-medium px-[6px]  tracking-[0.7px]">
                5
              </div>
            </div>

            <div className="flex items-center justify-between p-2 cursor-pointer hover:bg-grey-200 rounded-md">
              <div className="flex items-center gap-2">
                <Folder />
                <span className="text-sm text-grey-800">Test Feedback</span>
              </div>
              <div className="flex justify-center items-center min-w-[18px] min-h-[18px] rounded-full bg-green text-white text-[11px] font-medium px-[6px]  tracking-[0.7px]">
                3
              </div>
            </div>

            <div className="flex items-center justify-between p-2 cursor-pointer hover:bg-grey-200 rounded-md">
              <div className="flex items-center gap-2">
                <Folder />
                <span className="text-sm text-grey-800">Support Cases</span>
              </div>
              <div className="flex justify-center items-center min-w-[18px] min-h-[18px] rounded-full bg-green text-white text-[11px] font-medium px-[6px]  tracking-[0.7px]">
                3
              </div>
            </div>

            {/* Disabled Folders */}
            <div className="flex items-center justify-between p-2 opacity-50">
              <div className="flex items-center gap-2">
                <Folder />
                <span className="text-sm text-grey-800">Card Dispute</span>
              </div>
            </div>

            <div className="flex items-center justify-between p-2 opacity-50">
              <div className="flex items-center gap-2">
                <Folder />
                <span className="text-sm text-grey-800">Scout</span>
              </div>
            </div>

            <div className="flex items-center justify-between p-2 opacity-50">
              <div className="flex items-center gap-2">
                <Folder />
                <span className="text-sm text-grey-800">Other cases</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
