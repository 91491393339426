import { useInfiniteQuery } from "@tanstack/react-query";
import { NoRecord } from "assets/images";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const ListManager = (
  {
    actionFn = () => null,
    setHasdata = () => null,
    queryKey = "",
    listItem: ListItem = () => null,
    defaultLoadingRows = 4,
    loadingComponent: LoadingComponent = () => null,
    onChangeItemHandler = () => null,
    itemValue = null,
    withPageParam = true,
    extraParams = {},
  },
  ref
) => {
  const observer = React.useRef(null);
  const { t } = useTranslation();
  const {
    status,
    data,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteQuery({
    queryKey: [`${queryKey}`, { ...extraParams }],
    queryFn: ({ pageParam, queryKey }) => {
      return actionFn(
        { page: withPageParam ? pageParam : undefined, ...queryKey[1] } || {}
      );
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      return lastPage?.data?.pagination?.nextPage;
    },
    select: (data) => {
      return (
        data?.pages
          ?.map((page) => {
            return page?.data?.data;
          })
          ?.flat() ?? []
      );
    },
    refetchOnMount: false,
    gcTime: 0,
  });

  const lastRef = React.useCallback(
    (node) => {
      if (!node) return;
      if (observer.current) observer.current?.disconnect();
      observer.current = new IntersectionObserver((nodes) => {
        if (nodes[0].isIntersecting) {
          if (hasNextPage && !isFetchingNextPage) fetchNextPage();
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasNextPage, isFetchingNextPage]
  );

  React.useImperativeHandle(
    ref,
    () => ({
      refresh() {
        refetch();
      },
    }),
    []
  );

  useEffect(() => {
    if (data?.length && data?.length > 0) {
      setHasdata(true);
    } else {
      setHasdata(false);
    }
  }, [data?.length]);

  return (
    <>
      {status === "pending" ? (
        new Array(defaultLoadingRows).fill("").map((_, index) => {
          return <LoadingComponent key={`col${index}`} />;
        })
      ) : status === "success" &&
        data &&
        data instanceof Array &&
        data.length > 0 ? (
        data?.map((item, key, arr) => {
          if (arr.length === key + 1) {
            return (
              <span ref={lastRef} key={item?.uuid}>
                <ListItem
                  item={item}
                  refetch={refetch}
                  onChangeItemHandler={onChangeItemHandler}
                  itemValue={itemValue}
                />
              </span>
            );
          }
          return (
            <ListItem
              key={item?.uuid}
              item={item}
              refetch={refetch}
              onChangeItemHandler={onChangeItemHandler}
              itemValue={itemValue}
            />
          );
        })
      ) : (
        <div className=" text-grey-600  place-items-center	grid h-full">
          <div className="text-center">
            <NoRecord />
            {t("overview.noRecordFound")}
          </div>
        </div>
      )}
    </>
  );
};

export default React.forwardRef(ListManager);
