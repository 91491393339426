import { X, RemoveUser } from "assets/images";
import { Button, Spinner } from "components";
import React from "react";
import { useTranslation } from "react-i18next";

const DeleteTeamModal = ({
  handleClose = () => null,
  deleteFn = () => null,
  isLoading = false,
  showModal = {},
}) => {
  const itemElement = showModal?.elementData ?? {};
  const { t } = useTranslation();
  return (
    <>
      <div className="flex justify-end items-center p-4">
        <button type="button" onClick={() => handleClose()}>
          <X className="text-grey-600 w-6 h-6" />
        </button>
      </div>
      <div className="text-center px-16 pb-5 justify-center">
        <div className="flex justify-center  mt-8">
          <RemoveUser className="text-black" />
        </div>
        <div className="text-4xl text-black font-semibold	 mt-4 mb-1">
          {t("overview.deleteTeam")}
        </div>
        <div className="text-grey-700 text-base	mt-4 w-96">
          {t("overview.deleteConfirmation")}{" "}
          <span className="font-medium">{itemElement?.name}</span> ?
        </div>
        <div className="mt-10 pb-14">
          <Button
            disabled={isLoading}
            background="danger"
            type="button"
            onClick={() =>
              deleteFn({
                uuid: itemElement?.uuid,
              })
            }
          >
            <div className="flex w-full px-5 justify-center items-center">
              {isLoading ? <Spinner /> : t("overview.delete")}
            </div>
          </Button>
        </div>
      </div>
    </>
  );
};

export default DeleteTeamModal;
