import {
  Arrow,
  Billing,
  Clock,
  Mastercard,
  Money,
  X,
  visa,
} from "assets/images";
import { Button, Modal, Skeletons, Spinner } from "components";
import useBuyBundle from "hooks/useBuyBundle";
import useSavedCards from "hooks/useSavedCards";
import React, { useEffect, useState, useMemo } from "react";
import { useForm, Controller } from "react-hook-form";
import { NG_CURRENCY_SYMBOL, sumOfTwoDigits } from "shared/resources";
import { dropdownActions, initialModalState } from "../constants";
import PaymentConfirmationModal from "components/PaymentConfirmationModal";
import { useTranslation } from "react-i18next";

const cardPlaceholderImage = {
  visa: visa,
  mastercard: Mastercard,
};

const CardItem = ({ data, setSelectedCard, selectedCard }) => {
  useEffect(() => {
    return () => {
      setSelectedCard("");
    };
  }, []);

  return (
    <div
      className="w-full flex flex-col gap-1 cursor-pointer"
      onClick={() => setSelectedCard(data?.uuid)}
    >
      <div className="flex items-center px-4 h-14  justify-between w-full bg-green-50 rounded-md border border-green-100">
        <div className="text-md flex gap-2 items-center ">
          <img
            src={cardPlaceholderImage[data?.card_type?.trim()?.toLowerCase()]}
            alt="MasterCard"
            className="w-8 h-5"
          />
          ****{data?.last4}
        </div>
        <div className="flex items-center gap-2">
          <input
            className="checked:bg-green checked:hover:bg-green checked:active:bg-green checked:focus:bg-green focus:outline-none focus:ring-1 focus:ring-green cursor-pointer"
            type="radio"
            checked={data?.uuid === selectedCard}
            onChange={() => setSelectedCard(data?.uuid)}
          />
        </div>
      </div>
    </div>
  );
};
export const PurchaseBundleModal = ({
  showModal = {},
  handleClose = () => null,
}) => {
  const [showModalPayment, setShowModalPayment] = useState(initialModalState);
  const { t } = useTranslation();
  const [selectedCard, setSelectedCard] = useState("");
  const { data, isLoading } = useSavedCards();
  const cards = data?.data?.data;
  const { elementData = {} } = showModal;
  const { mutate, isPending } = useBuyBundle({ handleSuccess: handleClose });
  const { control, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      payment_method: "NEW_CARD",
      save_card: "N",
    },
    mode: "onChange",
  });
  const PaymentMethodArray = [
    {
      label: t("payment.newMethod"),
      type: "NEW_CARD",
    },
    {
      label: t("payment.savedCards"),
      type: "SAVED_CARD",
    },
    {
      label: t("payment.fonuWallet"),
      type: "WALLET",
    },
  ];

  const ModalLookup = useMemo(() => {
    return {
      [dropdownActions.PURCHASE_CONFIRMATION]: (
        <PaymentConfirmationModal
          handleClose={handleModalClosePayment}
          continueCallBack={handleSubmit(onSubmit)}
          setValue={setValue}
        />
      ),
    };
  }, [showModalPayment]);

  useEffect(() => {
    if (
      cards &&
      cards?.length > 0 &&
      watch("payment_method") === "SAVED_CARD"
    ) {
      const defaultCard = cards?.find((item) => item?.is_default === "Y");
      setSelectedCard(defaultCard?.uuid || "");
    }
  }, [cards?.length, watch("payment_method")]);

  function handleModalClosePayment() {
    setShowModalPayment(initialModalState);
  }

  function onSubmit(val) {
    const uuidArr = [];

    if (elementData?.call_bundle) {
      uuidArr.push(elementData?.call_bundle);
    }
    if (elementData?.sms_bundle) {
      uuidArr.push(elementData?.sms_bundle);
    }

    mutate({
      bundle_uuids: uuidArr,
      payment_type: val?.payment_method,
      is_saved_card: val?.save_card,
      card_uuid: val?.payment_method ? selectedCard : undefined,
      source: val?.source,
    });
  }
  const { isShow, modalType } = showModalPayment;

  return (
    <form className="min-w-[500px]">
      <div className="flex  justify-between items-start pt-4 lg:pb-4 md-:pb-2  px-4">
        <div className="flex flex-col gap-1">
          <div className="text-xl font-semibold text-black">
            {elementData?.call_bundle && elementData?.sms_bundle
              ? `Purchasing for ${elementData?.smsBundleData?.total_bundles} SMS and Call ${elementData?.callBundleData?.total_bundles} Minutes`
              : elementData?.call_bundle
              ? `Purchasing for Call ${elementData?.callBundleData?.total_bundles} Minutes`
              : `Purchasing for ${elementData?.smsBundleData?.total_bundles} SMS`}
          </div>
          <div className="text-sm  text-grey-700">
            {elementData?.call_bundle && elementData?.sms_bundle
              ? `Get ${elementData?.smsBundleData?.total_bundles} SMS for ${NG_CURRENCY_SYMBOL}${elementData?.smsBundleData?.cost} , Call ${elementData?.callBundleData?.total_bundles} Minutes for ${NG_CURRENCY_SYMBOL}${elementData?.callBundleData?.cost}`
              : elementData?.call_bundle
              ? `Get Call ${elementData?.callBundleData?.total_bundles} Minutes for ${NG_CURRENCY_SYMBOL}${elementData?.callBundleData?.cost}`
              : `Get ${elementData?.smsBundleData?.total_bundles} SMS for ${NG_CURRENCY_SYMBOL}${elementData?.smsBundleData?.cost}`}
          </div>
        </div>

        <button type="button" onClick={handleClose}>
          <X className="text-grey-600 w-6 h-6" />
        </button>
      </div>

      <>
        {elementData?.call_bundle ? (
          <div className="w-full  lg:mb-5 md:mb-3 px-4">
            <div className="text-lg font-semibold md:mt-1 lg:mt-4 mb-1">
              {t("overview.callsBundle")}
            </div>
            <div className="border border-grey-400 bg-grey-100 rounded-md p-3 flex items-center justify-between  w-full">
              <div className="flex flex-col items-center justify-center gap-1  w-full">
                <div className="text-xs text-grey-600 flex items-center gap-1">
                  <Billing className="w-4 h-4 rotate-90" />{" "}
                  {t("overview.bundle")}
                </div>
                <div className="text-sm">
                  {elementData?.callBundleData?.total_bundles}{" "}
                  {t("overview.minutes")}
                </div>
              </div>

              <div className="flex flex-col items-center justify-center gap-1 w-full  border-r-2 border-l-2 border-grey-400">
                <div className="text-xs text-grey-600 flex items-center  gap-1">
                  <Clock className="w-4 h-4 " /> {t("overview.validity")}
                </div>
                <div className="text-sm capitalize">
                  {`${elementData?.callBundleData?.validity}`.toLowerCase()}
                </div>
              </div>

              <div className="flex flex-col items-center justify-center gap-1  w-full ">
                <div className="text-xs text-grey-600 flex items-center gap-1">
                  <Money className="w-4 h-4 " /> {t("overview.price")}
                </div>
                <div className="text-sm">
                  {NG_CURRENCY_SYMBOL} {elementData?.callBundleData?.cost}
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {elementData?.sms_bundle ? (
          <div className="w-full lg:mb-5 md:mb-3 px-4">
            <div className="text-lg font-semibold lg:mt-4 md:mt-2 mb-1">
              {t("overview.smsBundle")}
            </div>
            <div className="border border-grey-400 bg-grey-100 rounded-md p-3 flex items-center justify-between  w-full">
              <div className="flex flex-col items-center justify-center gap-1  w-full">
                <div className="text-xs text-grey-600 flex items-center gap-1">
                  <Billing className="w-4 h-4 rotate-90" />{" "}
                  {t("overview.bundle")}
                </div>
                <div className="text-sm">
                  {elementData?.smsBundleData?.total_bundles}{" "}
                  {t("overview.minutes")}
                </div>
              </div>

              <div className="flex flex-col items-center justify-center gap-1 w-full  border-r-2 border-l-2 border-grey-400">
                <div className="text-xs text-grey-600 flex items-center  gap-1">
                  <Clock className="w-4 h-4 " /> {t("overview.validity")}
                </div>
                <div className="text-sm capitalize">
                  {`${elementData?.smsBundleData?.validity}`.toLowerCase()}
                </div>
              </div>

              <div className="flex flex-col items-center justify-center gap-1  w-full ">
                <div className="text-xs text-grey-600 flex items-center gap-1">
                  <Money className="w-4 h-4 " /> {t("overview.price")}
                </div>
                <div className="text-sm">
                  {NG_CURRENCY_SYMBOL} {elementData?.smsBundleData?.cost}
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <div className="w-full px-4">
          <div className=" w-full border border-b-0 border-l-0 border-r-0  border-grey-400  "></div>
        </div>
        <div className="md:py-2 lg:py-3 px-4 w-full">
          <div className=" lg:text-xl md:text-lg text-grey-600  ">
            {t("overview.subtotal")}
          </div>
          <div className="lg:text-5xl md:text-3xl text-grey-900 font-medium lg:mt-2 ">
            {NG_CURRENCY_SYMBOL}
            {sumOfTwoDigits(
              Number(elementData?.smsBundleData?.cost ?? 0),
              Number(elementData?.callBundleData?.cost ?? 0)
            )}
          </div>
        </div>
        <div className="w-full px-4">
          <div className=" w-full border border-b-0 border-l-0 border-r-0  border-grey-400 mb-4 "></div>
        </div>
        <div className="flex gap-3 px-4">
          {PaymentMethodArray.map((item) => {
            return (
              <label
                htmlFor={`payment_type_${item?.type}`}
                key={item?.type}
                className="flex items-center gap-1 font-medium text-grey-800 cursor-pointer"
              >
                <Controller
                  name={"payment_method"}
                  control={control}
                  render={({ field }) => {
                    return (
                      <input
                        className="checked:bg-green checked:hover:bg-green checked:active:bg-green checked:focus:bg-green focus:outline-none focus:ring-1 focus:ring-green"
                        type="radio"
                        {...field}
                        id={`payment_type_${item?.type}`}
                        value={item?.type}
                        checked={item?.type === watch("payment_method")}
                      />
                    );
                  }}
                />
                {item?.label}
              </label>
            );
          })}
        </div>

        {watch("payment_method") === "SAVED_CARD" ? (
          <div className="w-full rounded-md px-4 mt-2 bg-white flex justify-center">
            <div className="max-w-[600px] max-h-32 overflow-auto w-full flex flex-col gap-2">
              {isLoading
                ? Array.from({ length: 3 }, (_, i) => i + 1).map((val) => {
                    return (
                      <div className="w-full" key={val}>
                        <Skeletons height="h-14" />
                      </div>
                    );
                  })
                : cards?.length > 0
                ? cards?.map((card) => (
                    <CardItem
                      key={card?.uuid}
                      data={card}
                      setSelectedCard={setSelectedCard}
                      selectedCard={selectedCard}
                    />
                  ))
                : t("overview.noSavedCards")}
            </div>
          </div>
        ) : null}
        {watch("payment_method") === "NEW_CARD" ? (
          <div className="w-full px-4 mt-4 gap-2 flex items-center">
            <input
              className="checked:bg-green checked:hover:bg-green checked:active:bg-green checked:focus:bg-green focus:outline-none focus:ring-1 focus:ring-green"
              type="checkbox"
              id="save_card"
              checked={watch("save_card") === "Y"}
              onChange={(e) => {
                setValue("save_card", e.target.checked ? "Y" : "N");
              }}
            />
            <label className="cursor-pointer" htmlFor="save_card">
              {t("overview.futureQuestion")}
            </label>
          </div>
        ) : null}

        <div className="flex items-center justify-end gap-4 px-4 pb-6 mt-3 ">
          <Button
            extraClasses="justify-start  cursor-pointer"
            type="button"
            background="transparent"
            width="w-16"
            onClick={handleClose}
          >
            <div className="text-grey-900 flex items-center gap-2">
              <Arrow className="rotate-180 text-black" />
              {t("overview.back")}
            </div>
          </Button>
          <Button
            type="button"
            extraClasses="min-w-[300px] max-w-[300px]"
            disabled={isPending}
            onClick={() => {
              if (watch("payment_method") === "NEW_CARD") {
                setShowModalPayment({
                  modalType: dropdownActions.PURCHASE_CONFIRMATION,
                  isShow: true,
                  elementData: {},
                });
              } else {
                handleSubmit(onSubmit)();
              }
            }}
          >
            {isPending ? <Spinner /> : t("overview.purchase")}
          </Button>
        </div>
        {isShow ? (
          <Modal
            handleClose={handleModalClosePayment}
            headerComponent={null}
            footerComponent={null}
            shouldCloseOnClickOutside={false}
          >
            {ModalLookup[modalType]}
          </Modal>
        ) : null}
      </>
    </form>
  );
};
