import { Check } from "assets/images";
import { Input, ReadViewInput } from "components";
import SwitchInput from "components/SwitchInput";
import React from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

const BillingDetailsForm = ({ control, watch, errors, setValue, readView }) => {
  const isStepDone =
    watch("billing_details.first_name") &&
    watch("billing_details.resident_address") &&
    watch("billing_details.billing_address") &&
    watch("billing_details.dob");
  const { t } = useTranslation();
  return (
    <>
      <div className="w-full max-w-[991px] h-[1px] bg-grey-400 my-[60px] "></div>

      <div className="flex gap-6 max-w-[991px]">
        {isStepDone ? (
          <div className="rounded-full w-10 min-w-[40px] min-h-[40px] h-10 text-white flex items-center justify-center bg-green">
            <Check />
          </div>
        ) : (
          <div className="rounded-full w-10 min-w-[40px] min-h-[40px] h-10 text-green flex items-center justify-center bg-green-200">
            2
          </div>
        )}
        <div className="flex flex-col  w-full">
          <div className="text-grey-900 text-lg font-semibold">
            {t("overview.billingDetails")}
          </div>
          <div className="text-grey-700 text-sm mb-8 ">
            {t("overview.addressOfBilling")}
          </div>
          <div className="flex flex-col gap-4">
            <div className="flex items-center gap-4 ">
              {readView ? (
                <ReadViewInput
                  labelText={t("overview.firstName")}
                  valueText={watch("billing_details.first_name")}
                />
              ) : (
                <Controller
                  name="billing_details.first_name"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        label={t("overview.firstName")}
                        autoComplete="off"
                        maxLength="50"
                        error={errors?.billing_details?.first_name?.message}
                        onWhitebg={true}
                      />
                    );
                  }}
                />
              )}

              {readView ? (
                <ReadViewInput
                  labelText={t("overview.lastName")}
                  valueText={watch("billing_details.last_name")}
                />
              ) : (
                <Controller
                  name="billing_details.last_name"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        label={t("overview.lastName")}
                        autoComplete="off"
                        maxLength="50"
                        error={errors?.billing_details?.last_name?.message}
                        onWhitebg={true}
                      />
                    );
                  }}
                />
              )}
            </div>
            <div className="flex  justify-between items-center gap-4">
              {readView ? (
                <div className="mt-4 w-full">
                  <ReadViewInput
                    labelText={t("overview.dob")}
                    valueText={watch("billing_details.dob")}
                  />
                </div>
              ) : (
                <Controller
                  name="billing_details.dob"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        type="date"
                        label={t("overview.dob")}
                        autoComplete="off"
                        max={
                          new Date(
                            new Date().getFullYear() - 18,
                            new Date().getMonth(),
                            new Date().getDate()
                          )
                            .toISOString()
                            .split("T")[0]
                        }
                        error={errors?.billing_details?.dob?.message}
                        onWhitebg={true}
                      />
                    );
                  }}
                />
              )}
              {readView ? (
                <div className="mt-4 w-full">
                  <ReadViewInput
                    labelText={t("overview.residentAddress")}
                    valueText={watch("billing_details.resident_address")}
                  />
                </div>
              ) : (
                <Controller
                  name="billing_details.resident_address"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        label={t("overview.residentAddress")}
                        error={
                          errors?.billing_details?.resident_address?.message
                        }
                        onWhitebg={true}
                      />
                    );
                  }}
                />
              )}
            </div>
            <div className="flex  justify-between items-center gap-4">
              <div className="w-1/2">
                {readView ? (
                  <div className="mt-4 w-full">
                    <ReadViewInput
                      labelText={t("overview.billingAddress")}
                      valueText={watch("billing_details.billing_address")}
                      labelClasses=""
                      valueClasses="font-semibold text-base text-grey-700"
                    />
                  </div>
                ) : (
                  <Controller
                    name="billing_details.billing_address"
                    control={control}
                    render={({ field }) => {
                      return (
                        <Input
                          {...field}
                          label={t("overview.billingAddress")}
                          onChange={(e) => {
                            setValue(
                              "billing_details.is_billing_resident_address_same",
                              false,
                              { shouldValidate: true }
                            );
                            return field.onChange(e.target.value);
                          }}
                          error={
                            errors?.billing_details?.billing_address?.message
                          }
                          onWhitebg={true}
                        />
                      );
                    }}
                  />
                )}
              </div>
              <div className="w-1/2 mt-6">
                <Controller
                  name={"billing_details.is_billing_resident_address_same"}
                  control={control}
                  render={({ field }) => (
                    <SwitchInput
                      {...field}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setValue(
                            "billing_details.billing_address",
                            watch("billing_details.resident_address"),
                            { shouldValidate: true }
                          );
                        }
                        return field.onChange(e.target.checked);
                      }}
                      name="is_billing_resident_address_same"
                      label={"Same as resident"}
                      disabled={readView}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full max-w-[991px] h-[1px] bg-grey-400 my-[60px] "></div>
    </>
  );
};

export default BillingDetailsForm;
