import { FonuIcon, Mtn, Phone, SmsIcon, WhatsAppLogo } from "assets/images";
import { Button } from "components";
import { CircleAlert } from "lucide-react";
import React from "react";

const VoiceSms = () => {
  return (
    <div className="text-[#474A4E]">
      <div className="w-full bg-[#E0FFE0] h-[62px] flex items-center px-4 rounded-md gap-3">
        <CircleAlert size={14} />
        <p>
          Please choose a Provider for Voice flow, SMS flow or WhatsApp Flow
        </p>
      </div>
      <div className="w-[90%] h-[160px] bg-[#D2D5DA]/40 shadow-md rounded-md flex items-center justify-between m-auto px-4 mt-4">
        <div className="flex gap-20">
          <div className="flex items-center gap-2">
            <Mtn className="w-10 h-10" />
            <p className="text-sm font-medium text-black">MTN</p>
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex gap-2 items-center text-sm">
              <Phone className="w-4 h-4" />
              <p>Voice Bot</p>
            </div>
            <div className="flex gap-2 items-center text-sm">
              <SmsIcon className="w-4 h-4" />
              <p>SMS Bot</p>
            </div>
            <div className="flex gap-2 items-center text-sm">
              <WhatsAppLogo className="w-4 h-4" />
              <p>WhatsApp Bot</p>
            </div>
          </div>
        </div>
        <div className="w-[100px]">
          <Button>Config</Button>
        </div>
      </div>
      <div className="w-[90%] h-[160px] bg-[#D2D5DA]/40 shadow-md rounded-md flex items-center justify-between m-auto px-4 mt-6">
        <div className="flex gap-20">
          <div className="flex items-center gap-2">
            <FonuIcon className="w-10 h-10" />
            <p className="text-sm font-medium text-black">Fonu</p>
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex gap-2 items-center text-sm">
              <Phone className="w-4 h-4" />
              <p>Voice Bot</p>
            </div>
            <div className="flex gap-2 items-center text-sm">
              <SmsIcon className="w-4 h-4" />
              <p>SMS Bot</p>
            </div>
            <div className="flex gap-2 items-center text-sm">
              <WhatsAppLogo className="w-4 h-4" />
              <p>WhatsApp Bot</p>
            </div>
          </div>
        </div>
        <div className="w-[100px]">
          <Button>Config</Button>
        </div>
      </div>
    </div>
  );
};

export default VoiceSms;
