import { HubspotIcon } from "assets/images";
import { Button } from "components";
import React from "react";

const Hubspot = () => {
  return (
    <div
      className={`h-[236px] bg-[#F1F1F1] shadow-md flex flex-col justify-center px-6`}
    >
      <div className="flex flex-col gap-7">
        <div className="flex items-center space-x-2">
          <HubspotIcon className="w-10 h-10" />
          <p className="">HubSpot</p>
        </div>
        <div className="flex flex-col gap-6">
          <p className="font-bold text-[#0D121D]">
            You can connect to CRM account to use CRM HubSpot actions in the
            flow.
          </p>

          <div className="w-[400px]">
            <Button>Connect CRM Account</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hubspot;
