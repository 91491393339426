import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Input, Spinner } from "components";
import useProfileUpdate from "hooks/useProfileUpdate";
import React from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { getRequiredText, passwordRegx } from "shared/resources";
import * as yup from "yup";

const validationSchema = yup.object().shape({
  old_password: yup
    .string()
    .required(getRequiredText("Old password"))
    .matches(
      passwordRegx,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character i.e Demo@123."
    ),
  password: yup
    .string()
    .required(getRequiredText("Password"))
    .matches(
      passwordRegx,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character i.e Demo@123."
    ),
  confirm_password: yup
    .string()
    .required(getRequiredText("Confirm password"))
    .when("password", {
      is: (password) => password && password.length > 0,
      then: yup.string().oneOf([yup.ref("password")], "Passwords do not match"),
    }),
});

const ChangePassword = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      old_password: "",
      password: "",
      confirm_password: "",
    },
    mode: "onChange",
  });
  const { mutate: updateProfileMutate, isPending: updateProfileLoad } =
    useProfileUpdate({ handleSuccess });

  const onSubmit = (values) => {
    updateProfileMutate({
      password: values?.confirm_password,
      old_password: values?.old_password,
    });
  };
  function handleSuccess() {
    reset();
  }
  const { t } = useTranslation();
  return (
    <div className="overflow-auto w-full px-6 pt-6 pb-6">
      <div className="flex items-center justify-between mb-5">
        <div className="text-sm text-grey-700">
          {t("profile.changePassword")}
        </div>
      </div>
      <div className="table w-full rounded-md border border-grey-400 bg-white ">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col items-start xxl:w-9/12 xl:w-9/12 lg:w-10/12 md:w-10/12 sm:w-11/12 xs:w-11/12 m-auto mt-[70px] gap-3">
            <div className="w-full">
              <Controller
                name="old_password"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    type="password"
                    autoComplete="off"
                    placeholder={t("password.min")}
                    label={t("password.oldPassword")}
                    error={errors?.old_password?.message}
                  />
                )}
              />
            </div>
            <div className="w-full">
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    type="password"
                    autoComplete="off"
                    placeholder={t("password.min")}
                    label={t("password.password")}
                    error={errors?.password?.message}
                  />
                )}
              />
            </div>
            <div className="w-full">
              <Controller
                name="confirm_password"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    type="password"
                    autoComplete="off"
                    placeholder={t("password.min")}
                    label={t("password.confirm")}
                    error={errors?.confirm_password?.message}
                  />
                )}
              />
            </div>
            <div className="flex  items-center justify-center mt-[76px] mb-[1.7rem] w-full">
              <Button
                width="w-[410px]"
                type="submit"
                border="border-green"
                disabled={updateProfileLoad}
              >
                <div className="flex w-fit font-semibold  px-5 justify-center items-center text-white">
                  {updateProfileLoad ? <Spinner /> : t("overview.save")}
                </div>
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;
