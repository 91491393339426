import { X, Paystack, Mono } from "assets/images";
import Button from "components/Button";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const paymentMode = [
  {
    id: 1,
    icon: Paystack,
    label: "Paystack",
    value: "PAYSTACK",
  },
  {
    id: 2,
    icon: Mono,
    label: "Mono",
    value: "MONO",
  },
];

const PaymentConfirmationModal = ({
  handleClose = () => null,
  continueCallBack = () => null,
  setValue = () => null,
  // showModal,
}) => {
  const [selectedPaymentMode, setSelectedPaymentMode] = useState("");
  const { t } = useTranslation();
  return (
    <>
      <div className="text-left px-6 pb-5 justify-center">
        <div className="relative">
          <div>
            <div className="text-2xl text-black font-semibold	 mt-9 mb-1">
              {t("payment.headerInfo")}
            </div>
            <div className="text-grey-700 text-base	mt-4 w-96">
              {t("payment.headerInst")}
            </div>
          </div>
          <div className="flex justify-end items-center p-4 absolute right-0 top-[-17px]">
            <button type="button" onClick={() => handleClose()}>
              <X className="text-grey-600 w-6 h-6" />
            </button>
          </div>
        </div>
        <div className="flex flex-col gap-8 my-8">
          {paymentMode.map(({ id, label, value, icon }) => {
            return (
              <label
                htmlFor={value}
                key={id}
                className="flex items-center justify-between px-4 cursor-pointer font-semibold bg-grey-200 rounded-md h-[70px] border border-grey-400 "
                onClick={() => setSelectedPaymentMode(value)}
              >
                <div className="flex items-center gap-2">
                  <img src={icon} alt={label} />
                  {label}
                </div>
                <input
                  className="checked:bg-green checked:hover:bg-green checked:active:bg-green checked:focus:bg-green bg-grey-300 border border-grey-600 rounded focus:outline-none focus:ring-1 focus:ring-green"
                  id={value}
                  type="checkbox"
                  value={value}
                  onChange={(e) => {
                    setSelectedPaymentMode(e.target.value);
                  }}
                  checked={value === selectedPaymentMode}
                />
              </label>
            );
          })}
        </div>
        <div className="mt-10 pb-14 flex items-center gap-4">
          <Button
            type="button"
            onClick={() => {
              // console.log(showModal);
              setValue("source", selectedPaymentMode);
              localStorage.setItem("redirectTo", window.location.url);
              continueCallBack();
              handleClose();
            }}
            disabled={selectedPaymentMode ? false : true}
          >
            <div className="flex w-full px-5 justify-center items-center">
              {t("payment.continue")}
            </div>
          </Button>
        </div>
      </div>
    </>
  );
};

export default PaymentConfirmationModal;
