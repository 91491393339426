import {
  AllBots,
  FacebookMessenger,
  GroupUser,
  InstagramMessenger,
  LinkedIn,
  Sdk,
  SmsIcon,
  TelegramIcon,
  TwitterIcon,
  User,
  WhatsappBusiness,
  ZigiIcon,
} from "assets/images";
import { useAuth } from "hooks/useAuth";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  Key,
} from "lucide-react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { navigationArr } from "shared/resources";

export default function Sidebar() {
  const location = useLocation();
  const { user } = useAuth();
  const hasIvrAccess = Boolean(
    user?.get_company_details?.features?.find(
      (feat) => feat?.key === "voice_response_ivr"
    )?.value
  );
  const hasCallRecordingAccess = Boolean(
    user?.get_company_details?.features?.find(
      (feat) => feat?.key === "call_recording"
    )?.value
  );
  const parentPath = window.location.pathname?.split("/")?.[1];
  const [{ innerNavigation = [] }] = navigationArr.filter((nav) =>
    parentPath.includes(nav?.parent)
  );

  const [mutatedRoutes, setMutatedRoutes] = useState(innerNavigation);
  const [dropdowns, setDropdowns] = useState({
    isVerificationOpen: false,
    isDeveloperOpen: false,
    isBotsOpen: false,
  });
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
    if (!isCollapsed) {
      setDropdowns({
        isVerificationOpen: false,
        isDeveloperOpen: false,
        isBotsOpen: false,
      });
    }
    if (isCollapsed) {
      setDropdowns({
        isVerificationOpen: true,
      });
    }
  };

  useEffect(() => {
    if (location.pathname) {
      const updatedRoutes = innerNavigation.map((nav) => ({
        ...nav,
        isActive: location.pathname.includes(nav.path),
        user,
      }));
      setMutatedRoutes(updatedRoutes);
    }
  }, [location.pathname]);

  const { t } = useTranslation();

  const toggleDropdown = (key) => {
    setDropdowns((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const renderDropdownLinks = (items) => (
    <div className="flex flex-col pl-8">
      {items.map((item) => {
        const isActive = location.pathname === item.path;
        return (
          <Link
            key={item.id}
            to={item.path}
            className={`py-2 pl-[2px] xxl:text-base xl:text-base lg:text-sm md:text-sm flex items-center text-gray-600 gap-2 rounded-md ${
              isActive ? "bg-grey-300" : ""
            }`}
          >
            <item.icon className="w-5 h-5" />
            <span>{item.label}</span>
          </Link>
        );
      })}
    </div>
  );

  return (
    <div>
      <span className="sm:hidden p-3 flex font-bold text-lg">
        {mutatedRoutes?.find((route) => route?.isActive)?.label ?? null}
      </span>
      <div className="pt-4 px-4 flex justify-end">
        <button
          onClick={toggleSidebar}
          className="flex items-center justify-center w-8 h-8 rounded-full hover:bg-grey-300"
        >
          {isCollapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </button>
      </div>
      <div
        className={`overflow-x-auto xs:h-11 sm:h-full ${
          isCollapsed ? "w-24" : "w-64"
        }  xs:rounded-md xs:m-2 xs:mb-0 xs:mt-0 transition-all duration-300 sm:m-0 xs:p-[2px] xs:bg-grey-300 sm:bg-grey-100 sm:pt-10 sm:px-4 pb-10 flex md:flex-col xs:flex-row  gap-2 border-grey-300 border-r`}
      >
        {mutatedRoutes?.map((nav) => {
          if (
            nav?.access?.length === 0 ||
            !nav.access.includes(user?.role_id) ||
            (nav?.label === "Phone menu" && !hasIvrAccess) ||
            (nav?.label === "Call recordings" && !hasCallRecordingAccess)
          ) {
            return null;
          }

          const dropdownConfig = {
            Verification: {
              isOpen: dropdowns.isVerificationOpen,
              toggle: () => toggleDropdown("isVerificationOpen"),
              items: [
                {
                  id: 1,
                  path: "/settings/customer-kyc",
                  label: t("navigation.customerKYC"),
                  icon: User,
                },
                {
                  id: 2,
                  path: "/settings/kyc-details",
                  label: t("navigation.reports"),
                  icon: GroupUser,
                },
              ],
            },
            "APIs and SDKs": {
              isOpen: dropdowns.isDeveloperOpen,
              toggle: () => toggleDropdown("isDeveloperOpen"),
              items: [
                {
                  id: 1,
                  path: "/settings/sdk-integration",
                  label: "Voice SDK",
                  icon: Sdk,
                },
                {
                  id: 2,
                  path: "/settings/api-keys-webhooks",
                  label: "APIs & Webhooks",
                  icon: Key,
                },
              ],
            },
            Bots: {
              isOpen: dropdowns.isBotsOpen,
              toggle: () => toggleDropdown("isBotsOpen"),
              items: [
                {
                  id: "all-bots",
                  path: "/settings/all-bots",
                  label: t("socials.all-bots"),
                  icon: AllBots,
                  description: t("socials.manageWb"),
                },
                {
                  id: "zigi",
                  path: "/settings/zigi",
                  label: t("socials.zigi"),
                  icon: ZigiIcon,
                  description: t("socials.manageWb"),
                },
                {
                  id: "whatsapp",
                  path: "/settings/whatsapp",
                  label: t("socials.whatsapp"),
                  icon: WhatsappBusiness,
                  description: t("socials.manageWb"),
                },
                {
                  id: "telegram",
                  path: "/settings/telegram",
                  label: t("socials.telegram"),
                  icon: TelegramIcon,
                  description: t("socials.manageTelegram"),
                },
                {
                  id: "instagram",
                  path: "/settings/instagram",
                  label: t("socials.instagram"),
                  icon: InstagramMessenger,
                  description: t("socials.manageInsta"),
                },
                {
                  id: "facebook",
                  path: "/settings/facebook",
                  label: t("socials.facebook"),
                  icon: FacebookMessenger,
                  description: t("socials.manageFb"),
                },
                {
                  id: "twitter",
                  path: "/settings/twitter",
                  label: t("socials.twitter"),
                  icon: TwitterIcon,
                  description: t("socials.manageTwitter"),
                },
                {
                  id: "linkedin",
                  path: "/settings/linkedin",
                  label: t("socials.linkedin"),
                  icon: LinkedIn,
                  description: t("socials.manageLinkedIn"),
                },
                {
                  id: "sms-socials",
                  path: "/settings/sms-socials",
                  label: t("socials.sms-socials"),
                  icon: SmsIcon,
                  description: t("socials.manageLinkedIn"),
                },
              ],
            },
          };

          if (dropdownConfig[nav.label]) {
            const { isOpen, toggle, items } = dropdownConfig[nav.label];
            return (
              <div key={nav.id} className="relative w-full">
                {isCollapsed ? (
                  <div
                    onClick={() => {
                      setIsCollapsed(false);
                      toggleDropdown(nav.label);
                    }}
                    className={`flex justify-center items-center gap-3 p-3 rounded-md cursor-pointer w-full ${
                      nav.isActive ? "bg-grey-300" : "hover:bg-grey-200"
                    }`}
                  >
                    <nav.icon className="w-5 h-5 " />
                  </div>
                ) : (
                  <div
                    onClick={toggle}
                    className={`flex justify-center  xxl:text-base xl:text-base lg:text-sm md:text-sm items-center gap-3 p-3 rounded-md cursor-pointer w-full ${
                      nav.isActive ? "bg-grey-300" : "hover:bg-grey-200"
                    }`}
                  >
                    <nav.icon className="w-5 h-5 " />

                    <span>{t(nav.label)}</span>
                    {isOpen ? (
                      <ChevronUpIcon className="w-5 h-5 ml-auto" />
                    ) : (
                      <ChevronDownIcon className="w-5 h-5 ml-auto" />
                    )}
                  </div>
                )}
                {isOpen && renderDropdownLinks(items)}
              </div>
            );
          }

          return isCollapsed ? (
            <Link
              key={nav?.id}
              to={nav?.path}
              className={`flex items-center justify-center gap-3 p-2 rounded-md xs:hover:bg-white sm:hover:bg-grey-300 cursor-pointer xs:w-full whitespace-nowrap  ${
                nav?.isActive ? "sm:bg-grey-300 xs:bg-white " : ""
              }`}
            >
              <nav.icon className="w-5 h-5 md:flex xs:hidden" />
            </Link>
          ) : (
            <Link
              key={nav?.id}
              to={nav?.path}
              className={`xxl:text-base xl:text-base lg:text-sm md:text-sm flex items-center xs:justify-center sm:justify-start gap-3 p-3 rounded-md xs:hover:bg-white sm:hover:bg-grey-300 cursor-pointer xs:w-full whitespace-nowrap  ${
                nav?.isActive ? "sm:bg-grey-300 xs:bg-white " : ""
              }`}
            >
              <nav.icon className="w-5 h-5 md:flex xs:hidden" />
              {!isCollapsed && <span className="truncate">{t(nav.label)}</span>}
            </Link>
          );
        })}
      </div>
    </div>
  );
}
