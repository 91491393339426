import { X } from "assets/images";

import { SipSocketContext } from "hooks/useSip/SipSocketContext";
import React, { useContext, useEffect, useRef, useState } from "react";
import ActiveCallScreen from "./ActiveCallScreen";
import Digits from "./Digits";
import DialerUpperScreen from "./DialerUpperScreen";

const Dialer = ({
  openDialpad = () => null,
  callActiveKey = "",
  callSessions = [],
  action,
  setAction = () => null,
}) => {
  const { socket = {} } = useContext(SipSocketContext);
  const { _makeCall = () => null } = socket;
  const [phoneNumber, setPhoneNumber] = useState("");
  const inputRef = useRef(null);

  const isCallExist = callSessions.length > 0;
  const activeCallData =
    callSessions.filter((call) => call?.callID === callActiveKey)?.[0] || {};

  useEffect(() => {
    if (!isCallExist && inputRef?.current) {
      inputRef?.current?.focus();
    }
  }, []);

  const appendPhoneNumber = (key) => {
    inputRef?.current?.focus();
    setPhoneNumber((prevPhoneNumber) => prevPhoneNumber + key);
  };

  const handleMakeCall = () => {
    if (phoneNumber?.length) {
      _makeCall(phoneNumber, phoneNumber);
    }
  };

  return (
    <div className="fixed inset-0 bg-black/10 bg-opacity-10 z-50 flex justify-center items-center h-screen flex-col">
      <div className="xxl:min-h-[656px] xl:min-h-[561px] lg:min-h-[540px] md:min-h-[540px] xxl:max-h-[656px] xl:max-h-[561px] lg:max-h-[540px] md:max-h-[540px] bg-black bg-opacity-85 flex flex-col items-center justify-center rounded-lg relative">
        <div className="flex justify-end items-center p-4 pb-0">
          <button
            type="button"
            className="absolute right-4 top-2"
            onClick={() => openDialpad(false)}
          >
            <X className="text-grey-600 w-6 h-6" />
          </button>
        </div>
        <div className=" xxl:w-96 xl:w-80 lg:w-80 md:w-80 mt-4">
          <div>
            <DialerUpperScreen
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              callSessions={callSessions}
              handleMakeCall={handleMakeCall}
              inputRef={inputRef}
              action={action}
              callActiveKey={callActiveKey}
            />
            {!isCallExist ? (
              <>
                <Digits
                  onPresshandler={appendPhoneNumber}
                  handleMakeCall={handleMakeCall}
                />
              </>
            ) : (
              <ActiveCallScreen
                action={action}
                setAction={setAction}
                activeCallData={activeCallData}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dialer;
