import React, { useState } from "react";
import Settings from "./Settings";
import Embedding from "./Embedding";

const OpenAi = () => {
  const [tabs, setTabs] = useState("settings");
  return (
    <div className="relative">
      <div className="flex gap-8 items-center py-2 ">
        <button
          className={`text-base font-medium ${
            tabs === "settings" ? "text-black" : "text-[#9B9DA4]"
          } hover:text-blue-700`}
          onClick={() => setTabs("settings")}
        >
          Settings
        </button>
        <button
          className={`text-base font-medium ${
            tabs === "embedding" ? "text-black" : "text-[#9B9DA4]"
          } hover:text-blue-700`}
          onClick={() => setTabs("embedding")}
        >
          Embeddings
        </button>
      </div>

      {tabs === "settings" ? <Settings /> : <Embedding />}
    </div>
  );
};

export default OpenAi;
