import React, { useEffect, useRef } from "react";
import { Header } from "components";
import { useSip, useUserDetails } from "hooks";
import { Outlet, useLocation } from "react-router-dom";
import { SipSocketContext } from "hooks/useSip/SipSocketContext";
import { io } from "socket.io-client";
import { useAuth } from "hooks/useAuth";

const splittedBaseUrl = process.env.REACT_APP_BASE_API_URL.split("/");
export const socket = io(`${splittedBaseUrl[0]}//${splittedBaseUrl[2]}`);

let zigiSocket = null;

export default function AuthLayout() {
  const SIP = useSip();
  const { pathname } = useLocation();
  const { user, setOnlineUsers, setCallData } = useAuth();
  useUserDetails(SIP);

  const emailRef = useRef(user?.email); // Declare ref at the top level

  // Update the ref whenever the user changes
  useEffect(() => {
    emailRef.current = user?.email;
  }, [user]);

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ video: false, audio: true })
      .then((tracks) => {
        tracks.getTracks().forEach((track) => track.stop());
      })
      .catch((err) => {
        console.log("Microphone access denied", err);
      });
  }, []);

  useEffect(() => {
    if (user?.uuid && !zigiSocket) {
      const splittedZigiBaseUrl =
        process.env.REACT_APP_ZIGI_BASE_API_URL.split("/");
      zigiSocket = io(`${splittedZigiBaseUrl[0]}//${splittedZigiBaseUrl[2]}`, {
        query: {
          companyUuid: user?.company_uuid,
          userId: user?.uuid,
        },
      });
    }
  }, [user]);

  useEffect(() => {
    // Emit when user connects
    if (user && user.email) {
      socket.emit("userConnected", user.email);
    }

    // Listen for updates to the online users list
    socket.on("updateOnlineUsers", (users) => {
      setOnlineUsers([...users]);
    });

    socket.on("updateUserStatus", (callData) => {
      setCallData({ ...callData });
    });

    // Clean up on component unmount
    return () => {
      // Use email from ref to ensure it's the latest value
      socket.emit("userOffCall", emailRef.current);
      socket.off("updateUserStatus");
      socket.off("updateOnlineUsers");
    };
  }, [user]);

  return (
    <SipSocketContext.Provider value={{ socket: SIP }}>
      {pathname?.includes("verify-email") ? null : <Header />}
      <Outlet />
    </SipSocketContext.Provider>
  );
}

export const getZigiSocket = () => {
  if (!zigiSocket) {
    throw new Error(
      "zigiSocket has not been initialized. Ensure user data is available first."
    );
  }
  return zigiSocket;
};
