import { BotsTree, Messenger, Omni } from "assets/images";
import { Button, Input, Select } from "components";
import React from "react";

const Store = () => {
  const filterByPayment = [
    { status: "All", uuid: "1" },
    { uuid: "2", status: "Free" },
    { uuid: "3", status: "Paid" },
  ];
  const filterByType = [
    { uuid: "1", type: "All" },
    { uuid: "2", type: "Agency" },
    { uuid: "3", type: "Arts" },
    { uuid: "4", type: "Auto" },
    { uuid: "5", type: "Customer Support" },
  ];

  return (
    <div className="mt-5">
      <div className="flex gap-4">
        <div className="w-[240px]">
          <Select
            placeholder={"All"}
            options={filterByPayment}
            renderOption={({ item }) => (
              <option value={item.uuid}>{item.status}</option>
            )}
          />
        </div>
        <div className="w-[240px]">
          <Select
            placeholder={"All"}
            options={filterByType}
            renderOption={({ item }) => (
              <option value={item.uuid}>{item.type}</option>
            )}
          />
        </div>
        <div className="w-[400px]">
          <Input withSearchIcon extraClasses="pl-9" />
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-6">
        {[...Array(4)].map((_, index) => (
          <div
            key={index}
            className="border border-[#9B9DA4] rounded-lg h-full w-full relative group transition-all"
          >
            {index > 0 && (
              <div className="absolute top-2 rounded-lg w-[50px] h-[40px] right-2 bg-[#024DE3] flex items-center justify-center text-white">
                <p>PRO</p>
              </div>
            )}
            <div className="h-[200px] flex items-center justify-center">
              <BotsTree />
            </div>
            <div className="p-3 flex flex-col gap-4 mt-4 border-t border-black/60 transition-all duration-300">
              <p>Free</p>
              <div className="flex gap-2 items-center">
                {index === 0 ? <Omni /> : <Messenger />}
                <p className="font-bold text-[#1F2124]">
                  {index === 0 && "Blank Template"}
                  {index === 1 && "Coffee Shop Template"}
                  {index === 2 && "Property Issue Template"}
                  {index === 3 && "Pizza Delivery Template"}
                </p>
              </div>
              <p className="text-[#474A4E] text-base">
                {index === 0 &&
                  "Start with a blank slate and unlimited possibilities."}
                {index === 1 &&
                  "This is a free template from Fonu. Phone Number setup needed."}
                {index === 2 &&
                  "This is a free template from Fonu. No integration needed."}
                {index === 3 &&
                  "This is a  free template from Fonu. Ecommerce System needed."}
              </p>
              <div className="flex flex-col gap-2">
                <Button>Install</Button>
                <button className="border text-black py-2 rounded-md">
                  See More
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Store;
