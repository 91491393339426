import React, { useEffect, useState } from "react";
import PricingItem from "./PricingItem";
import { getDIDListing, getPlans } from "api";
import { useQuery } from "@tanstack/react-query";
import { useAuth } from "hooks/useAuth";
import { Button, Modal, Skeletons, Spinner, Input } from "components";
import { dropdownActions, initialModalState } from "../constants";
import { ChangePlanModal } from "./ChangePlanModal";
import { ViewPlanModal } from "./ViewPlanModal";
import BuyPlanModal from "./BuyPlanModal";
import useGetRequestedPlan from "hooks/useGetRequestedPlan";
import {
  formatDateTime,
  NG_CURRENCY_SYMBOL,
  getRequiredText,
  alphaNumericRegx,
  ayt,
  getToken,
} from "shared/resources";
import useCancelRequestPlan from "hooks/useCancelRequestPlan";
import PurchaseNumberModal from "pages/PhoneNumbers/PurchaseNumberModal";
import { X } from "assets/images";
import {
  CardItem,
  PaymentMethodArray,
} from "pages/PhoneNumbers/PurchaseNumberModal/NumberDetail";
import { Controller, useForm } from "react-hook-form";
import useChangePlan from "hooks/useChangePlan";
import PaymentConfirmationModal from "components/PaymentConfirmationModal";
import useSavedCards from "hooks/useSavedCards";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { toastEmitter } from "components/Toast";
import { useTranslation } from "react-i18next";

const numberOfPlans = 3;

export const requestEnterprisePlanInitialValues = {
  company_name: "",
  contact_name: "",
  email: "",
  phone: "",
  no_of_users: null,
};
export const validationSchema = yup.object().shape({
  company_name: yup
    .string()
    .required(getRequiredText("Company name"))
    .matches(alphaNumericRegx, "Special characters are not allowed."),
  contact_name: yup
    .string()
    .required(getRequiredText("Contact name"))
    .matches(alphaNumericRegx, "Special characters are not allowed."),
  email: yup.string().required(getRequiredText("Email")),
  phone: yup
    .string()
    .min(6, getRequiredText("Phone"))
    .required(getRequiredText("Phone")),
  no_of_users: yup
    .number()
    .required("No of users is required")
    .positive("No of users must be a positive number")
    .integer("No of users must be an integer"),
});

const PlanLoader = () => {
  const { t } = useTranslation();
  const optionsItems = [
    {
      key: "cost",
      value: t("pnb.cost"),
      type: "value",
    },
    {
      key: "call_local",
      value: t("pnb.call_local"),
      type: "value",
    },
    {
      key: "messaging_local",
      value: t("pnb.messaging_local"),
      type: "value",
    },
    {
      key: "review_and_setup_free",
      value: t("pnb.review_and_setup_free"),
      type: "value",
    },
    {
      key: "phone_numbers_per_user",
      value: t("pnb.phone_numbers_per_user"),
      type: "value",
    },
    {
      key: "members_per_user",
      value: t("pnb.members_per_user"),
      type: "value",
    },
    {
      key: "additional_phone_number",
      value: t("pnb.additional_phone_number"),
      type: "value",
    },
    {
      key: "free_call_valid_for_one_month",
      value: t("pnb.free_call_valid_for_one_month"),
      type: "value",
    },
    {
      key: "offnet_call_rate_discount",
      value: t("pnb.offnet_call_rate_discount"),
      type: "value",
    },
    {
      key: "call_recording",
      value: t("pnb.call_recording"),
      type: "value",
    },
    {
      key: "mobile_app_feature_for_customer_service",
      value: t("pnb.mobile_app_feature_for_customer_service"),
      type: "value",
    },
    {
      key: "transfer_call_feature",
      value: t("pnb.transfer_call_feature"),
      type: "value",
    },
    {
      key: "voice_response_ivr",
      value: t("pnb.voice_response_ivr"),
      type: "value",
    },
    {
      key: "business_hour_settings",
      value: t("pnb.business_hour_settings"),
      type: "value",
    },
  ];
  return (
    <div className={`border border-grey-400 rounded overflow-hidden w-[30%]`}>
      <div className={` h-1`}></div>
      <div className={`h-32 p-3  border-b border-grey-400`}>
        <Skeletons />
      </div>
      <div>
        {Array.from({ length: optionsItems?.length - 1 }, (_, i) => i + 1).map(
          (val) => {
            return (
              <div
                key={val}
                className={`border-b border-grey-400 p-3 
            }`}
              >
                <Skeletons />
              </div>
            );
          }
        )}
      </div>
    </div>
  );
};

const Pnb = () => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const optionsItems = [
    {
      key: "cost",
      value: t("pnb.cost"),
      type: "value",
    },
    {
      key: "call_local",
      value: t("pnb.call_local"),
      type: "value",
    },
    {
      key: "messaging_local",
      value: t("pnb.messaging_local"),
      type: "value",
    },
    {
      key: "review_and_setup_free",
      value: t("pnb.review_and_setup_free"),
      type: "value",
    },
    {
      key: "phone_numbers_per_user",
      value: t("pnb.phone_numbers_per_user"),
      type: "value",
    },
    {
      key: "members_per_user",
      value: t("pnb.members_per_user"),
      type: "value",
    },
    {
      key: "additional_phone_number",
      value: t("pnb.additional_phone_number"),
      type: "value",
    },
    {
      key: "free_call_valid_for_one_month",
      value: t("pnb.free_call_valid_for_one_month"),
      type: "value",
    },
    {
      key: "offnet_call_rate_discount",
      value: t("pnb.offnet_call_rate_discount"),
      type: "value",
    },
    {
      key: "call_recording",
      value: t("pnb.call_recording"),
      type: "value",
    },
    {
      key: "mobile_app_feature_for_customer_service",
      value: t("pnb.mobile_app_feature_for_customer_service"),
      type: "value",
    },
    {
      key: "transfer_call_feature",
      value: t("pnb.transfer_call_feature"),
      type: "value",
    },
    {
      key: "voice_response_ivr",
      value: t("pnb.voice_response_ivr"),
      type: "value",
    },
    {
      key: "business_hour_settings",
      value: t("pnb.business_hour_settings"),
      type: "value",
    },
  ];

  const [showModal, setShowModal] = useState(initialModalState);
  const { data: requestedPlanData } = useGetRequestedPlan();
  const { mutate: cancelRequestedPlan, isPending } = useCancelRequestPlan();
  const myPlan = user?.get_company_details.plan_uuid;
  const [isMonthly, setIsMonthly] = useState(true);
  const [dids, setDids] = useState([]);
  const [isEnterprisePlanLoading, setIsEnterprisePlanLoading] = useState(false);
  const {
    isLoading: isLoadingData,
    data,
    isFetching,
  } = useQuery({
    queryFn: ({ queryKey }) => getPlans(queryKey[1] || {}),
    queryKey: [`getPlans`],
    select: (data) => data?.data?.data,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    retry: false,
    gcTime: Infinity,
  });

  const { control, handleSubmit, setValue, watch, getValues } = useForm({
    defaultValues: {
      payment_type: "NEW_CARD",
      is_saved_card: "N",
      card_uuid: "",
    },
    mode: "onChange",
  });

  const {
    control: buyEnterprisePlanControl,
    handleSubmit: submitEnterprisePlanRequest,
    formState: { errors },
    watch: watchEnterprisePlanForm,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: requestEnterprisePlanInitialValues,
    mode: "onChange",
  });

  const { isPending: isPlanPending, mutate: changePlanMutate } = useChangePlan({
    handleSuccess: () => {
      window.location.reload();
    },
  });

  const { cardListing, isCardLoading } = useSavedCards();

  useEffect(() => {
    if (
      cardListing &&
      cardListing?.length > 0 &&
      watch("payment_type") === "SAVED_CARD"
    ) {
      const defaultCard = cardListing?.find((item) => item?.is_default === "Y");
      setValue("card_uuid", defaultCard?.uuid || "");
    }
  }, [cardListing?.length, watch("payment_type")]);

  useEffect(() => {
    if (user?.get_company_details?.plan_duration === "YEARLY") {
      setIsMonthly(false);
    }
  }, [user]);

  useEffect(() => {
    if (!isMonthly && user?.get_company_details?.plan_duration !== "YEARLY") {
      setShowModal({
        isShow: true,
        modalType: dropdownActions.SWITCH_TO_YEARLY,
        type: "center",
      });
    }
  }, [isMonthly]);

  useEffect(() => {
    getDIDListing().then((response) => {
      setDids(response.data.data);
    });
  }, []);

  function handleChangePlan(planIndex) {
    setShowModal({
      isShow: true,
      modalType: dropdownActions.PURCHASE_NUMBER,
      type: "center",
      elementData: { planIndex },
    });
  }

  function handleEnterprisePlan() {
    setShowModal({
      isShow: true,
      modalType: dropdownActions.BUY_ENTERPRISE_PLAN,
      type: "center",
    });
  }

  function handleBuyPlan(planId, action) {
    setShowModal({
      isShow: true,
      modalType: dropdownActions.BUY_PLAN,
      elementData: planId,
      type: "center",
      plan_action: action || undefined,
    });
  }

  function handleViewPlan(data) {
    setShowModal({
      isShow: true,
      modalType: dropdownActions.VIEW_PLAN,
      type: "center",
      elementData: { ...data },
    });
  }

  function handleCloseModal() {
    setShowModal(initialModalState);
  }

  const ModalLookup = {
    [dropdownActions.CHANGE_PLAN]: (
      <ChangePlanModal showModal={showModal} handleClose={handleCloseModal} />
    ),
    [dropdownActions.VIEW_PLAN]: (
      <ViewPlanModal
        showModal={showModal}
        handleClose={handleCloseModal}
        allPlans={data}
      />
    ),
    [dropdownActions.BUY_PLAN]: (
      <BuyPlanModal
        showModal={showModal}
        handleClose={handleCloseModal}
        isMonthly={isMonthly}
      />
    ),
    [dropdownActions.PURCHASE_NUMBER]: (
      <PurchaseNumberModal
        showModal={showModal}
        handleClose={handleCloseModal}
        isMonthly={isMonthly}
      />
    ),
    [dropdownActions.SWITCH_TO_YEARLY]: (
      <div className="">
        <div className="flex justify-between items-start pt-4 px-4">
          <div className="flex flex-col gap-1">
            <div className="text-4xl font-semibold text-black">
              {dids[0]?.did_number}
            </div>
            <div className="text-sm  text-grey-700">{t("pnb.inst")}</div>
            <p style={{ marginTop: "1rem" }}>{t("pnb.pPlan")}</p>
          </div>
          <button type="button" onClick={() => setShowModal(initialModalState)}>
            <X className="text-grey-600 w-6 h-6" />
          </button>
        </div>
        <div
          style={{ margin: "20px 0" }}
          className="  px-4 pb-6 w-full xxl:max-h-full xl:max-h-full lg:max-h-full  md:overflow-auto md:max-h-56"
        >
          <div className="text-4xl font-semibold">
            {NG_CURRENCY_SYMBOL}
            {Number(user?.get_company_details?.features[0].value) * 12 +
              dids.reduce((prev, cur) => {
                prev += Number(cur?.monthly_cost) * 12;
                return prev;
              }, 0)}
            &nbsp;
            <span className="text-2xl text-grey-700 capitalize">
              {t("pnb.yearly")}
            </span>
          </div>
          <p style={{ marginTop: "1rem" }}>{t("pnb.yearlyInfo")}</p>
        </div>
        <div className="flex w-full gap-5 mt-8 px-4">
          {PaymentMethodArray.map((item) => {
            return (
              <label
                htmlFor={`payment_type_${item?.type}`}
                key={item?.type}
                className="flex items-center gap-1 font-medium text-grey-800 cursor-pointer"
              >
                <Controller
                  name={"payment_type"}
                  control={control}
                  render={({ field }) => {
                    return (
                      <input
                        className="checked:bg-green checked:hover:bg-green checked:active:bg-green checked:focus:bg-green focus:outline-none focus:ring-1 focus:ring-green"
                        type="radio"
                        {...field}
                        id={`payment_type_${item?.type}`}
                        value={item?.type}
                        checked={item?.type === watch("payment_type")}
                      />
                    );
                  }}
                />
                {item?.label}
              </label>
            );
          })}
        </div>
        {watch("payment_type") === "SAVED_CARD" ? (
          <div className="w-full px-4 mt-4 mb-3 gap-2 flex items-center max-w-[600px] overflow-auto flex-col max-h-28">
            {isCardLoading
              ? Array.from({ length: 3 }, (_, i) => i + 1).map((val) => {
                  return (
                    <div className="w-full" key={val}>
                      <Skeletons height="h-14" />
                    </div>
                  );
                })
              : cardListing?.length > 0
              ? cardListing?.map((card) => (
                  <CardItem
                    key={card?.uuid}
                    data={card}
                    setSelectedCard={(val) => setValue("card_uuid", val)}
                    selectedCard={watch("card_uuid")}
                  />
                ))
              : t("overview.noSavedCards")}
          </div>
        ) : null}
        {watch("payment_type") === "NEW_CARD" ? (
          <div className="w-full px-4 mt-4 mb-10 gap-2 flex items-center">
            <input
              className="checked:bg-green checked:hover:bg-green checked:active:bg-green checked:focus:bg-green focus:outline-none focus:ring-1 focus:ring-green"
              type="checkbox"
              id="is_saved_card"
              checked={watch("is_saved_card") === "Y"}
              onChange={(e) => {
                setValue("is_saved_card", e.target.checked ? "Y" : "N");
              }}
            />
            <label className="cursor-pointer" htmlFor="is_saved_card">
              {t("overview.futureQuestion")}
            </label>
          </div>
        ) : null}
        <div className="mt-4  flex items-center justify-end gap-2 px-4 pb-4">
          <Button
            width="w-[191px]"
            type="button"
            onClick={() => {
              if (watch("payment_type") === "NEW_CARD") {
                setShowModal({
                  modalType: "PLAN_PURCHASE_CONFIRMATION",
                  isShow: true,
                  elementData: {},
                  type: "center",
                });
              } else {
                const values = getValues();
                changePlanMutate({
                  ...values,
                  plan_uuid: user?.get_company_details?.plan_uuid,
                  payment_type: values?.payment_type,
                  is_saved_card: values?.is_saved_card,
                  plan_action: showModal?.plan_action ?? undefined,
                  plan_type: "YEARLY",
                  source: values?.source,
                });
              }
            }}
          >
            <div className="flex w-fit px-5   justify-center items-center">
              {isPlanPending ? <Spinner /> : t("pnb.payNow")}
            </div>
          </Button>
        </div>
      </div>
    ),
    PLAN_PURCHASE_CONFIRMATION: (
      <PaymentConfirmationModal
        handleClose={() => {
          setShowModal({
            isShow: true,
            modalType: "PLAN_RENEWAL",
            type: "center",
          });
        }}
        continueCallBack={handleSubmit((values) => {
          changePlanMutate({
            plan_uuid: user?.get_company_details?.plan_uuid,
            payment_type: values?.payment_type,
            is_saved_card: values?.is_saved_card,
            plan_action: showModal?.plan_action ?? undefined,
            plan_type: "YEARLY",
            source: values?.source,
          });
        })}
        setValue={setValue}
      />
    ),
    [dropdownActions.BUY_ENTERPRISE_PLAN]: (
      <div className="min-w-[500px]">
        <div className="flex justify-between items-start py-4 px-6">
          <div className="flex flex-col gap-1">
            <div className="text-xl font-semibold text-black">
              {t("pnb.customQuote")}
            </div>
            <div className="text-sm  text-grey-700">
              {t("pnb.enterprisePlan")}
            </div>
            <div
              className="text-sm  text-grey-700"
              style={{ marginTop: "1rem" }}
            >
              {t("pnb.fillOut")}
            </div>
          </div>
          <button type="button" onClick={handleCloseModal}>
            <X className="text-grey-600 w-6 h-6" />
          </button>
        </div>
        <div className=" pb-5 pt-6 ">
          <form className="overflow-auto px-6 ">
            <div className="flex flex-col gap-4">
              <div>
                <Controller
                  name="company_name"
                  control={buyEnterprisePlanControl}
                  render={({ field }) => (
                    <Input
                      {...field}
                      onWhitebg={true}
                      label={t("pnb.companyName")}
                      error={errors?.company_name?.message}
                      maxLength={50}
                      required={true}
                    />
                  )}
                />
              </div>
              <div>
                <Controller
                  name="contact_name"
                  control={buyEnterprisePlanControl}
                  render={({ field }) => (
                    <Input
                      {...field}
                      onWhitebg={true}
                      label={t("pnb.contactName")}
                      error={errors?.contact_name?.message}
                      maxLength={50}
                      required
                    />
                  )}
                />
              </div>
              <div>
                <Controller
                  name="email"
                  control={buyEnterprisePlanControl}
                  render={({ field }) => (
                    <Input
                      {...field}
                      onWhitebg={true}
                      label={t("overview.email")}
                      error={errors?.email?.message}
                      maxLength={50}
                      required
                    />
                  )}
                />
              </div>
              <div>
                <Controller
                  name="phone"
                  control={buyEnterprisePlanControl}
                  render={({ field }) => {
                    const { onChange } = field;
                    return (
                      <Input
                        {...field}
                        onWhitebg={true}
                        label={t("overview.phone")}
                        error={errors?.phone?.message}
                        autoComplete="off"
                        maxLength="17"
                        onChange={(e) => {
                          const aytNumber = ayt(e.target.value);
                          onChange(aytNumber);
                        }}
                        required={true}
                      />
                    );
                  }}
                />
              </div>
              <div style={{ marginBottom: "1rem" }}>
                <Controller
                  name="no_of_users"
                  control={buyEnterprisePlanControl}
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="number"
                      onWhitebg={true}
                      label={t("pnb.noOfUsers")}
                      error={errors?.no_of_users?.message}
                      required
                    />
                  )}
                />
              </div>
            </div>
          </form>
          <div className="px-6 mt-4">
            <Button
              disabled={
                isEnterprisePlanLoading ||
                !watchEnterprisePlanForm("company_name") ||
                !watchEnterprisePlanForm("contact_name") ||
                !watchEnterprisePlanForm("email") ||
                !watchEnterprisePlanForm("phone") ||
                !watchEnterprisePlanForm("no_of_users")
              }
              type="button"
              onClick={(event) => {
                event.preventDefault();
                setIsEnterprisePlanLoading(true);
                submitEnterprisePlanRequest(async (values) => {
                  const response = await axios.post(
                    `${process.env.REACT_APP_BASE_API_URL}plans/request-enterprise-plan`,
                    {
                      company_name: values.company_name,
                      contact_name: values.contact_name,
                      email: values.email,
                      phone: values.phone,
                      no_of_users: values.no_of_users,
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${getToken()}`,
                        Accept: "application/json",
                        "Content-Type": "application/json; charset=utf-8",
                      },
                    }
                  );
                  setIsEnterprisePlanLoading(false);
                  if (response.status === 200) {
                    handleCloseModal();
                    toastEmitter(
                      "success",
                      "Enterprise plan requested successfully"
                    );
                  }
                })(event);
              }}
            >
              {isEnterprisePlanLoading ? <Spinner /> : t("overview.submit")}
            </Button>
          </div>
        </div>
      </div>
    ),
  };

  const isLoading = isLoadingData || isFetching;

  const { isShow, modalType } = showModal;

  const oldPlan =
    data?.find(
      (plan) => plan?.uuid === requestedPlanData?.data?.data?.old_plan_uuid
    ) ?? {};

  const newPlan =
    data?.find(
      (plan) => plan?.uuid === requestedPlanData?.data?.data?.plan_uuid
    ) ?? {};

  return (
    <>
      <div className="px-6 py-6">
        {requestedPlanData?.data?.data ? (
          <div className="border px-5 py-4 rounded-md bg-green-50 border-green-100 flex items-center justify-between mb-6">
            <div>
              <div className="font-semibold	text-base text-green">
                {t("pnb.change")}
              </div>
              <div className="font-medium mt-1 text-base text-green">
                {t("pnb.yourRequest")} <b>{oldPlan?.plan_name}</b> to{" "}
                <b>{newPlan?.plan_name}</b> on{" "}
                {formatDateTime(requestedPlanData?.data?.data?.created_at)?.[0]}
              </div>
            </div>
            <div className="w-40">
              <Button
                height="h-[50px]"
                extraClasses=" px-5"
                onClick={() =>
                  cancelRequestedPlan({
                    uuid: requestedPlanData?.data?.data?.uuid,
                  })
                }
              >
                {isPending ? <Spinner /> : t("pnb.cancelRequest")}
              </Button>
            </div>
          </div>
        ) : null}
        <div className="flex items-center justify-between mb-5">
          <div className="text-sm text-grey-700">{t("pnb.managePlan")}</div>
          <div className="rounded-full border-green-400 border-2 p-4 px-6 flex gap-5">
            <button
              onClick={() => {
                setIsMonthly(true);
              }}
              className={
                isMonthly
                  ? "bg-green-400 p-2 rounded-full"
                  : "bg-none text-[12px]"
              }
            >
              {t("pnb.monthly")}
            </button>
            <button
              onClick={() => {
                setIsMonthly(false);
              }}
              className={
                !isMonthly
                  ? "bg-green-400 p-2 rounded-full"
                  : "bg-none text-[12px]"
              }
            >
              {t("pnb.yearlyS")}
            </button>
          </div>
        </div>
        <div className="flex gap-7">
          <PricingItem
            title="Options"
            itemsArr={optionsItems}
            width="w-2/5"
            headerBg="bg-green-100"
            withActionBtn={false}
            textCenter={false}
            isLoading={isLoading}
            data={data}
            isMonthly={isMonthly}
          />

          {isLoading ? (
            <>
              {Array.from({ length: numberOfPlans }, (_, i) => i + 1).map(
                (val) => {
                  return <PlanLoader key={val} />;
                }
              )}
            </>
          ) : data && data.length > 0 ? (
            <>
              {data?.map((plan, idx) => {
                const isCurrentPlan = plan?.uuid === myPlan;
                return (
                  <PricingItem
                    isActivePlan={isCurrentPlan}
                    title={plan?.plan_name}
                    key={plan?.uuid}
                    itemsArr={plan?.features}
                    isLoading={isLoading}
                    data={data}
                    highiLight={isCurrentPlan ? "bg-green" : "bg-black-600"}
                    bg={isCurrentPlan ? "bg-green" : "bg-white"}
                    headerBg={isCurrentPlan ? "bg-green" : "bg-white"}
                    planId={plan?.uuid}
                    handleChangePlan={handleChangePlan}
                    handleViewPlan={handleViewPlan}
                    handleBuyPlan={handleBuyPlan}
                    handleEnterprisePlan={handleEnterprisePlan}
                    action={plan?.action}
                    isMonthly={isMonthly}
                    index={idx}
                  />
                );
              })}
            </>
          ) : null}
        </div>
      </div>

      {isShow ? (
        <Modal
          handleClose={handleCloseModal}
          headerComponent={null}
          footerComponent={null}
          shouldCloseOnClickOutside={false}
        >
          {ModalLookup[modalType]}
        </Modal>
      ) : null}
    </>
  );
};

export default Pnb;
