import { Button, Spinner } from "components";
import { toastEmitter } from "components/Toast";
import { useAuth } from "hooks/useAuth";
import useChangePlan from "hooks/useChangePlan";
import React from "react";
import { useGetCompanyDetails } from "hooks";
import { useTranslation } from "react-i18next";

const Item = ({ item, bg, textCenter, isMonthly = true }) => {
  const { t } = useTranslation();
  const ifTypeBoolean = item?.type === "boolean";

  const value =
    item?.key === "cost" && !!parseInt(item?.value)
      ? isMonthly
        ? item?.value
        : Number(item?.value) * 12
      : item?.value;

  let valueName;
  switch (value) {
    case "Contact Sales":
      valueName = t("overview.contactSales");
      break;
    case "Free":
      valueName = t("pnb.free");
      break;
    default:
      if (value) {
        if (typeof value === "string" && value.includes("Minutes")) {
          let valueSplit = value.split(" ");
          valueName = valueSplit[0] + " " + t("overview.minutes");
        } else if (typeof value === "string" && value.includes("/User/Month")) {
          let valueSplit = value.split("/");
          valueName = valueSplit[0] + "" + t("pnb.users");
        } else {
          valueName =
            value === "32767" || value === "5"
              ? t("overview.unlimited")
              : value;
        }
      } else {
        valueName = <>&nbsp;</>;
      }

      break;
  }

  if (ifTypeBoolean) {
    return (
      <div
        className={`border-b border-grey-400 text-sm p-3 flex  ${
          textCenter ? "justify-center" : "justify-start"
        } `}
      >
        {item?.value ? (
          <span
            className={`w-4 ${
              bg === "bg-green" ? "text-white" : "text-grey-900"
            }`}
          >
            {t("overview.yes")}
          </span>
        ) : (
          <>&nbsp;</>
        )}
      </div>
    );
  }

  return (
    <div
      className={`border-b border-grey-400 text-sm p-3 flex  ${
        textCenter ? "justify-center" : "justify-start"
      } `}
    >
      <span className={`${bg === "bg-green" ? "text-white" : "text-grey-900"}`}>
        {valueName}
      </span>
    </div>
  );
};

const PricingItem = ({
  isActivePlan = false,
  width = "w-[30%]",
  title = "Basic",
  headerBg = "bg-white",
  bg = "bg-white",
  highiLight = "bg-black-600",
  itemsArr = [],
  textCenter = true,
  withActionBtn = true,
  planId,
  isMonthly = true,
  handleViewPlan = () => {},
  handleBuyPlan = () => {},
  action = "",
  index,
  handleChangePlan = () => {},
  handleEnterprisePlan = () => {},
}) => {
  const { t } = useTranslation();
  const { isPending } = useChangePlan();
  const { user } = useAuth();
  const companyDetails = useGetCompanyDetails();
  let titleName;

  switch (title) {
    case "Starter":
      titleName = t("pnb.starter");
      break;
    case "Business":
      titleName = t("pnb.business");
      break;
    case "Enterprise":
      titleName = t("pnb.enterprise");
      break;
    case "Options":
      titleName = t("overview.options");
      break;
    default:
      break;
  }

  return (
    <div className={`border border-grey-400 rounded ${width} overflow-hidden`}>
      <div className={`${highiLight} h-1`}></div>
      <div
        className={`h-32 ${headerBg} ${
          bg === "bg-green" ? "text-white" : "text-grey-900"
        }  text-2xl flex items-center justify-center border-b border-grey-400`}
      >
        {titleName}
      </div>
      <div className={`${bg}`}>
        {itemsArr?.map((item) => (
          <Item
            isMonthly={isMonthly}
            item={item}
            key={item?.key}
            bg={bg}
            textCenter={textCenter}
          />
        ))}
      </div>
      <div className={`${bg}  h-16 flex items-center justify-center`}>
        {withActionBtn ? (
          user?.get_company_details?.remaining_days <=
            user?.get_settings?.plan_reminder_days &&
          user?.get_company_details?.plan_type === "PURCHASED" ? (
            <Button
              onClick={() => {
                title === "Enterprise"
                  ? handleEnterprisePlan()
                  : action === "Downgrade"
                  ? handleViewPlan({
                      currentPlan: user?.get_company_details.plan_uuid,
                      newPlan: planId,
                    })
                  : companyDetails.data?.plan_type === "TRIAL"
                  ? handleChangePlan(index)
                  : handleBuyPlan(planId, t("overview.renew"));
              }}
              disabled={isPending}
              type="button"
              height="h-3/4"
              width="w-1/2"
              background={`${bg === "bg-green" ? "white" : "green"}`}
              color={`${bg === "bg-green" ? "text-green" : "text-white"}`}
            >
              {isPending ? (
                <Spinner />
              ) : title?.toLowerCase() === "enterprise" ? (
                t("overview.contactSales")
              ) : isActivePlan ? (
                t("overview.renew")
              ) : (
                t("overview.buy")
              )}
            </Button>
          ) : (
            <Button
              onClick={() => {
                if (title === "Enterprise") {
                  handleEnterprisePlan();
                } else {
                  if (
                    isActivePlan &&
                    user?.get_company_details?.plan_type !== "TRIAL"
                  ) {
                    return toastEmitter("success", t("overview.alreadyOnPlan"));
                  }

                  action === "Downgrade"
                    ? handleViewPlan({
                        currentPlan: user?.get_company_details.plan_uuid,
                        newPlan: planId,
                      })
                    : companyDetails.data?.plan_type === "TRIAL"
                    ? handleChangePlan(index)
                    : handleBuyPlan(planId, t("overview.buy"));
                }
              }}
              disabled={isPending}
              type="button"
              height="h-3/4"
              width="w-1/2"
              background={`${bg === "bg-green" ? "white" : "green"}`}
              color={`${bg === "bg-green" ? "text-green" : "text-white"}`}
            >
              {isPending ? (
                <Spinner />
              ) : isActivePlan &&
                user?.get_company_details?.plan_type !== "TRIAL" ? (
                t("overview.currentPlan")
              ) : title?.toLowerCase() === "enterprise" ? (
                t("overview.contactSales")
              ) : (
                t("overview.buy")
              )}
            </Button>
          )
        ) : null}
      </div>
    </div>
  );
};

export default PricingItem;
