import SwitchInput from "components/SwitchInput";
import React from "react";
import { Controller } from "react-hook-form";
import BusinessHourForm from "./BusinessHourForm";
import { CustomAudioPlayer, CustomSelectServer, Skeletons } from "components";
import { MusicNote } from "assets/images";
import { greetingList } from "api";
import { useTranslation } from "react-i18next";

const loader = () => (
  <div className="w-9 h-9 rounded-full">
    <Skeletons type="circle" />
  </div>
);

const GreetingItem = ({ item }) => {
  return (
    <div
      key={item.filename}
      value={item?.filename}
      className="w-full h-full flex  justify-start gap-2 items-center py-2 px-3"
    >
      {item?.name}
    </div>
  );
};
const PrivacyControlModal = ({
  control,
  errors,
  watch,
  setValue,
  setVoicemailValue,
}) => {
  const isBusinessHourEnabled = watch("enable_business_hour");
  const { t } = useTranslation();
  return (
    <>
      <div className="flex flex-col gap-1">
        <div className="text-grey-900 text-base font-semibold">
          {t("overview.setRingtone")}
        </div>
        <div className="text-grey-700 text-sm font-medium">
          {t("overview.voiceInfo")}
        </div>
      </div>
      <div className="mt-5 flex gap-1 items-center">
        <Controller
          name="ringtone_virtual_number"
          control={control}
          render={({ field }) => (
            <CustomSelectServer
              {...field}
              height="xxl:h-14 xl:h-12"
              onWhitebg={true}
              placeholder="Set ringtone for Fonu virtual number"
              error={errors?.ringtone_virtual_number?.message}
              ItemComponent={GreetingItem}
              labelKey={"name"}
              valueKey={"filename"}
              actionFn={greetingList}
              queryKey={"greetingListRingtone"}
              CaretComponent={MusicNote}
              extraParams={{
                action_type: "R",
              }}
            />
          )}
        />
        <CustomAudioPlayer
          loadingItem={loader}
          minimal={true}
          srcUrl={`${process.env.REACT_APP_BASE_API_URL}ringtones/${watch(
            "ringtone_virtual_number"
          )}`}
        />
      </div>

      <div className="flex justify-between mt-7 ">
        <div className="text-grey-900 text-base font-semibold">
          {isBusinessHourEnabled
            ? t("overview.disableBusHours")
            : t("overview.enableBusHours")}
        </div>
        <div>
          <Controller
            name={"enable_business_hour"}
            control={control}
            render={({ field }) => (
              <SwitchInput {...field} name="enable_business_hour" label={""} />
            )}
          />
        </div>
      </div>
      <div className="text-grey-700 text-sm font-medium mt-1">
        {isBusinessHourEnabled
          ? t("overview.setToReset")
          : t("overview.setCustomSchedule")}
      </div>

      {isBusinessHourEnabled && (
        <div>
          <BusinessHourForm
            control={control}
            watch={watch}
            errors={errors}
            setValue={setValue}
            setVoicemailValue={setVoicemailValue}
          />
        </div>
      )}
    </>
  );
};

export default PrivacyControlModal;
