/* eslint-disable no-unused-vars */
import { Trash, WhatsAppLogo, ZigiIcon } from "assets/images";
import { Avatar, EllipsisTextWithTooltip } from "components";
import { useChat } from "hooks/useChat";
import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";

const selectedNameClasses = {
  true: "font-semibold text-sm tracking-[0.41px]",
  false: "font-medium text-sm text-grey-800 tracking-[0.41px]",
};
// const selectedDescriptionClasses = {
//   true: "font-semibold text-xs tracking-[0.41px]",
//   false: "font-medium text-grey-700 text-xs tracking-[0.41px]",
// };

const InboxListItem = ({
  item,
  isZigiBot = false,
  filteredConversations = [],
  setFilteredConversations = null,
}) => {
  // const [isSelected, setIsSelected] = useState(false);

  // const handleClassName = (active) => {
  //   setIsSelected(active);
  // };

  const { messages } = useChat();

  useEffect(() => {
    if (filteredConversations.length) {
      const clonedConversations = [...filteredConversations];
      const conversationIdx = clonedConversations.findIndex(
        (conv) => conv.uuid === item.uuid
      );
      let lastIndex = -1;
      for (let i = messages.length - 1; i >= 0; i--) {
        if (
          messages[i].conversation_uuid &&
          messages[i].conversation_uuid === item.uuid
        ) {
          lastIndex = i;
          break;
        }
      }
      if (lastIndex >= 0) {
        clonedConversations[conversationIdx].metadata.lastMessage =
          messages[lastIndex].metadata.body;
        setFilteredConversations(clonedConversations);
      }
    }
  }, [messages]);

  return (
    <NavLink
      to={`${
        isZigiBot
          ? item.role === "responder"
            ? `/live-chat/${item.uuid}`
            : `/live-chat/zigi`
          : `/live-chat/${item}`
      }`}
      className={({ isActive }) =>
        `p-4 flex justify-between items-center cursor-pointer hover:bg-grey-300 ${
          isActive ? "bg-grey-300" : ""
        }`
      }
    >
      <div className="flex gap-3">
        <span className="flex items-start relative">
          {isZigiBot ? (
            item.role === "responder" ? (
              <Avatar name={`${item.displayName}`} />
            ) : (
              <ZigiIcon />
            )
          ) : (
            <Avatar name={`${item} Deanna Curtis`} />
          )}
          {isZigiBot ? null : (
            <div className="absolute -bottom-1 right-0">
              <WhatsAppLogo className="w-[22px] h-[22px]" />
            </div>
          )}
        </span>

        <span className="flex items-start flex-col gap-1">
          <span className={selectedNameClasses[isZigiBot ? "true" : "false"]}>
            {isZigiBot
              ? item.role === "responder"
                ? item.displayName
                : "Zigi Bot"
              : "Deanna Curtis"}
          </span>
          <span className={selectedNameClasses[isZigiBot ? "true" : "false"]}>
            <EllipsisTextWithTooltip
              withTooltip={false}
              charLength={30}
              string={
                isZigiBot
                  ? item.role === "responder"
                    ? `${item.metadata?.lastMessage || "No message yet"}`
                    : "How can I assist you today?"
                  : "Please I would like an update on this as soon as possible"
              }
            />
          </span>
        </span>
      </div>

      {!isZigiBot && (
        <span className="flex items-end flex-col gap-1">
          <span className="text-grey-600 font-medium text-xs">9:41 PM</span>
          <div className="flex justify-center items-center gap-2.5">
            <div className="flex justify-center items-center min-w-[18px] min-h-[18px] rounded-full bg-green text-white text-[11px] font-medium px-[6px]  tracking-[0.7px]">
              100
            </div>
            {/* <span className="bg-green text-white text-xs font-medium me-2 px-2.5 py-0.5 rounded-full ">
              1
            </span> */}
            <span>
              <Trash className="text-danger" />
            </span>
          </div>
        </span>
      )}
    </NavLink>
  );
};

export default InboxListItem;
