import { Button, Input } from "components";
import SwitchInput from "components/SwitchInput";
import { toastEmitter } from "components/Toast";
import { CircleAlert, Copy, TriangleAlert } from "lucide-react";
import React, { useEffect, useRef, useState } from "react";
import { generateApiKey, getToken } from "shared/resources";
import axios from "axios";
import { useAuth } from "hooks/useAuth";
import { Loader2 } from "lucide-react";

function ApiKeys() {
  const livePublicKeyRef = useRef();
  const testPublicKeyRef = useRef();

  const { user } = useAuth();
  const webhookSetting = user?.get_company_details.features.find(
    (f) => f.key === "webhook_enabled"
  );
  const [isSwitchOn, setIsSwitchOn] = useState(
    webhookSetting ? webhookSetting?.value : false
  );
  const [liveChangesMade, setLiveChangesMade] = useState(false);
  const [testChangesMade, setTestChangesMade] = useState(false);
  const handleCopy = (ref) => {
    if (ref && ref.current) {
      navigator.clipboard
        .writeText(ref.current.value)
        .then(() => {
          toastEmitter("success", "Copied to clipboard!");
        })
        .catch(() => {
          toastEmitter("error", "Failed to copy!");
        });
    }
  };
  const handleTestCopy = (ref) => {
    if (ref && ref.current) {
      navigator.clipboard
        .writeText(ref.current.value)
        .then(() => {
          toastEmitter("success", "Copied to clipboard!");
        })
        .catch(() => {
          toastEmitter("error", "Failed to copy!");
        });
    }
  };

  useEffect(() => {
    fetchConfiguration();
  }, []);

  const [loading, setLoading] = useState(true);

  const fetchConfiguration = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}webhook/get-configuration`,
        {},
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (response.data.data?.length) {
        const liveConfiguration = response.data.data.find(
          (d) => d.environment === "LIVE"
        );
        const testConfiguration = response.data.data.find(
          (d) => d.environment === "TEST"
        );
        if (liveConfiguration) {
          setLivePublicKey(liveConfiguration?.public_key);
          setLiveSecretKey(liveConfiguration?.secret_key);
          setLiveWebhookUrl(liveConfiguration?.webhook_url);
        }
        if (testConfiguration) {
          setTestPublicKey(testConfiguration?.public_key);
          setTestSecretKey(testConfiguration?.secret_key);
          setTestWebhookUrl(testConfiguration?.webhook_url);
        }
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
        return (window.location.href = "/");
      } else {
        toastEmitter("error", error?.response?.data?.error?.message);
      }
    }
  };

  const [liveSecretKey, setLiveSecretKey] = useState(
    `fonu_sk_${generateApiKey(48)}`
  );
  const [livePublicKey, setLivePublicKey] = useState(
    `fonu_pk_${generateApiKey()}`
  );
  const [liveWebhookUrl, setLiveWebhookUrl] = useState("");
  const [testSecretKey, setTestSecretKey] = useState(
    `fonu_test_sk_${generateApiKey(48)}`
  );
  const [testPublicKey, setTestPublicKey] = useState(
    `fonu_test_pk_${generateApiKey()}`
  );
  const [testWebhookUrl, setTestWebhookUrl] = useState("");

  const handleLiveSecretKeyChange = () => {
    setLiveSecretKey(`fonu_sk_${generateApiKey(48)}`);
    setLiveChangesMade(true);
  };

  const handleLiveWebhookChange = (value) => {
    setLiveWebhookUrl(value);
    setLiveChangesMade(true);
  };

  const handleTestWebhookChange = (value) => {
    setTestWebhookUrl(value);
    setTestChangesMade(true);
  };

  const saveLiveConfiguration = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}webhook/save-configuration`,
        {
          public_key: livePublicKey,
          secret_key: liveSecretKey,
          webhook_url: liveWebhookUrl,
          environment: "LIVE",
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      toastEmitter("success", `Live configuration saved successfully`);
      setLiveChangesMade(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
        return (window.location.href = "/");
      } else {
        toastEmitter("error", error?.response?.data?.error?.message);
      }
    }
  };

  const saveTestConfiguration = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}webhook/save-configuration`,
        {
          public_key: testPublicKey,
          secret_key: testSecretKey,
          webhook_url: testWebhookUrl,
          environment: "TEST",
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      toastEmitter("success", `Test configuration saved successfully`);
      setTestChangesMade(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
        return (window.location.href = "/");
      } else {
        toastEmitter("error", error?.response?.data?.error?.message);
      }
    }
  };

  const toggleWebhook = async (webhookEnabled) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}webhook/toggle`,
        {
          webhookEnabled,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
        return (window.location.href = "/");
      } else {
        toastEmitter("error", error?.response?.data?.error?.message);
      }
    }
  };

  const handleSwitchChange = () => {
    setIsSwitchOn(!isSwitchOn);
    toggleWebhook(!isSwitchOn);
  };

  return loading ? (
    <div className="h-screen flex justify-center items-center">
      <Loader2 className="animate-spin" />
    </div>
  ) : (
    <div className="md:w-[1000px] items-center justify-center w-full gap-6 m-auto flex flex-col">
      <div className="shadow-md h-[350px] px-10 w-full flex flex-col justify-center space-y-4 rounded-md border border-grey-400 relative">
        <div className="absolute left-4 top-4">
          <SwitchInput
            name="liveIntegration"
            value={isSwitchOn}
            onChange={handleSwitchChange}
          />
        </div>
        <p className="text-center py-4">API Configuration - Live Mode</p>
        <div className="flex items-center gap-4">
          <p className="w-[200px] text-[14px] font-semibold">Live Secret Key</p>
          <div className="w-full">
            <Input
              disabled={!isSwitchOn}
              type="password"
              value={liveSecretKey}
            />
            <p className="text-[12px] text-blue-light pt-1 items-center cursor-pointer gap-2 flex">
              <span onClick={handleLiveSecretKeyChange}>
                Generate new secret key
              </span>{" "}
              <CircleAlert color="#3333ff" size={14} />
            </p>
          </div>
        </div>
        <div className="flex items-center gap-4">
          <p className="w-[200px] text-[14px] font-semibold">Live Public Key</p>
          <div className="w-full relative">
            <Input
              disabled={!isSwitchOn}
              type="text"
              ref={livePublicKeyRef}
              value={livePublicKey}
            />
            <div
              className="absolute top-[25%] right-3 cursor-pointer"
              onClick={() => handleCopy(livePublicKeyRef)}
            >
              <Copy />
            </div>
          </div>
        </div>
        <div className="flex items-center gap-4">
          <p className="w-[200px] text-[14px] font-semibold">
            Live Webhook URL
          </p>
          <div className="w-full">
            <Input
              disabled={!isSwitchOn}
              type="text"
              placeholder="https://example.com"
              onChange={handleLiveWebhookChange}
            />
          </div>
        </div>
        <div className="w-[200px] ml-auto">
          <Button
            disabled={!isSwitchOn || !liveChangesMade}
            onClick={saveLiveConfiguration}
          >
            Save Changes
          </Button>
        </div>
      </div>

      <div className="shadow-md h-[380px] px-10 w-full flex flex-col justify-center space-y-4 rounded-md border border-grey-400 relative">
        <p className="text-center py-4">API Configuration - Test Mode</p>
        <div className="h-[40px] w-full bg-danger/30 border border-danger rounded-md px-4 flex gap-3 items-center text-sm text-danger">
          <TriangleAlert color="red" />
          <p>
            These keys are for testing only. Please DO NOT use in production.
          </p>
        </div>
        <div className="flex items-center gap-4">
          <p className="w-[200px] text-[14px] font-semibold">Test Secret Key</p>
          <div className="w-full">
            <Input
              disabled={!isSwitchOn}
              type="password"
              value={testSecretKey}
            />
          </div>
        </div>
        <div className="flex items-center gap-4">
          <p className="w-[200px] text-[14px] font-semibold">Test Public Key</p>
          <div className="w-full relative">
            <Input
              disabled={!isSwitchOn}
              type="text"
              ref={testPublicKeyRef}
              value={testPublicKey}
            />
            <div
              className="absolute top-[25%] right-3 cursor-pointer"
              onClick={() => handleTestCopy(testPublicKeyRef)}
            >
              <Copy />
            </div>
          </div>
        </div>
        <div className="flex items-center gap-4">
          <p className="w-[200px] text-[14px] font-semibold">
            Test Webhook URL
          </p>
          <div className="w-full">
            <Input
              disabled={!isSwitchOn}
              type="text"
              placeholder="https://example.com"
              onChange={handleTestWebhookChange}
            />
          </div>
        </div>
        <div className="w-[200px] ml-auto">
          <Button
            disabled={!isSwitchOn || !testChangesMade}
            onClick={saveTestConfiguration}
          >
            Save Changes
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ApiKeys;
