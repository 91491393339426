import { Arrow, X } from "assets/images";
import { Button, Spinner, StaticPhoneSelect } from "components";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import ForwardToInput from "./ForwardToInput";
import CustomSelect from "components/CustomSelect";
import SwitchInput from "components/SwitchInput";
import { useSetForwarding } from "hooks";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { IPRegex, getRequiredText } from "shared/resources";
import { useTranslation } from "react-i18next";

const initialValues = {
  uuid: "",
  forward_call_actions: {
    connect_as_web_call: "Y",
    during_business_hour: {
      type: "EXTENSION",
      value: "",
      timeout: 30,
    },
    failover: { type: "VOICEMAIL", value: "VOICEMAIL", timeout: 3 },
  },
};
const TimeoutItem = ({ item }) => {
  return (
    <div
      key={item.value}
      value={item?.value}
      className="w-full h-full flex  justify-start gap-2 items-center py-2 px-3"
    >
      {item?.label}
    </div>
  );
};

const ForwardToItem = ({ item }) => {
  return (
    <div
      key={item.value}
      value={item?.value}
      className="w-full h-full flex  justify-start gap-2 items-center py-2 px-3"
    >
      {item?.label}
    </div>
  );
};

const validationSchema = yup.object({
  forward_call_actions: yup.object({
    during_business_hour: yup.object({
      type: yup.string().required(getRequiredText("This field")),
      value: yup
        .string()
        .when("type", {
          is: (val) => !["HANGUP", "VOICEMAIL"].includes(val),
          then: yup.string().required(getRequiredText("This field")),
        })
        .when("type", {
          is: (val) => ["IP"].includes(val),
          then: yup
            .string()
            .required(getRequiredText("This field"))
            .matches(IPRegex, "Invalid IP Address"),
        }),
    }),
  }),
});

const SetForwardingModal = ({ showModal, handleClose = () => null }) => {
  const itemElement = showModal?.elementData;
  const { t } = useTranslation();
  const handleSuccess = () => {
    handleClose();
  };
  const { mutate: setForwardingMutate, isPending: setForwardingLoad } =
    useSetForwarding({ handleSuccess });
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    clearErrors,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: "onChange",
  });

  useEffect(() => {
    if (itemElement) {
      setValue("uuid", itemElement?.uuid);
      if (itemElement?.forward_call_actions) {
        setValue(
          "forward_call_actions.connect_as_web_call",
          itemElement?.forward_call_actions?.connect_as_web_call === "Y"
        );

        setValue(
          "forward_call_actions.during_business_hour.type",
          itemElement?.forward_call_actions?.during_business_hour?.type
        );
        setValue(
          "forward_call_actions.during_business_hour.value",
          itemElement?.forward_call_actions?.during_business_hour?.value
        );
        setValue(
          "forward_call_actions.during_business_hour.timeout",
          itemElement?.forward_call_actions?.during_business_hour?.timeout
        );
      }
    }
  }, [itemElement]);

  const onSubmit = (values) => {
    const payload = {
      uuid: values?.uuid,
      forward_call_actions: {
        connect_as_web_call: values?.forward_call_actions?.connect_as_web_call
          ? "Y"
          : "N",
        during_business_hour: {
          ...values?.forward_call_actions?.during_business_hour,
          failover: ["TEAM", "EXTENSION"].includes(
            values?.forward_call_actions?.during_business_hour?.type
          )
            ? values?.forward_call_actions?.failover
            : undefined,
        },
      },
    };

    setForwardingMutate(payload);
  };

  const forwardTypeValue = watch(
    "forward_call_actions.during_business_hour.type"
  );

  return (
    <form
      className="min-w-[1050px]  px-6 pb-6 py-4"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex justify-between items-start">
        <div className="flex flex-col gap-1">
          <div className="text-xl font-semibold text-black">
            {t("overview.forwardPhoneNumber")}
          </div>
          <div className="text-sm  text-grey-700">
            {t("overview.forwardInfo")}
          </div>
        </div>
        <button type="button" onClick={() => handleClose()}>
          <X className="text-grey-600 w-6 h-6" />
        </button>
      </div>
      <div className="mt-6">
        <StaticPhoneSelect
          number={itemElement?.did_number}
          background="bg-grey-100"
        />
      </div>
      <div className="mt-6">
        <ForwardToInput
          clearErrors={clearErrors}
          forward_type_key="forward_call_actions.during_business_hour.type"
          forward_value_key="forward_call_actions.during_business_hour.value"
          forward_type_error={
            errors?.forward_call_actions?.during_business_hour?.type?.message
          }
          forward_value_error={
            errors?.forward_call_actions?.during_business_hour?.value?.message
          }
          control={control}
          watch={watch}
          setValue={setValue}
          selectedItem={itemElement?.did_number}
        />
      </div>

      {["EXTENSION", "TEAM"].includes(forwardTypeValue) && (
        <div className="flex items-center gap-6 mt-6">
          <div className="w-full">
            <Controller
              name={"forward_call_actions.during_business_hour.timeout"}
              control={control}
              render={({ field }) => (
                <CustomSelect
                  {...field}
                  placeholder={t("overview.selectOption")}
                  onWhitebg={true}
                  label={t("overview.forwardLong")}
                  error={errors?.timeout?.message}
                  ItemComponent={TimeoutItem}
                  valueKey={"value"}
                  labelKey={"label"}
                  isMulti={false}
                  optionsList={Array.from({ length: 30 }, (_, i) => i + 1).map(
                    (val) => ({
                      id: val,
                      label: `${val} seconds`,
                      value: val,
                    })
                  )}
                />
              )}
            />
          </div>
          <div className="w-full">
            <Controller
              name={"forward_call_actions.failover.type"}
              control={control}
              render={({ field }) => (
                <CustomSelect
                  {...field}
                  placeholder={t("overview.selectFailover")}
                  onWhitebg={true}
                  label={t("overview.selectFailover")}
                  error={errors?.timeout?.message}
                  ItemComponent={ForwardToItem}
                  valueKey={"value"}
                  labelKey={"label"}
                  isMulti={false}
                  optionsList={[
                    {
                      id: 1,
                      label: t("overview.voicemail"),
                      value: "VOICEMAIL",
                    },
                  ]}
                />
              )}
            />
          </div>
        </div>
      )}
      {["EXTENSION"].includes(forwardTypeValue) && (
        <>
          <div className="w-full mt-6 ">
            <Controller
              name={"forward_call_actions.connect_as_web_call"}
              control={control}
              render={({ field }) => (
                <SwitchInput
                  {...field}
                  name={"connect_as_web_call"}
                  label={"Connect calls as web calls"}
                />
              )}
            />
          </div>

          <div className=" mt-6">
            <div className="text-grey-700 font-semibold text-sm  ">
              {t("overview.textOne")}
              <br></br>
              {t("overview.textTwo")}
            </div>
          </div>
        </>
      )}
      <div className="flex items-center justify-end gap-6 mt-[73px]">
        <Button
          type="button"
          width="w-[80px]"
          background="transparent"
          onClick={handleClose}
        >
          <div className="text-grey-900 flex items-center gap-2">
            <Arrow className="rotate-180 text-black" />
            Back
          </div>
        </Button>
        <Button type="submit" width="w-[191px]" disabled={setForwardingLoad}>
          {setForwardingLoad ? <Spinner /> : "Confirm"}
        </Button>
      </div>
    </form>
  );
};

export default SetForwardingModal;
