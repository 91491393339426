import { Capture, CloudArrowUp, X } from "assets/images";
import { Input } from "components";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";

const UploadIdentificationModal = ({
  handleClose = () => null,
  onSuccess = () => null,
  openSelfieModal = () => null,
}) => {
  const filePicker = useRef(null);
  const { t } = useTranslation();
  function handleSuccess(data) {
    onSuccess(data);
    handleClose();
  }
  const Options = [
    {
      id: 1,
      label: t("overview.captureSelfie"),
      value: "selfie",
      icon: <Capture />,
    },
    {
      id: 2,
      label: t("overview.uploadValidId"),
      value: "upload",
      icon: <CloudArrowUp />,
    },
  ];

  function handleFileSelect(e) {
    const file = e?.target?.files?.[0] ?? {};
    if (file?.type?.includes("image")) {
      handleSuccess({ file, type: "U" });
    }
  }
  return (
    <div className="min-w-[500px]">
      <div className="flex justify-between items-start py-4 px-6">
        <div className="flex flex-col gap-1">
          <div className="text-xl font-semibold text-black">
            {t("overview.selectOption")}
          </div>
          <div className="text-sm  text-grey-700">
            {t("overview.howToSubDoc")}
          </div>
        </div>

        <button type="button" onClick={() => handleClose()}>
          <X className="text-grey-600 w-6 h-6" />
        </button>
      </div>

      <div className="p-6 flex flex-col gap-4 mt-5">
        {Options.map(({ id, label, value, icon }) => {
          return (
            <div
              key={id}
              className="border group hover:bg-green hover:text-white border-grey-400 rounded-md h-14 flex items-center justify-between px-4 cursor-pointer font-medium"
              onClick={() => {
                if (value === "upload") {
                  filePicker?.current?.click();
                } else {
                  openSelfieModal();
                }
              }}
            >
              {label}
              <div className="text-grey-900 group-hover:text-white">{icon}</div>
            </div>
          );
        })}
        <Input
          ref={filePicker}
          extraClasses="hidden"
          type="file"
          accept={"image/*"}
          id="upload_identification"
          onChange={handleFileSelect}
        />
      </div>
    </div>
  );
};

export default UploadIdentificationModal;
