import {
  Gift,
  PinLink,
  PreviousRight,
  Renew,
  StepKYC,
  WaveOne,
  WaveTwo,
  WhatsappIcon,
  XIcon,
} from "assets/images";
import { Facebook, Google } from "assets/images/signup";
import { Instagram } from "assets/images";
import { Button, Input } from "components";
import React from "react";
import { useTranslation } from "react-i18next";

export const InviteAndEarn = () => {
  const { t } = useTranslation();
  return (
    <div className="overflow-auto w-full px-6 pt-6 pb-6">
      <div className="flex flex-col w-full">
        <div className="flex items-center justify-between mb-5">
          <div className="text-sm text-grey-700">
            {t("invite.manageReferrals")}
          </div>
        </div>

        <div className="w-full rounded-md p-7 border border-grey-400 bg-white min-h-[calc(100vh_-_226px)] flex justify-center">
          <div className="max-w-[900px] w-full flex flex-col gap-2">
            <div className="w-full">
              <div className="w-full flex justify-between mb-10">
                <div className=""></div>
                <div className=" flex flex-col items-center">
                  <div className="text-3xl font-medium flex items-center">
                    <Renew className="w-5 h-5" /> 7,500
                  </div>
                  <div className="text-sm text-grey-600">
                    {t("invite.totalRewards")}
                  </div>
                </div>
                <div className="text-green font-semibold flex items-center gap-2">
                  {t("invite.invite")}
                  <div className="w-6 h-6 rounded-full bg-green text-white flex items-center justify-center">
                    <PreviousRight className="text-white w-4 h-4" />
                  </div>
                </div>
              </div>
              <div className="flex gap-10">
                <div className="w-full flex flex-col items-center gap-3 relative">
                  <div className="w-20 h-20 rounded-full bg-green-50 flex items-center justify-center">
                    <PinLink className="w-8 h-8" />
                  </div>
                  <div className="text-sm font-semibold text-center">
                    {t("invite.sendInvitation")}
                  </div>
                  <div className="text-sm text-grey-600 text-center">
                    {t("invite.withReferLink")}
                  </div>
                  <div className="w-full max-w-[136px] absolute top-1 -right-[90px]">
                    <div className="pt-2">
                      <WaveOne />
                    </div>
                  </div>
                </div>

                <div className="w-full flex flex-col items-center gap-3 relative">
                  <div className="w-20 h-20 rounded-full bg-green-50 flex items-center justify-center">
                    <StepKYC className="w-6 h-6" />
                  </div>
                  <div className="text-sm font-semibold text-center">
                    {t("invite.yourFriend")}
                  </div>
                  <div className="text-sm text-grey-600 text-center">
                    {t("invite.inviteInfo")}
                  </div>
                  <div className="w-full max-w-[136px] absolute top-1 -right-[83px]">
                    <div className="pt-2">
                      <WaveTwo />
                    </div>
                  </div>
                </div>

                <div className="w-full flex flex-col items-center gap-3 relative">
                  <div className="w-20 h-20 rounded-full bg-green-50 flex items-center justify-center">
                    <Gift className="w-6 h-6" />
                  </div>
                  <div className="text-sm font-semibold text-center">
                    {t("invite.getRewarded")}
                  </div>
                  <div className="text-sm text-grey-600 text-center"></div>
                  <div className="w-full max-w-[136px] absolute top-1 -right-[90px]">
                    <div className="pt-2">
                      <WaveOne />
                    </div>
                  </div>
                </div>

                <div className="w-full flex flex-col items-center gap-3">
                  <div className="w-20 h-20 rounded-full bg-green-50 flex items-center justify-center">
                    <Renew className="w-6 h-6" />
                  </div>
                  <div className="text-sm font-semibold text-center">
                    {t("invite.renew")}
                  </div>
                  <div className="text-sm text-grey-600 text-center">
                    {t("invite.fonuCred")}
                  </div>
                </div>
              </div>
              <div className="w-full flex flex-col mt-12">
                <div className="text-lg font-semibold ">
                  {t("invite.inviteFriends")}
                </div>
                <div className="text-sm text-grey-600 mb-5">
                  {t("invite.insert")}
                </div>
                <div className="w-full flex gap-1">
                  <Input placeholder="hjkhkj" />
                  <div className="max-w-36 w-full">
                    <Button
                      // onClick={() => setCurrentStep(2)}
                      type="button"
                      border="border-green"
                      // extraClasses="max-w-[400px]"
                    >
                      {t("invite.sendInvite")}
                    </Button>
                    {/* <Button ></Button> */}
                  </div>
                </div>
              </div>
              <div className="w-full flex flex-col mt-12">
                <div className="text-lg font-semibold ">
                  {t("invite.referralLink")}
                </div>
                <div className="text-sm text-grey-600 mb-5">
                  {t("invite.share")}
                </div>
                <div className="w-full flex gap-1">
                  <div className="w-full relative flex items-center justify-end ">
                    <Input placeholder="hjkhkj" />
                    <span className="text-green text-sm font-semibold absolute  right-3 cursor-pointer">
                      {t("invite.copyLink")}
                    </span>
                  </div>
                  <div className=" flex gap-1 ">
                    <Button
                      type="button"
                      border="border-grey-400"
                      extraClasses="bg-grey-200 text-black min-w-[45px] max-w-[50px] flex items-center justify-center"
                    >
                      <img src={WhatsappIcon} alt="WhatsappIcon" />{" "}
                    </Button>
                    <Button
                      type="button"
                      border="border-grey-400"
                      extraClasses="bg-grey-200 text-black min-w-[45px] max-w-[50px] flex items-center justify-center"
                    >
                      <Google />{" "}
                    </Button>
                    <Button
                      type="button"
                      border="border-grey-400"
                      extraClasses="bg-grey-200 text-black min-w-[45px] max-w-[50px] flex items-center justify-center"
                    >
                      <Facebook />{" "}
                    </Button>
                    <Button
                      type="button"
                      border="border-grey-400"
                      extraClasses="bg-grey-200 text-black min-w-[45px] max-w-[50px] flex items-center justify-center"
                    >
                      <img src={Instagram} alt="InstagramIcon" />{" "}
                    </Button>
                    <Button
                      type="button"
                      border="border-grey-400"
                      extraClasses="bg-grey-200 text-black min-w-[45px] max-w-[50px] flex items-center justify-center"
                    >
                      <XIcon />{" "}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="w-full flex flex-col gap-2 h-full">
              <div className="flex w-full items-center justify-between items-center border-b border-grey-400 h-12 pb-2">
                <div className='flex flex-col'>
                  <div className="text-sm font-semibold">Samuel Atusi</div>
                  <div className="text-sm text-grey-600 font-medium">samuelatusi@gmail.com</div>
                </div>
                <div className='flex gap-3 items-center'>
                  <div className="max-w-[120px] w-full min-w-[110px] min-h-[29px] rounded-full bg-green-100 text-green text-xs flex items-center justify-center font-medium cursor-pointer">
                    Earning ₦200
                  </div>
                  <div className='font-sm cursor-pointer'>

                    <PreviousRight className='w-5 h-5' />
                  </div>
                </div>
              </div>
              <div className="flex w-full items-center justify-between items-center border-b border-grey-400 h-12 pb-2">
                <div className='flex flex-col'>
                  <div className="text-sm font-semibold">Samuel Atusi</div>
                  <div className="text-sm text-grey-600 font-medium">samuelatusi@gmail.com</div>
                </div>
                <div className='flex gap-3 items-center'>
                  <div className="max-w-[120px] w-full min-w-[110px] min-h-[29px] rounded-full bg-green-100 text-green text-xs flex items-center justify-center font-medium cursor-pointer">
                    Earning ₦200
                  </div>
                  <div className='font-sm cursor-pointer'>

                    <PreviousRight className='w-5 h-5' />
                  </div>
                </div>
              </div>
              <div className="flex w-full items-center justify-between items-center border-b border-grey-400 h-12 pb-2">
                <div className='flex flex-col'>
                  <div className="text-sm font-semibold">Samuel Atusi</div>
                  <div className="text-sm text-grey-600 font-medium">samuelatusi@gmail.com</div>
                </div>
                <div className='flex gap-3 items-center'>
                  <div className="max-w-[120px] w-full min-w-[110px] min-h-[29px] rounded-full bg-green-100 text-green text-xs flex items-center justify-center font-medium cursor-pointer">
                    Earning ₦200
                  </div>
                  <div className='font-sm cursor-pointer'>

                    <PreviousRight className='w-5 h-5' />
                  </div>
                </div>
              </div>
              <div className="flex w-full items-center justify-between items-center border-b border-grey-400 h-12 pb-2">
                <div className='flex flex-col'>
                  <div className="text-sm font-semibold">Samuel Atusi</div>
                  <div className="text-sm text-grey-600 font-medium">samuelatusi@gmail.com</div>
                </div>
                <div className='flex gap-3 items-center'>
                  <div className="max-w-[120px] w-full min-w-[110px] min-h-[29px] rounded-full bg-green-100 text-green text-xs flex items-center justify-center font-medium cursor-pointer">
                    Earning ₦200
                  </div>
                  <div className='font-sm cursor-pointer'>

                    <PreviousRight className='w-5 h-5' />
                  </div>
                </div>
              </div>
              <div className="flex w-full items-center justify-between items-center border-b border-grey-400 h-12 pb-2">
                <div className='flex flex-col'>
                  <div className="text-sm font-semibold">Samuel Atusi</div>
                  <div className="text-sm text-grey-600 font-medium">samuelatusi@gmail.com</div>
                </div>
                <div className='flex gap-3 items-center'>
                  <div className="max-w-[120px] w-full min-w-[110px] min-h-[29px] rounded-full bg-green-100 text-green text-xs flex items-center justify-center font-medium cursor-pointer">
                    Earning ₦200
                  </div>
                  <div className='font-sm cursor-pointer'>

                    <PreviousRight className='w-5 h-5' />
                  </div>
                </div>
              </div>
              <div className="flex w-full items-center justify-between items-center border-b border-grey-400 h-12 pb-2">
                <div className='flex flex-col'>
                  <div className="text-sm font-semibold">Samuel Atusi</div>
                  <div className="text-sm text-grey-600 font-medium">samuelatusi@gmail.com</div>
                </div>
                <div className='flex gap-3 items-center'>
                  <div className="max-w-[120px] w-full min-w-[110px] min-h-[29px] rounded-full bg-green-100 text-green text-xs flex items-center justify-center font-medium cursor-pointer">
                    Earning ₦200
                  </div>
                  <div className='font-sm cursor-pointer'>

                    <PreviousRight className='w-5 h-5' />
                  </div>
                </div>
              </div>
              <div className="flex w-full items-center justify-between items-center border-b border-grey-400 h-12 pb-2">
                <div className='flex flex-col'>
                  <div className="text-sm font-semibold">Samuel Atusi</div>
                  <div className="text-sm text-grey-600 font-medium">samuelatusi@gmail.com</div>
                </div>
                <div className='flex gap-3 items-center'>
                  <div className="max-w-[120px] w-full min-w-[110px] min-h-[29px] rounded-full bg-green-100 text-green text-xs flex items-center justify-center font-medium cursor-pointer">
                    Earning ₦200
                  </div>
                  <div className='font-sm cursor-pointer'>

                    <PreviousRight className='w-5 h-5' />
                  </div>
                </div>
              </div>


              <div className="flex w-full justify-between items-center border-b border-grey-400 h-10 pb-2">
                <div className="text-sm text-grey-600">+234 70 9332 7543</div>
                <div className="max-w-[80px] min-w-[80px] min-h-[29px] rounded-full bg-danger-100 text-danger text-xs flex items-center justify-center font-medium cursor-pointer">
                  Unblock
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};
