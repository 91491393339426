/* eslint-disable no-unused-vars */
import { CalendlyIcon } from "assets/images";
import { Button } from "components";
import React, { useState, useEffect } from "react";
const { routes } = require("api/routes");
const { getToken } = require("shared/resources");

const Calendly = () => {
  const CLIENT_ID = process.env.REACT_APP_CALENDLY_CLIENT_ID;
  const BACKEND_URL = process.env.REACT_APP_BASE_API_URL;
  const REDIRECT_URI = process.env.REACT_APP_CALENDLY_REDIRECT_URI;

  const handleAuthorize = () => {
    const authUrl = `https://auth.calendly.com/oauth/authorize?client_id=${CLIENT_ID}&response_type=code&redirect_uri=${REDIRECT_URI}`;
    window.location.href = authUrl;
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");

    if (code) {
      fetch(`${BACKEND_URL}${routes.CREATE_CALENDLY_INTEGRATION.URL}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify({ code }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.access_token) {
            console.log("data:", data);
          } else {
            console.log("data:", data);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, []);

  return (
    <div
      className={`h-[236px] bg-[#F1F1F1] shadow-md flex flex-col justify-center px-6`}
    >
      <div className="flex flex-col gap-7">
        <div className="flex items-center space-x-2">
          <CalendlyIcon className="w-7 h-7" />
          <p className="">Calendly</p>
        </div>
        <div className="flex flex-col gap-6">
          <p className="font-bold text-[#0D121D]">
            This integration allows you to manage and schedule appointments
            through Calendly when connected to your account.
          </p>

          <div className="w-[400px]">
            <Button onClick={handleAuthorize}>Connect Calendly Account</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Calendly;
