import React, { useContext, useEffect, useState, useCallback } from "react";
import { useAuth } from "hooks/useAuth";
import { SipSocketContext } from "hooks/useSip/SipSocketContext";
import { useDidList } from "hooks";
import {
  BlackAdd,
  BlackHold,
  BlackKeypad,
  BlackMute,
  BlackRecord,
  BlackTransfer,
  CutCall,
  NoteIcon,
  WhiteHold,
  WhiteMute,
  WhiteRecord,
} from "assets/images";
import { toast } from "react-toastify";
import axios from "axios";
import { routes } from "api/routes";
import { getToken } from "shared/resources";
import { toastEmitter } from "components/Toast";
import { X } from "lucide-react";
import Select from "components/Select";
import Input from "components/Input";
import Button from "components/Button";

const ConnectedScreen = ({ activeCallData = {}, setAction }) => {
  const { socket = {} } = useContext(SipSocketContext);
  const { user } = useAuth();
  const { mutate } = useDidList();
  const { _number = "", _status = "", callID = "" } = activeCallData;
  const { _terminate, _muteCall, _toggleHold, _uaSessions, _sendDtmf } = socket;

  const isUARecordingEnabled =
    !!_uaSessions?.[callID]?._request?.headers?.["X-Record"];

  const [isRecording, setIsRecording] = useState(isUARecordingEnabled);

  useEffect(() => {
    mutate();
  }, []);

  useEffect(() => {
    fetchDidDetails();
  }, [_uaSessions]);

  const fetchDidDetails = useCallback(async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}${routes.GET_DID_DETAILS.URL}`,
        {
          phone_number: user?.caller_id,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (
        _uaSessions?.[callID]?._direction === "outgoing" &&
        response.data?.data?.incoming_call?.auto_record_outgoing_calls
      ) {
        setIsRecording(true);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
        return (window.location.href = "/");
      } else {
        toastEmitter("error", error?.response?.data?.error?.message);
      }
    }
  }, [user, _uaSessions, callID]);

  const callRecording = (uuid) => {
    if (_number?.length <= 4) return;
    if (isRecording) {
      _sendDtmf(uuid, `*3`);
      setIsRecording(false);
    } else {
      _sendDtmf(uuid, `*2`);
      setIsRecording(true);
    }
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const connected = [
    { uuid: 1, title: "Yes" },
    { uuid: 2, title: "No" },
  ];
  const connected2 = [
    { uuid: 1, title: "Yes" },
    { uuid: 2, title: "No" },
  ];

  const numbers = [
    { uuid: 1, number: 1 },
    { uuid: 2, number: 2 },
    { uuid: 3, number: 3 },
    { uuid: 4, number: 4 },
    { uuid: 5, number: 5 },
    { uuid: 6, number: 6 },
    { uuid: 7, number: 7 },
    { uuid: 8, number: 8 },
    { uuid: 9, number: 9 },
    { uuid: 10, number: 0 },
  ];
  const numbers2 = [
    { uuid: 1, number: 1 },
    { uuid: 2, number: 2 },
    { uuid: 3, number: 3 },
    { uuid: 4, number: 4 },
    { uuid: 5, number: 5 },
    { uuid: 6, number: 6 },
    { uuid: 7, number: 7 },
    { uuid: 8, number: 8 },
    { uuid: 9, number: 9 },
    { uuid: 10, number: 0 },
  ];
  const reasons = [
    { uuid: 1, reason: "Financial Issues" },
    { uuid: 2, reason: "Bank Issues" },
    { uuid: 3, reason: "Waiting for salary" },
    { uuid: 4, reason: "Sick, hospitalized" },
    { uuid: 5, reason: "Unable to pay(payment issues)" },
    { uuid: 6, reason: "Doesn't know how to pay" },
    { uuid: 7, reason: "Travelled to remote areas" },
    { uuid: 8, reason: "Lost/damage phone" },
    { uuid: 9, reason: "Death" },
    { uuid: 10, reason: "Others" },
  ];
  const comments = [
    { uuid: 1, title: "No answer" },
    { uuid: 2, title: "Switched off" },
    { uuid: 3, title: "Barred" },
    { uuid: 4, title: "Busy" },
    { uuid: 5, title: "Hung up" },
    { uuid: 6, title: "Mute" },
    { uuid: 7, title: "No commitment" },
    { uuid: 8, title: "No precise amount" },
    { uuid: 9, title: "No precise date" },
    { uuid: 10, title: "Paid" },
    { uuid: 11, title: "Payment not updated" },
    { uuid: 12, title: "Loan not disbursed" },
    { uuid: 13, title: "Third party contact" },
    { uuid: 14, title: "Deceased" },
    { uuid: 16, title: "Wrong number" },
    { uuid: 17, title: "No knowledge of loan" },
    { uuid: 18, title: "Language barrier" },
    { uuid: 19, title: "PTP next week" },
    { uuid: 20, title: "PTP month end" },
    { uuid: 21, title: "Others" },
  ];
  return (
    <div className="flex flex-col h-full xxl:gap-8 xl:gap-4 lg:gap-8 md:gap-10 px-4 xxl:mt-8 xl:mt-3 lg:mt-8 md:mt-8 ">
      <div className="grid grid-cols-4 gap-6">
        {/* <div className="flex items-center justify-between xxl:px-5 xl:px-3 lg:px-5 md:px-8"> */}
        <div
          className="flex items-center flex-col gap-2 justify-center xxl:w-auto xl:w-auto lg:w-auto  md:w-[55px] "
          onClick={() => _muteCall(callID)}
        >
          {_status === "mute" ? (
            <WhiteMute
              height={64}
              className="xxl:w-auto xl:w-14 lg:w-[52px] md:w-12 cursor-pointer"
            />
          ) : (
            <BlackMute className="xxl:w-auto xl:w-14 lg:w-[52px] md:w-12 cursor-pointer" />
          )}

          <div className="text-sm text-white">
            {_status === "mute" ? "Unmute" : "Mute"}
          </div>
        </div>
        <div
          className="flex items-center flex-col gap-2 justify-center xxl:w-auto xl:w-auto lg:w-auto  md:w-[55px]"
          onClick={() => setAction("transfer")}
        >
          <BlackTransfer className="xxl:w-auto xl:w-14 lg:w-[52px] md:w-12 cursor-pointer" />
          <div className="text-sm text-white">Transfer</div>
        </div>
        <div
          className="flex items-center flex-col gap-2 justify-center xxl:w-auto xl:w-auto lg:w-auto  md:w-[55px]"
          onClick={() => setAction("dial")}
        >
          <BlackKeypad className="xxl:w-auto xl:w-14 lg:w-[52px] md:w-12 cursor-pointer" />
          <div className="text-sm text-white">Keypad</div>
        </div>
        {/* </div> */}
        {/* <div className="flex items-center justify-between xxl:px-5 xl:px-3 lg:px-5 md:px-8"> */}
        <div
          className="flex items-center flex-col gap-2 justify-center xxl:w-auto xl:w-auto lg:w-auto  md:w-[55px]"
          onClick={() => _toggleHold(callID)}
        >
          {_status === "hold" ? (
            <WhiteHold className="xxl:w-auto xl:w-14 lg:w-[52px] md:w-12 cursor-pointer" />
          ) : (
            <BlackHold className="xxl:w-auto xl:w-14 lg:w-[52px] md:w-12 cursor-pointer" />
          )}
          <div className="text-sm text-white">
            {_status === "hold" ? "Unhold" : "Hold"}
          </div>
        </div>
        <div
          className="flex items-center flex-col gap-2 justify-center xxl:w-auto xl:w-auto lg:w-auto  md:w-[55px]"
          onClick={() => setAction("add")}
        >
          <BlackAdd className="xxl:w-auto xl:w-14 lg:w-[52px] md:w-12 cursor-pointer" />
          <div className="text-sm text-white">Add Call</div>
        </div>
        <div
          className="flex items-center flex-col gap-2 justify-center xxl:w-auto xl:w-auto lg:w-auto  md:w-[55px]"
          onClick={() => {
            const callRecordingFeatureEnabled =
              user?.get_company_details?.features.find(
                (f) => f.key === "call_recording"
              ).value;
            if (!callRecordingFeatureEnabled) {
              toast.error(
                "Call Recording is not enabled for your Account Plan. Please upgrade to Business plan"
              );
              return;
            }
            callRecording(callID);
          }}
        >
          {_number?.length <= 4 ? (
            <BlackRecord className="xxl:w-auto xl:w-14 lg:w-[52px] md:w-12 cursor-pointer" />
          ) : isRecording ? (
            <WhiteRecord className="xxl:w-auto xl:w-14 lg:w-[52px] md:w-12 cursor-pointer" />
          ) : (
            <BlackRecord className="xxl:w-auto xl:w-14 lg:w-[52px] md:w-12 cursor-pointer" />
          )}

          <div className="text-sm text-white">Record</div>
        </div>
        <div
          onClick={() => setIsModalOpen(true)}
          className="flex items-center flex-col gap-2 cursor-pointer justify-center xxl:w-auto xl:w-auto lg:w-auto  md:w-[55px]"
        >
          <NoteIcon />
          <div className="text-sm text-white">Note</div>
        </div>
      </div>
      <div
        className="flex items-center flex-col gap-2 justify-center "
        onClick={() => {
          _terminate(callID);
        }}
      >
        <CutCall className="xxl:w-auto xl:w-16 lg:w-16 md:w-14 cursor-pointer" />
      </div>
      {isModalOpen && (
        <div className="fixed inset-0 flex justify-end bg-black/10 bg-opacity-10 -z-20 ">
          <div
            className="bg-black bg-opacity-80 h-screen overflow-auto w-1/3 shadow-lg backdrop-blur-md z-50 p-4"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              className="text-gray-500 flex items-end ml-auto hover:text-gray-800 mb-4"
              onClick={() => setIsModalOpen(false)}
            >
              <X className="text-white" />
            </button>
            <div className="flex flex-col gap-2">
              <div>
                <p className="text-green">Connected</p>
                <Select
                  options={connected}
                  extraClasses="mt-2 text-white bg-transparent focus:bg-transparent"
                  renderOption={({ item }) => (
                    <option key={item.uuid} value={item.title}>
                      {item.title}
                    </option>
                  )}
                />
              </div>
              <div>
                <p className="text-green">Right party contact</p>
                <Select
                  options={connected2}
                  extraClasses="mt-2 text-white bg-transparent focus:bg-transparent"
                  renderOption={({ item }) => (
                    <option key={item.uuid} value={item.title}>
                      {item.title}
                    </option>
                  )}
                />
              </div>
              <div>
                <p className="text-green">No. of times connected</p>
                <Select
                  options={numbers}
                  extraClasses="mt-2 text-white bg-transparent focus:bg-transparent"
                  renderOption={({ item }) => (
                    <option key={item.uuid} value={item.number}>
                      {item.number}
                    </option>
                  )}
                />
              </div>
              <div>
                <p className="text-green">No. of times called</p>
                <Select
                  options={numbers2}
                  extraClasses="mt-2 text-white bg-transparent focus:bg-transparent"
                  renderOption={({ item }) => (
                    <option key={item.uuid} value={item.number}>
                      {item.number}
                    </option>
                  )}
                />
              </div>
              <div>
                <p className="text-green">Reason for delinquency</p>
                <Select
                  options={reasons}
                  extraClasses="mt-2 text-white bg-transparent focus:bg-transparent"
                  renderOption={({ item }) => (
                    <option key={item.uuid} value={item.reason}>
                      {item.reason}
                    </option>
                  )}
                />
              </div>
              <div>
                <p className="text-green">PIP Date</p>
                <Input
                  extraClasses="bg-transparent focus:bg-transparent text-white mt-2"
                  type="date"
                />
              </div>
              <div>
                <p className="text-green">PIP Amount</p>
                <Input
                  extraClasses="bg-transparent focus:bg-transparent text-white mt-2"
                  type="text"
                />
              </div>
              <div>
                <p className="text-green">Comment</p>
                <Select
                  options={comments}
                  extraClasses="mt-2 text-white bg-transparent focus:bg-transparent"
                  renderOption={({ item }) => (
                    <option key={item.uuid} value={item.title}>
                      {item.title}
                    </option>
                  )}
                />
              </div>
            </div>
            <div className="mt-6">
              <Button>Submit</Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ConnectedScreen;
