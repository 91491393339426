import { Plus, X } from "assets/images";
import { Button, Input } from "components";
import React, { useState } from "react";

const Embedding = () => {
  const [showModal, setShowModal] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [input2Value, setInput2Value] = useState("");
  const [textareaValue, setTextAreaValue] = useState("");

  const handleCloseModal = () => setShowModal(false);

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (value.length <= 50) {
      setInputValue(value);
    }
  };
  const handleInput2Change = (e) => {
    const value = e.target.value;
    if (value.length <= 50) {
      setInput2Value(value);
    }
  };
  const handleTextAreaChange = (e) => {
    const value = e.target.value;
    if (value.length <= 100) {
      setTextAreaValue(value);
    }
  };

  return (
    <div className="relative">
      <div
        className="absolute -top-[70px] right-4 w-[200px]"
        onClick={() => setShowModal(true)}
      >
        <Button className="w-full">
          <Plus /> New Embedding
        </Button>
      </div>
      <div className="w-[500px] mt-8">
        <Input
          withSearchIcon
          placeholder="Search by name"
          extraClasses="pl-10"
        />
      </div>
      <div className="w-full mt-8 bg-[#f1f1f1] h-[231px] rounded-lg py-6 px-4">
        <table className="w-full">
          <thead className="w-full text-center border-b h-16 border-[#D9D9D9] pt-6">
            <tr>
              <th className="w-[100px]">Phone Number</th>
              <th className="w-[100px]">Provider</th>
              <th className="w-[100px]">Voice</th>
              <th className="w-[100px]">SMS</th>
              <th className="w-[100px]">Data</th>
            </tr>
          </thead>
          <tbody className="w-full text-center h-12">
            <tr>
              <td></td>
              <td></td>
              <td>No data</td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
      {showModal && (
        <div
          className="fixed inset-0 z-50 animate-modalAnimation flex items-center justify-center bg-black bg-opacity-50"
          onClick={handleCloseModal}
        >
          <div
            className="bg-white p-4 rounded-lg w-[650px] h-[550px] flex flex-col text-center overflow-scroll"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex justify-between w-full mb-6">
              <p className="font-bold">Open AI Embedding</p>
              <div className="cursor-pointer" onClick={handleCloseModal}>
                <X />
              </div>
            </div>
            <form className="w-full flex flex-col text-left gap-4">
              <div className="flex flex-col gap-2">
                <label className="text-base text-left text-black/80 font-medium">
                  Type
                </label>
                <div className="relative">
                  <input
                    type="text"
                    value={inputValue}
                    onChange={handleInputChange}
                    maxLength={50}
                    placeholder="Only letters, numbers, and underscores"
                    className="border-2 border-grey-400 px-3 py-2 rounded-md focus:border-green focus:ring-green-200 w-full"
                  />
                  <p className="text-black/40 absolute top-[23%] right-4">
                    {inputValue.length}/50
                  </p>
                </div>
                <p className="text-sm text-[#9B9DA4]">
                  Type can be used as filter for embedding match
                </p>
              </div>
              <div className="flex flex-col gap-2">
                <label className="text-base text-left text-black/80 font-medium">
                  <span className="text-danger">* </span>Heading
                </label>
                <div className="relative">
                  <input
                    type="text"
                    value={input2Value}
                    onChange={handleInput2Change}
                    maxLength={50}
                    placeholder="e.g into"
                    className="border-2 border-grey-400 px-3 py-2 rounded-md focus:border-green focus:ring-green-200 w-full"
                  />
                  <p className="text-black/40 absolute top-[23%] right-4">
                    {inputValue.length}/50
                  </p>
                </div>
                <p className="text-sm text-[#9B9DA4]">
                  Heading must be unique across all embeddings
                </p>
              </div>
              <div className="flex flex-col gap-2">
                <label className="text-base text-left text-black/80 font-medium">
                  <span className="text-danger">* </span>Text
                </label>
                <div className="relative">
                  <textarea
                    type="text"
                    value={textareaValue}
                    onChange={handleTextAreaChange}
                    maxLength={100}
                    placeholder="Only letters, numbers, and underscores"
                    className="border-2 border-grey-400 px-3 py-2 rounded-md focus:border-green focus:ring-green-200 w-full"
                  />
                  <p className="text-black/40 absolute top-[23%] right-4">
                    {inputValue.length}/100
                  </p>
                </div>
              </div>

              <div className="flex items-center justify-end ml-auto gap-4 mt-4">
                <button className="border rounded-md h-[40px] w-[100px] flex items-center justify-center">
                  Cancel
                </button>
                <div className="w-[100px]">
                  <Button>Create</Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Embedding;
