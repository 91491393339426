import { TwitterIcon } from "assets/images";
import { Button } from "components";
import React from "react";

function TwitterDm() {
  return (
    <div
      className={`h-[309px] bg-[#F1F1F1] shadow-md flex flex-col justify-center px-6`}
    >
      <div className="flex flex-col gap-7">
        <div className="flex items-center space-x-2">
          <TwitterIcon className="w-8 h-8" />
          <p className="pt-1">Twitter Dm</p>
        </div>
        <div className="flex flex-col gap-6">
          <p className="font-bold text-[#0D121D]">
            Connect your Twitter Account to your Fonu account{" "}
          </p>
          <p className="text-[#474A4E] text-[14px]">
            We will need permission to manage your Twitter Account in order to
            automate your replies. <br /> You can create Twitter bot on
            connected accounts.
          </p>
          <div className="w-[400px]">
            <Button>Connect to Twitter</Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TwitterDm;
