import { BotsTree, Messenger, Omni } from "assets/images";
import { Button, Input } from "components";
import { ArrowLeft, ArrowRight } from "lucide-react";
import React, { useState } from "react";

const StepOne = ({ next, previous }) => {
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const handleTemplateClick = (index) => {
    setSelectedTemplate(index);
  };
  return (
    <div className="w-full">
      <div className="flex justify-between items-center w-full">
        <div
          onClick={previous}
          className="flex text-black/50 text-sm items-center gap-3 cursor-pointer"
        >
          <ArrowLeft />
          <p className="mb-1">Back</p>
        </div>
        <p className="font-medium text-black/80">Create New Bot</p>
        <div className="w-[150px]" onClick={next}>
          <Button extraClasses="flex items-center gap-2">
            <span>Next Step</span> <ArrowRight />
          </Button>
        </div>
      </div>
      <div className="font-bold text-base text-center my-6">
        <p>Step 1: Select a template</p>
      </div>
      <div className="w-[500px]">
        <Input
          placeholder="Search by name"
          withSearchIcon
          extraClasses="pl-10"
        />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-6">
        {[...Array(4)].map((_, index) => (
          <div
            key={index}
            onClick={() => handleTemplateClick(index)}
            className={`border rounded-lg h-full w-full relative group transition-all cursor-pointer ${
              selectedTemplate === index
                ? "border-green border-2"
                : "border-[#9B9DA4]"
            }`}
          >
            {index > 0 && (
              <div className="absolute top-2 rounded-lg w-[50px] h-[40px] right-2 bg-[#024DE3] flex items-center justify-center text-white">
                <p>PRO</p>
              </div>
            )}
            <div className="h-[200px] flex items-center justify-center">
              <BotsTree />
            </div>
            <div className="p-3 flex flex-col gap-4 mt-4 border-t border-black/60 transition-all duration-300">
              <p>Free</p>
              <div className="flex gap-2 items-center">
                {index === 0 ? <Omni /> : <Messenger />}
                <p className="font-bold text-[#1F2124]">
                  {index === 0 && "Blank Template"}
                  {index === 1 && "Coffee Shop Template"}
                  {index === 2 && "Property Issue Template"}
                  {index === 3 && "Pizza Delivery Template"}
                </p>
              </div>
              <p className="text-[#474A4E] text-base">
                {index === 0 &&
                  "Start with a blank slate and unlimited possibilities."}
                {index === 1 &&
                  "This is a free template from Fonu. Phone Number setup needed."}
                {index === 2 &&
                  "This is a free template from Fonu. No integration needed."}
                {index === 3 &&
                  "This is a  free template from Fonu. Ecommerce System needed."}
              </p>
              <div className="flex flex-col gap-2">
                <Button>Install</Button>
                <button className="border text-black py-2 rounded-md">
                  See More
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StepOne;
