import { Edit, Mail, MenuIcon, NoRecord, Trash, X } from "assets/images";
import {
  Avatar,
  Button,
  Dropdown,
  EllipsisTextWithTooltip,
  Modal,
  Spinner,
  TableManager,
} from "components";
import React, { useEffect, useMemo, useState } from "react";
import { dropdownActions, initialModalState } from "./constants";
import { teamListing } from "api";
import { ADMIN, OWNER } from "shared/resources";
import { useAuth } from "hooks/useAuth";
import CreateTeamModal from "./CreateTeamModal";
import AvatarGroup from "components/AvatarGroup";
import DeleteTeamModal from "./DeleteTeamModal";
import useTeamDelete from "hooks/useTeamDelete";
import useMemberList from "hooks/useMemberListing";
import InviteTeamToModal from "./InviteToTeamModal";
import useBulkDelete from "hooks/useBulkDelete";
import BulkDeleteTeamModal from "./BulkDeleteTeamModal";
import { useTranslation } from "react-i18next";

const BulkDeleteComponent = ({ data = [], callback = () => null }) => {
  const { mutate, isPending, status } = useBulkDelete();
  const [showModal, setShowModal] = useState(initialModalState);

  useEffect(() => {
    if (status && status === "success") {
      callback();
    }
  }, [status]);

  const ModalLookup = {
    [dropdownActions.BULK_DELETE_TEAM]: (
      <BulkDeleteTeamModal
        handleClose={callback}
        data={data}
        deleteFn={mutate}
        isLoading={isPending}
      />
    ),
  };
  const { isShow, modalType } = showModal;
  return (
    <div className="w-64">
      <Button
        height="h-10"
        type="button"
        background="danger"
        onClick={() =>
          setShowModal({
            isShow: true,
            modalType: dropdownActions.BULK_DELETE_TEAM,
            elementData: {},
          })
        }
      >
        {isPending ? <Spinner /> : `Delete ${data?.length} team(s) -`}
      </Button>
      {isShow ? (
        <Modal
          handleClose={callback}
          headerComponent={null}
          footerComponent={null}
          shouldCloseOnClickOutside={false}
        >
          {ModalLookup[modalType]}
        </Modal>
      ) : null}
    </div>
  );
};

const NoRecordItem = () => {
  return (
    <div className="w-full grid place-content-center text-grey-600 gap-3 ">
      <div className="flex items-center justify-center flex-col h-[calc(100vh_-_309px)]">
        <NoRecord />
        <span className="text-grey-600">
          You have no teams added, add a new team now
        </span>
      </div>
    </div>
  );
};
const SepratorItem = ({ list = [], setShow = () => null }) => {
  const { user } = useAuth();
  const result =
    list?.map((option) => {
      return (
        <div key={option?.id} className="flex items-center gap-3">
          <Avatar
            inGroup={true}
            name={`${option?.first_name} ${option?.last_name}`}
            mediaUrl={`${process.env.REACT_APP_BASE_API_URL}${
              user?.company_uuid || user?.uuid
            }/profile/${option?.profile_pic}`}
          />
          <EllipsisTextWithTooltip
            string={`${option?.first_name} ${option?.last_name ?? ""}`}
            charLength={20}
            withTooltip={false}
          />
        </div>
      );
    }) ?? null;

  return (
    <div>
      <div className="absolute right-1.5 top-1.5">
        <button type="button" onClick={() => setShow(false)}>
          <X className="text-white w-4 h-4" />
        </button>
      </div>

      <div className="flex flex-col gap-2 ">{result}</div>
    </div>
  );
};

const filterInitialValues = {
  filter: [
    {
      name: "",
      a_filter_type: "",
      placeholder: "Enter name",
      type: "text",
      shouldFilterOptionDisabled: false,
      label: "Name",
      filterName: "name",
      position: 1,
    },
  ],
};

function Teams() {
  const [showModal, setShowModal] = useState(initialModalState);
  const { mutate: getMemberList, data: memberListdata } = useMemberList();
  const [memberList, setMemberList] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [filters, setFilters] = useState([]);
  const { user = {} } = useAuth();
  const { t } = useTranslation();

  useEffect(() => {
    if (
      !memberListdata?.data?.data &&
      !memberListdata?.data?.data?.length > 0
    ) {
      getMemberList();
    } else {
      setMemberList(memberListdata?.data?.data);
    }
  }, [memberListdata?.data?.data]);

  const handleCloseModal = () => {
    setShowModal(initialModalState);
  };

  const { mutate, isPending: isDeleting } = useTeamDelete({
    onSuccessFn: () => handleCloseModal(),
    onErrorFn: () => null,
  });

  const handleDropListClick = ({ value = "" }, element = {}) => {
    if (value) {
      setShowModal({ isShow: true, modalType: value, elementData: element });
    }
  };

  const checkboxSelectableFn = () => {
    return true;
  };

  const initialColumns = useMemo(
    () => [
      {
        accessorKey: "check",
        header: (props) => {
          return (
            <input
              className="checked:bg-green checked:hover:bg-green checked:active:bg-green checked:focus:bg-green focus:outline-none focus:ring-1 focus:ring-green"
              type="checkbox"
              checked={props?.table?.getIsAllRowsSelected()}
              onChange={props?.table?.getToggleAllRowsSelectedHandler()}
            />
          );
        },
        cell: (props) => {
          return (
            <input
              key={props?.row?.id}
              className="checked:bg-green checked:hover:bg-green checked:active:bg-green checked:focus:bg-green focus:outline-none focus:ring-1 focus:ring-green"
              type="checkbox"
              checked={props?.row?.getIsSelected()}
              onChange={props?.row?.getToggleSelectedHandler()}
            />
          );
        },
        meta: {
          colType: "action",
          width: "5%",
          hasAccess: [OWNER, ADMIN].includes(user?.role_id),
        },
      },
      {
        accessorKey: "name",
        header: () => t("overview.name"),
        meta: {
          isSortable: true,
        },
      },
      {
        accessorKey: "members",
        header: () => t("overview.members"),
        cell: (props) => (
          <AvatarGroup
            id={props?.row?.id}
            options={props?.getValue()}
            accessorKey="first_name"
            sepratorComponent={SepratorItem}
          />
        ),
      },
    ],
    []
  );

  const tableActions = [
    {
      id: 1,
      label: t("overview.createTeam"),
      access: [OWNER, ADMIN]?.includes(user?.role_id),
      component: (
        <div className=" w-64">
          <Button
            type="button"
            height="h-10"
            onClick={() =>
              setShowModal({
                isShow: true,
                modalType: dropdownActions.UPSERT_TEAM,
              })
            }
          >
            {t("overview.createTeam")}
          </Button>
        </div>
      ),
    },
  ];
  const ModalLookup = {
    [dropdownActions.UPSERT_TEAM]: (
      <CreateTeamModal
        showModal={showModal}
        handleClose={handleCloseModal}
        memberList={memberList}
      />
    ),
    [dropdownActions.DELETE_TEAM]: (
      <DeleteTeamModal
        showModal={showModal}
        handleClose={handleCloseModal}
        deleteFn={mutate}
        isLoading={isDeleting}
      />
    ),
    [dropdownActions.INVITE_TO_TEAM]: (
      <InviteTeamToModal
        showModal={showModal}
        handleClose={handleCloseModal}
        memberList={memberList}
      />
    ),
  };

  const columns = [OWNER, ADMIN]?.includes(user?.role_id)
    ? [
        ...initialColumns,
        {
          accessorKey: "action",
          header: () => "",
          cell: (props) => {
            const element = props?.row?.original || {};
            return (
              <Dropdown
                menuWidth="w-max"
                closeOnClickOutside={true}
                dropList={{
                  component: ({ item }) => {
                    const Icon = item?.icon ?? null;
                    const del = item?.type === "del" ? "text-danger" : "";

                    return (
                      <span
                        className={`w-full h-full flex  justify-start gap-2 items-center py-2 px-3 ${del}`}
                        onClick={() => handleDropListClick(item, element)}
                      >
                        {Icon} {item?.title}
                      </span>
                    );
                  },
                  data: [
                    {
                      id: 1,
                      title: t("overview.editTeam"),
                      value: dropdownActions.UPSERT_TEAM,
                      icon: <Edit className="text-grey-700" />,
                    },
                    {
                      id: 2,
                      title: t("overview.inviteTeam"),
                      value: dropdownActions.INVITE_TO_TEAM,
                      icon: <Mail className="text-grey-700" />,
                    },
                    {
                      id: 3,
                      title: t("overview.deleteTeam"),
                      value: dropdownActions.DELETE_TEAM,
                      icon: <Trash className=" text-danger " />,
                      type: "del",
                    },
                  ],
                }}
                showcaret={false}
              >
                <div className="cursor-pointer w-1">
                  <MenuIcon className="text-grey-700" />
                </div>
              </Dropdown>
            );
          },
          meta: {
            colType: "action",
            width: "5%",
          },
        },
      ]
    : initialColumns;

  const bulkActions = [
    {
      id: 1,
      component: BulkDeleteComponent,
    },
  ];

  const { isShow, modalType } = showModal;

  return (
    <>
      <TableManager
        fetcherFn={teamListing}
        fetcherKey={"teamListing"}
        searchPlaceholder={t("overview.searchByName")}
        searchLabel={t("overview.manageTeams")}
        isSearchable={true}
        shouldFilter={true}
        columns={columns}
        setOpenFilter={setOpenFilter}
        openFilter={openFilter}
        tableActions={tableActions}
        noRecordItem={NoRecordItem}
        filterInitialValues={filterInitialValues}
        setFilters={setFilters}
        filters={filters}
        bulkActions={bulkActions}
        checkboxSelectableFn={checkboxSelectableFn}
      />
      {isShow ? (
        <Modal
          handleClose={handleCloseModal}
          headerComponent={null}
          footerComponent={null}
          shouldCloseOnClickOutside={false}
        >
          {ModalLookup[modalType]}
        </Modal>
      ) : null}
    </>
  );
}

export default Teams;
