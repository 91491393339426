import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { formatDateTime } from "shared/resources";
import { dropdownActions, initialModalState } from "./constants";
import { Play } from "assets/images";
import { callSessionLogs } from "api";
import { Modal, PlayAudioModal, TableManager } from "components";

function SessionLogs() {
  const { id } = useParams();
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(initialModalState);

  const columns = useMemo(
    () => [
      //   {
      //     accessorKey: "check",
      //     header: (props) => {
      //       return (
      //         <input
      //           className="checked:bg-green checked:hover:bg-green checked:active:bg-green checked:focus:bg-green focus:outline-none focus:ring-1 focus:ring-green"
      //           type="checkbox"
      //           checked={props?.table?.getIsAllRowsSelected()}
      //           onChange={props?.table?.getToggleAllRowsSelectedHandler()}
      //         />
      //       );
      //     },
      //     cell: (props) => {
      //       return (
      //         <input
      //           key={props?.row?.id}
      //           className="checked:bg-green checked:hover:bg-green checked:active:bg-green checked:focus:bg-green focus:outline-none focus:ring-1 focus:ring-green"
      //           type="checkbox"
      //           checked={props?.row?.getIsSelected()}
      //           onChange={props?.row?.getToggleSelectedHandler()}
      //         />
      //       );
      //     },
      //     meta: {
      //       colType: "action",
      //       width: "5%",
      //       hasAccess: [OWNER, ADMIN].includes(user?.role_id),
      //     },
      //   },
      {
        accessorKey: "created_at",
        header: () => t("overview.date"),
        cell: (props) => {
          const [date, time] = formatDateTime(props?.getValue());
          return (
            <span>
              {date}&nbsp;&#183;&nbsp;{time}
            </span>
          );
        },
      },
      {
        accessorKey: "from",
        header: () => t("overview.from"),
      },
      {
        accessorKey: "to",
        header: () => t("overview.to"),
      },
      {
        accessorKey: "status",
        header: () => t("overview.status"),
        cell: (props) => {
          const logs = props?.row?.original || {};
          return (
            <p className="uppercase font-medium">
              {logs.status === 1 ? (
                <p className="text-green-400">{t("overview.success")}</p>
              ) : (
                <p className="text-danger uppercase">{t("overview.failed")}</p>
              )}
            </p>
          );
        },
      },
      {
        accessorKey: "duration",
        header: () => t("overview.duration"),
      },
      {
        accessorKey: "action",
        header: () => "Play Recording",
        cell: (props) => {
          const element = props?.row?.original || {};
          return (
            <div className="flex gap-3">
              {element.recording_file ? (
                <span
                  className="text-green cursor-pointer"
                  onClick={() => {
                    setShowModal({
                      isShow: true,
                      modalType: dropdownActions.PLAY_RECORDING,
                      elementData: element,
                      type: "center",
                    });
                  }}
                >
                  <Play className="w-6 h-6" />
                </span>
              ) : (
                <p>---</p>
              )}
            </div>
          );
        },
        meta: {
          colType: "action",
          width: "5%",
        },
      },
    ],
    [id]
  );
  const { isShow, type } = showModal;
  function handleCloseModal() {
    setShowModal(initialModalState);
  }
  return (
    <>
      <TableManager
        {...{
          fetcherKey: "callSessionLogs",
          fetcherFn: callSessionLogs,
          columns,
          showPagination: true,
          bulkActionsAccKey: "uuid",
          back: true,
          extraParams: {
            session_uuid: id,
          },
        }}
      />
      {isShow && type === "center" ? (
        <Modal
          handleClose={handleCloseModal}
          headerComponent={null}
          footerComponent={null}
          shouldCloseOnClickOutside={false}
        >
          <PlayAudioModal
            type="recording"
            fileNameAccessorKey={"recording_file"}
            showModal={showModal}
            handleClose={handleCloseModal}
            customLabel="Recording"
          />
        </Modal>
      ) : null}
    </>
  );
}

export default SessionLogs;
