import React, { useState } from "react";
import { Button } from "components";
import { ArrowLeft } from "lucide-react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const StepTwo = ({ previous }) => {
  const [isLoading, setIsLoading] = useState(false);

  const [botName, setBotName] = useState("");

  const navigate = useNavigate();
  const handleCreateBot = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      toast.success("Bot created successfully!");
      const whatsapp =
        localStorage.getItem("whatsapp") &&
        JSON.parse(localStorage.getItem("whatsapp"));
      localStorage.setItem(
        "whatsapp",
        JSON.stringify({
          linked: true,
          botId: botName,
          bots: whatsapp.bots ? [...whatsapp.bots, botName] : [botName],
        })
      );
      navigate("/flow");
    }, 5000);
  };

  return (
    <div className="w-full">
      <div className="flex justify-between items-center w-full">
        <div
          onClick={previous}
          className="flex text-black/50 text-sm items-center gap-3 cursor-pointer"
        >
          <ArrowLeft />
          <p className="mb-1">Back</p>
        </div>
        <p className="font-medium text-black/80">Create New Bot</p>
        <div className="w-[150px]">
          <Button onClick={handleCreateBot} disabled={isLoading}>
            {isLoading ? (
              <span className="flex items-center gap-2">
                <span className="loader w-4 h-4 border-2 border-t-transparent border-black/50 rounded-full animate-spin" />
                Loading...
              </span>
            ) : (
              "Create Bot"
            )}
          </Button>
        </div>
      </div>
      <div className="font-bold text-base text-center my-6">
        <p>Final Step</p>
      </div>
      <form className="flex flex-col gap-8 my-8 justify-center w-[500px] m-auto">
        <div className="flex gap-2 flex-col">
          <label className="text-base text-left text-black/80 font-medium">
            Bot Name
          </label>
          <input
            type="text"
            value={botName}
            onChange={(event) => setBotName(event.target.value)}
            className="w-full px-4 py-2 rounded-md border border-black/20 focus:outline-none focus:border-black/30"
          />
        </div>
        <div className="flex gap-2 flex-col">
          <label className="text-base text-left text-black/80 font-medium">
            Bot Description
          </label>
          <textarea
            className="w-full px-4 py-2 rounded-md border border-black/20 focus:outline-none focus:border-black/30"
            rows={3}
          />
        </div>
      </form>
    </div>
  );
};

export default StepTwo;
