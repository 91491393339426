import { formatTime } from "shared/resources";

const Right = ({ message }) => {
  return (
    <div className="flex flex-col relative items-end gap-2 mt-4">
      <div className="flex items-center gap-2.5">
        <div className="bg-green text-white rounded px-4 py-2">
          {message?.metadata?.body}
        </div>
        {message.from !== "zigi" && (
          <div className="w-[38px] h-[38px]">
            <img
              src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8Z2lybHN8ZW58MHx8MHx8fDA%3D"
              alt="ss"
              className="rounded-full w-[38px] h-[38px] object-cover "
            />
          </div>
        )}
      </div>
      <div className="flex items-center gap-2.5">
        <div className="w-[38px]"></div>
        <div className="text-sm font-normal text-grey-600">
          {message?.created_at ? formatTime(message?.created_at) : "9:41 PM"}
        </div>
      </div>
    </div>
  );
};

export default Right;
