import React, { useContext, useEffect, useMemo, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  Card,
  Chevron,
  DotsNine,
  NotificationBell,
  Prohibit,
  SignOut,
  UserCircle,
  X,
} from "assets/images";
import { Logo } from "assets/images/signup";
import {
  ADMIN,
  MEMBER,
  NG_CURRENCY_SYMBOL,
  OWNER,
  getToken,
  navigationArr,
} from "shared/resources";
import Dropdown from "components/Dropdown";
import Modal from "components/Modal";
import WelcomeFonuModal from "pages/Dashboard/WelcomeFonuModal";
import { PhoneSystem } from "layouts";
import Avatar from "components/Avatar";
import { useAuth } from "hooks/useAuth";
import { SipSocketContext } from "hooks/useSip/SipSocketContext";
import Tooltip from "components/Tooltip";
import { useWalletDetails } from "hooks";
import axios from "axios";
import { initialModalState } from "pages/Dashboard/constants";
import { Button, Skeletons, Spinner } from "components";
import PaymentConfirmationModal from "components/PaymentConfirmationModal";
import { useForm, Controller } from "react-hook-form";
import useChangePlan from "hooks/useChangePlan";
import useSavedCards from "hooks/useSavedCards";
import {
  CardItem,
  PaymentMethodArray,
} from "pages/PhoneNumbers/PurchaseNumberModal/NumberDetail";
import { useTranslation } from "react-i18next";
import "i18n";

export function handleSipStatus(_status) {
  if (_status === "registered") {
    return (
      <Tooltip title="Connected" position="b">
        <span className="inline-flex h-3 w-3 rounded-full bg-green-400"></span>
      </Tooltip>
    );
  } else if (_status === "away") {
    return (
      <Tooltip title="Away" position="b">
        <span className="inline-flex h-3 w-3 rounded-full bg-danger"></span>
      </Tooltip>
    );
  }

  return (
    <Tooltip title="Disconnected" position="b">
      <span className="inline-flex h-3 w-3 rounded-full bg-grey-500"></span>
    </Tooltip>
  );
}

function DropdownList({ item }) {
  const Icon = item?.icon;
  return (
    <div
      className="w-full flex  items-center gap-3 py-2 px-3 whitespace-nowrap"
      onClick={item?.onclick}
    >
      {Icon} {item?.title}
    </div>
  );
}

export default function Header() {
  const { user, clearUser, setUser } = useAuth();
  const userRole = user?.role_id;
  const [isDialpadOpen, openDialpad] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [sidebarList, setSidebarList] = useState([]);
  const { data: walletDetails } = useWalletDetails();
  const { socket = {} } = useContext(SipSocketContext);
  const searchParam = new URLSearchParams(window?.location?.search);
  const navigate = useNavigate();
  const showWelcomeModal = searchParam?.get("current") === "from-base-origin";
  const [expiredSubs, setExpiredSubs] = useState([]);
  const [showModal, setShowModal] = useState(initialModalState);
  const [selectedSub, setSelectedSub] = useState(null);
  const { cardListing, isLoading } = useSavedCards();
  const [showStatusModal, setStatusModal] = useState(false);
  const { t, i18n } = useTranslation();

  const { control, handleSubmit, setValue, watch, getValues } = useForm({
    defaultValues: {
      payment_type: "NEW_CARD",
      is_saved_card: "N",
      card_uuid: "",
    },
    mode: "onChange",
  });
  // const toggleLanguage = () => {
  //   const newLang = i18n.language === "en" ? "fr" : "en";
  //   i18n.changeLanguage(newLang);
  // };
  useEffect(() => {
    if (
      cardListing &&
      cardListing?.length > 0 &&
      watch("payment_type") === "SAVED_CARD"
    ) {
      const defaultCard = cardListing?.find((item) => item?.is_default === "Y");
      setValue("card_uuid", defaultCard?.uuid || "");
    }
  }, [cardListing?.length, watch("payment_type")]);

  useEffect(() => {
    fetchExpiredSubscriptions();
  }, []);

  const handleStatusModal = () => {
    setStatusModal(!showStatusModal);
  };

  useEffect(() => {
    const open = searchParam.get("open");
    const selectedSub = searchParam.get("selectedSub");
    if (open && selectedSub) {
      setSelectedSub(JSON.parse(selectedSub));
      setShowModal({
        modalType: open,
        isShow: true,
        elementData: {},
        type: "center",
      });
    }
  }, [searchParam]);

  const fetchExpiredSubscriptions = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_API_URL}subscriptions/expired-subscriptions`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
        },
      }
    );
    setExpiredSubs(response.data?.data);
  };

  function handleCloseWelcomeModal() {
    if (window.location.pathname === "/") {
      navigate("/overview");
    } else {
      navigate(window.location.pathname);
    }
  }

  useEffect(() => {
    const result = navigationArr?.filter(({ access }) =>
      access?.includes(userRole)
    );
    setSidebarList(result);
  }, [userRole]);

  const { isPending: isPlanPending, mutate: changePlanMutate } = useChangePlan({
    handleSuccess: () => {
      window.location.reload();
    },
  });

  const ModalLookup = useMemo(() => {
    let price = 0;
    if (expiredSubs?.features && expiredSubs?.dids) {
      const features = JSON.parse(expiredSubs.features);
      const featureCost = Number(features[0]?.value);
      const planMultiplier = expiredSubs.duration === "MONTHLY" ? 1 : 12;

      // Calculate the base plan price
      price = featureCost * planMultiplier;

      // Calculate the total DID costs and add to the base price
      const didCost = expiredSubs.dids.reduce((total, did) => {
        const didMultiplier = did?.buy_duration === "MONTHLY" ? 1 : 12;
        return total + Number(did?.monthly_cost) * didMultiplier;
      }, 0);

      // Final price with DID costs included
      price = (price + didCost).toFixed(2);
    }
    return {
      SUBSCRIPTION_RENEWAL: (
        <div className="">
          <div className="flex justify-between items-start pt-4 px-4">
            <div className="flex flex-col gap-1">
              <div className="text-4xl font-semibold text-black">
                Fonu {selectedSub?.plan_name} Plan Renewal
              </div>
              <div className="text-sm  text-grey-700">
                You&apos;re about to renew your FonuCX subscription. Click on
                the payment button below to pay.
              </div>
            </div>
            <button
              type="button"
              onClick={() => setShowModal(initialModalState)}
            >
              <X className="text-grey-600 w-6 h-6" />
            </button>
          </div>
          <div
            style={{ margin: "20px 0" }}
            className="  px-4 pb-6 w-full xxl:max-h-full xl:max-h-full lg:max-h-full  md:overflow-auto md:max-h-56"
          >
            <div className="text-4xl font-semibold">
              {NG_CURRENCY_SYMBOL}
              {price}
              &nbsp;
              <span className="text-2xl text-grey-700 capitalize">
                {user?.get_company_details?.plan_duration}
              </span>
            </div>
          </div>
          <div className="flex w-full gap-5 mt-8 px-4">
            {PaymentMethodArray.map((item) => {
              return (
                <label
                  htmlFor={`payment_type_${item?.type}`}
                  key={item?.type}
                  className="flex items-center gap-1 font-medium text-grey-800 cursor-pointer"
                >
                  <Controller
                    name={"payment_type"}
                    control={control}
                    render={({ field }) => {
                      return (
                        <input
                          className="checked:bg-green checked:hover:bg-green checked:active:bg-green checked:focus:bg-green focus:outline-none focus:ring-1 focus:ring-green"
                          type="radio"
                          {...field}
                          id={`payment_type_${item?.type}`}
                          value={item?.type}
                          checked={item?.type === watch("payment_type")}
                        />
                      );
                    }}
                  />
                  {item?.label}
                </label>
              );
            })}
          </div>
          {watch("payment_type") === "SAVED_CARD" ? (
            <div className="w-full px-4 mt-4 mb-3 gap-2 flex items-center max-w-[600px] overflow-auto flex-col max-h-28">
              {isLoading
                ? Array.from({ length: 3 }, (_, i) => i + 1).map((val) => {
                    return (
                      <div className="w-full" key={val}>
                        <Skeletons height="h-14" />
                      </div>
                    );
                  })
                : cardListing?.length > 0
                ? cardListing?.map((card) => (
                    <CardItem
                      key={card?.uuid}
                      data={card}
                      setSelectedCard={(val) => setValue("card_uuid", val)}
                      selectedCard={watch("card_uuid")}
                    />
                  ))
                : t("overview.noSavedCards")}
            </div>
          ) : null}
          {watch("payment_type") === "NEW_CARD" ? (
            <div className="w-full px-4 mt-4 mb-10 gap-2 flex items-center">
              <input
                className="checked:bg-green checked:hover:bg-green checked:active:bg-green checked:focus:bg-green focus:outline-none focus:ring-1 focus:ring-green"
                type="checkbox"
                id="is_saved_card"
                checked={watch("is_saved_card") === "Y"}
                onChange={(e) => {
                  setValue("is_saved_card", e.target.checked ? "Y" : "N");
                }}
              />
              <label className="cursor-pointer" htmlFor="is_saved_card">
                {t("overview.futureQuestion")}
              </label>
            </div>
          ) : null}
          <div className="mt-4  flex items-center justify-end gap-2 px-4 pb-4">
            <Button
              width="w-[191px]"
              type="button"
              onClick={() => {
                if (watch("payment_type") === "NEW_CARD") {
                  setShowModal({
                    modalType: "PLAN_PURCHASE_CONFIRMATION",
                    isShow: true,
                    elementData: {},
                    type: "center",
                  });
                } else {
                  const values = getValues();
                  changePlanMutate({
                    ...values,
                    plan_uuid: expiredSubs?.uuid,
                    payment_type: values?.payment_type,
                    is_saved_card: values?.is_saved_card,
                    plan_action: "Renew",
                    plan_type: expiredSubs?.duration,
                    source: values?.source,
                  });
                }
              }}
            >
              <div className="flex w-fit px-5   justify-center items-center">
                {isPlanPending ? <Spinner /> : t("pnb.payNow")}
              </div>
            </Button>
          </div>
        </div>
      ),
      PLAN_PURCHASE_CONFIRMATION: (
        <PaymentConfirmationModal
          handleClose={() => {
            setShowModal({
              isShow: true,
              modalType: "PLAN_RENEWAL",
              type: "center",
            });
          }}
          continueCallBack={handleSubmit((values) => {
            changePlanMutate({
              plan_uuid: expiredSubs?.uuid,
              payment_type: values?.payment_type,
              is_saved_card: values?.is_saved_card,
              plan_action: "Renew",
              plan_type: expiredSubs?.duration,
              source: values?.source,
            });
          })}
          setValue={setValue}
        />
      ),
    };
  }, [
    selectedSub,
    expiredSubs,
    watch("is_saved_card"),
    watch("payment_type"),
    watch("card_uuid"),
    isPlanPending,
  ]);

  const { _status = "" } = socket;
  const { isShow, modalType, type } = showModal;

  const [statusData, setStatusData] = useState([
    {
      value: "unavailable",
      title: t("overview.setAsUnavailable"),
    },
    {
      value: "away",
      title: t("overview.setAsAway"),
    },
  ]);

  useEffect(() => {
    if (user) {
      switch (user.default_status) {
        case "unavailable":
          setStatusData([
            { title: t("overview.setAsAvailable"), value: "online" },
            { title: t("overview.setAsAway"), value: "away" },
          ]);
          break;
        case "away":
          setStatusData([
            { title: t("overview.setAsAvailable"), value: "online" },
            { title: t("overview.setAsUnavailable"), value: "unavailable" },
          ]);
          break;
        default:
          setStatusData([
            {
              title: t("overview.setAsUnavailable"),
              value: "unavailable",
            },
            {
              title: t("overview.setAsAway"),
              value: "away",
            },
          ]);
      }
    }
  }, [user]);

  const handleStatusChange = async (value) => {
    setUser({ ...user, default_status: value });
    await axios.post(
      `${process.env.REACT_APP_BASE_API_URL}member/update-default-status`,
      {
        status: value,
      },
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
        },
      }
    );
  };

  return (
    <>
      {expiredSubs && expiredSubs?.status === "sub_about_to_expire" ? (
        <div className="bg-[#eed202] py-5 flex h-4 items-center justify-center border-top-grey xxl:px-7 xl:px-7 lg:px-7 md:px-7 sm:px-3 xs:px-3">
          {t("overview.subExpired")}&nbsp;&nbsp;
          <span
            className="cursor-pointer underline italic"
            onClick={() => {
              setShowModal({
                modalType: "SUBSCRIPTION_RENEWAL",
                isShow: true,
                type: "center",
              });
            }}
          >
            <b>{t("pnb.payNow")}</b>
          </span>
        </div>
      ) : (
        expiredSubs?.status === "sub_expired" && (
          <div className="bg-[#eed202] py-5 flex h-4 items-center justify-center border-top-grey xxl:px-7 xl:px-7 lg:px-7 md:px-7 sm:px-3 xs:px-3">
            {t("overview.subExpired")}&nbsp;&nbsp;
            <span
              className="cursor-pointer underline italic"
              onClick={() => {
                setShowModal({
                  isShow: true,
                  modalType: "SUBSCRIPTION_RENEWAL",
                  type: "center",
                });
              }}
            >
              <b>{t("pnb.payNow")}</b>
            </span>
          </div>
        )
      )}

      {isShow && type === "center" ? (
        <Modal
          handleClose={() => {
            setShowModal(initialModalState);
          }}
          headerComponent={null}
          footerComponent={null}
          shouldCloseOnClickOutside={false}
        >
          {ModalLookup[modalType]}
        </Modal>
      ) : null}

      <div className="bg-black-600  py-5 flex items-center justify-between border-top-grey xxl:px-7 xl:px-7 lg:px-7 md:px-7 sm:px-3 xs:px-3">
        <div className=" flex text-base items-center xxl:gap-14 xl:gap-10 lg:gap-8 md:gap-6 sm:gap-8 xs:gap-6 xxl:justify-between xl:justify-start lg:justify-start md:justify-start sm:justify-between xs:justify-between   xxl:w-auto xl:w-auto lg:w-auto md:w-auto  sm:w-auto xs:w-[calc(100%_-_30px)]">
          <Logo className="w-20" />

          <div className="absolute top-5 right-0 flex items-center xxl:hidden  xl:hidden lg:hidden  md:hidden sm:block  xs:block ">
            <button
              type="button"
              className="relative inline-flex items-center justify-center rounded-md p-2 text-white  "
              aria-controls="mobile-menu"
              aria-expanded="false"
              onClick={() => setShowDropdown((prev) => !prev)}
            >
              <span className="absolute -inset-0.5"></span>
              <span className="sr-only">{t("overview.openMainMenu")}</span>

              <svg
                className="block h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
              </svg>

              <svg
                className="hidden h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <div
            className={`absolute bg-black-600/30 bg-opacity-30 top-16 left-0 w-full p-5 flex-col xs:gap-4 sm:gap-6 z-10 ${
              showDropdown ? "sm:flex xs:flex" : " sm:hidden xs:hidden"
            } sm:ml-6`}
          >
            {sidebarList?.map((nav) => {
              const isActive = window.location.href.includes(nav?.parent);
              const translatedLabel = t(nav?.label);
              if (
                [
                  t("navigation.teams"),
                  t("navigation.phoneNumbers"),
                  t("navigation.calls"),
                  t("navigation.overview"),
                  t("navigation.bulkDialContacts"),
                ].includes(translatedLabel)
              ) {
                return (
                  <div
                    key={nav?.id}
                    className="flex  items-center group relative  "
                    onClick={() => setShowDropdown((prev) => !prev)}
                  >
                    <NavLink
                      to={nav?.path}
                      className={
                        isActive
                          ? "text-white xxl:text-[17.5px] xl:text-[17.5px] lg:text-base md:text-base font-medium cursor-pointer xs:text-green sm:border-none xs:border-b sm:text-white xs:border-green xs:w-full sm:w-auto xs:pb-4 sm:pb-0"
                          : "text-white/90 cursor-pointer xxl:text-[17.5px] xl:text-[17.5px] lg:text-base md:text-base group-hover:text-white sm:border-none xs:border-b xs:border-grey-100 xs:w-full sm:w-auto xs:pb-4 sm:pb-0"
                      }
                    >
                      {translatedLabel}
                    </NavLink>
                    <div
                      className={`${
                        isActive
                          ? "xs:invisible sm:visible"
                          : "xs:invisible sm:invisible"
                      } group-hover:visible w-full h-0.5 bg-green rounded-sm absolute top-7`}
                    ></div>
                  </div>
                );
              }
            })}
          </div>

          <div className=" items-center gap-4 xxl:hidden  xl:hidden lg:hidden  md:hidden sm:flex  xs:flex">
            <div
              className="xs:hidden sm:flex   items-center gap-1 justify-center xs:h-8 xs:w-8 md:h-10 md:w-10	xs:text-white sm:text-black cursor-pointer sm:bg-white xs:bg-black-600 rounded-full relative"
              onClick={() => openDialpad(true)}
            >
              <DotsNine />
              <div className="absolute top-0 right-0">
                {handleSipStatus(_status)}
              </div>
            </div>

            <NotificationBell className="text-white" />
            <div onClick={handleStatusModal} className="relative">
              <div className="relative">
                <Avatar
                  name={`${user?.first_name} ${user?.last_name}`}
                  mediaUrl={`${process.env.REACT_APP_BASE_API_URL}${
                    user?.company_uuid || user?.uuid
                  }/profile/${user?.profile_pic}`}
                />
                <div className="absolute left-7 bottom-[1px]">
                  {handleSipStatus(_status)}
                </div>
              </div>{" "}
              {showStatusModal && (
                <div className="absolute top-12 z-50 left-0 py-2 flex flex-col justify-center rounded-[12px] bg-white w-[150px] bg-opacity">
                  {statusData.map((status, index) => (
                    <p
                      key={index}
                      onClick={() => handleStatusChange(status.value)}
                      className={` flex flex-col px-2 cursor-pointer duration-500 text-[14px] hover:bg-grey-500 py-2 ${
                        index === 1 ? "border-none" : "border-b border-grey-500"
                      }`}
                    >
                      {status.title}
                    </p>
                  ))}
                </div>
              )}
            </div>
            <div className=" text-white ">
              <Dropdown
                caretComponent={() => <></>}
                extraClassName=" bg--black-200"
                closeOnClickOutside={true}
                dropList={{
                  component: ({ item }) => {
                    if (
                      item?.access?.length === 0 ||
                      !item.access.includes(user?.role_id)
                    ) {
                      return;
                    }
                    return <DropdownList key={item?.id} item={item} />;
                  },
                  data: [
                    {
                      title: t("profile.profile"),
                      value: "profile",
                      onclick: () => navigate("/profile/general"),
                      icon: <UserCircle className="text-grey-700" />,
                      access: [ADMIN, OWNER, MEMBER],
                      id: 1,
                    },
                    {
                      title: `${t("profile.plans")} & ${t("profile.billing")}`,
                      value: "planBilling",
                      onclick: () => navigate("/profile/pnb"),
                      icon: <Card className="text-grey-700" />,
                      access: [ADMIN, OWNER],
                      id: 2,
                    },
                    {
                      title: t("profile.blocklist"),
                      value: "blocklist",
                      onclick: () => navigate("/profile/blocklist"),
                      icon: <Prohibit className="text-grey-700" />,
                      access: [ADMIN, OWNER],
                      id: 3,
                    },
                    {
                      title: t("profile.logout"),
                      value: "logout",
                      onclick: clearUser,
                      icon: <SignOut className="text-grey-700" />,
                      access: [ADMIN, OWNER, MEMBER],
                      id: 4,
                    },
                  ],
                }}
                showcaret={true}
              >
                <div className="flex items-center gap-3 cursor-pointer">
                  {/* <Avatar
                    name={`${user?.first_name} ${user?.last_name}`}
                    mediaUrl={`${process.env.REACT_APP_BASE_API_URL}${
                      user?.company_uuid || user?.uuid
                    }/profile/${user?.profile_pic}`}
                  /> */}

                  <div className="flex flex-col gap-1">
                    <div className="flex items-center text-sm font-medium	 text-white">
                      {NG_CURRENCY_SYMBOL}
                      {walletDetails?.accountBalance || 0}
                    </div>
                  </div>
                </div>
              </Dropdown>
            </div>
            <div className="min-w-[70px] text-white  max-w-[70px] bg-green p-2 rounded-md flex items-center justify-center">
              <Dropdown
                caretComponent={Chevron}
                extraClassName="min-w-[80px]  max-w-[80px]  bg--black-200"
                closeOnClickOutside={true}
                dropList={{
                  component: ({ item }) => {
                    if (
                      item?.access?.length === 0 ||
                      !item.access.includes(user?.role_id)
                    ) {
                      return;
                    }
                    return <DropdownList key={item?.id} item={item} />;
                  },
                  data: [
                    {
                      title: "English",
                      value: "english",
                      onclick: () => i18n.changeLanguage("en"),
                      // icon: <UserCircle className="text-grey-700 w-5 h-5" />,
                      access: [ADMIN, OWNER, MEMBER],
                      id: 1,
                    },
                    {
                      title: "French",
                      value: "french",
                      onclick: () => i18n.changeLanguage("fr"),
                      // icon: <Card className="text-grey-700 w-[18px] h-[18px]" />,
                      access: [ADMIN, OWNER],
                      id: 2,
                    },
                  ],
                }}
                showcaret={true}
              >
                <div className="flex items-center gap-1 cursor-pointer">
                  <div className="flex flex-col gap-1">
                    {i18n.language === "en" ? "EN" : "FR"}
                  </div>
                </div>
              </Dropdown>
            </div>
          </div>

          <div className="flex items-center xxl:gap-8 xl:gap-6 lg:gap-6 md:gap-6  xxl:flex  xl:flex lg:flex  md:flex sm:hidden  xs:hidden">
            {sidebarList?.map((nav) => {
              const isActive = window.location.href.includes(nav?.parent);
              const translatedLabel = t(nav?.label);
              return (
                <div
                  key={nav?.id}
                  className={`flex justify-center items-center group relative`}
                >
                  <NavLink
                    to={nav?.path}
                    className={`${
                      nav?.label === "flow"
                        ? "text-transparent"
                        : isActive
                        ? "text-white xxl:text-base xl:text-base lg:text-sm md:text-xs font-medium cursor-pointer"
                        : "text-grey-800 cursor-pointer xxl:text-base xl:text-base lg:text-sm md:text-xs group-hover:text-white"
                    }`}
                  >
                    {translatedLabel}
                  </NavLink>
                  {nav?.label !== "flow" && (
                    <div
                      className={`${
                        isActive ? "visible" : "invisible"
                      } group-hover:visible w-3 h-0.5 bg-green rounded-sm absolute top-7`}
                    ></div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <div className=" items-center xxl:gap-6 xl:gap-4 lg:gap-3 md:gap-2 xxl:flex  xl:flex lg:flex  md:flex sm:hidden  xs:hidden">
          <div
            className="flex  items-center gap-1 justify-center w-auto h-auto 	 text-black cursor-pointer bg-white px-3 py-2  rounded-full"
            onClick={() => openDialpad(true)}
          >
            <DotsNine />
            <div className="xxl:text-base  xl:text-sm  lg:text-base  md:text-xs  flex font-medium  items-center gap-1">
              {t("overview.dialPad")} {handleSipStatus(_status)}
            </div>
          </div>

          <NotificationBell className="text-white" />
          <div onClick={handleStatusModal} className="relative">
            <div className="relative">
              <Avatar
                name={`${user?.first_name} ${user?.last_name}`}
                mediaUrl={`${process.env.REACT_APP_BASE_API_URL}${
                  user?.company_uuid || user?.uuid
                }/profile/${user?.profile_pic}`}
              />
              <div className="absolute left-7 bottom-[1px]">
                {handleSipStatus(
                  user?.default_status === "online"
                    ? "registered"
                    : user?.default_status
                )}
              </div>
            </div>{" "}
            {showStatusModal && (
              <div className="absolute top-12 z-50 left-0 py-2 flex flex-col justify-center rounded-[12px] bg-white w-[150px] bg-opacity">
                {statusData.map((status, index) => (
                  <p
                    key={index}
                    onClick={() => handleStatusChange(status.value)}
                    className={` flex flex-col px-2 cursor-pointer duration-500 text-[14px] hover:bg-grey-500 py-2 ${
                      index === 1 ? "border-none" : "border-b border-grey-500"
                    }`}
                  >
                    {status.title}
                  </p>
                ))}
              </div>
            )}
          </div>
          <div className="min-w-[80px] text-white  max-w-[80px]">
            <Dropdown
              caretComponent={Chevron}
              // extraClassName="min-w-[80px]  max-w-[80px]  bg--black-200"
              closeOnClickOutside={true}
              dropList={{
                component: ({ item }) => {
                  if (
                    item?.access?.length === 0 ||
                    !item.access.includes(user?.role_id)
                  ) {
                    return;
                  }
                  return <DropdownList key={item?.id} item={item} />;
                },
                data: [
                  {
                    title: t("profile.profile"),
                    value: "profile",
                    onclick: () => navigate("/profile/general"),
                    icon: <UserCircle className="text-grey-700 w-5 h-5" />,
                    access: [ADMIN, OWNER, MEMBER],
                    id: 1,
                  },
                  {
                    title: `${t("profile.plans")} & ${t("profile.billing")}`,
                    value: "planBilling",
                    onclick: () => navigate("/profile/pnb"),
                    icon: <Card className="text-grey-700 w-[18px] h-[18px]" />,
                    access: [ADMIN, OWNER],
                    id: 2,
                  },
                  {
                    title: t("profile.blocklist"),
                    value: "blocklist",
                    onclick: () => navigate("/profile/blocklist"),
                    icon: <Prohibit className="text-grey-700 w-5 h-5" />,
                    access: [ADMIN, OWNER],
                    id: 3,
                  },
                  {
                    title: t("profile.logout"),
                    value: "logout",
                    onclick: clearUser,
                    icon: <SignOut className="text-grey-700 w-5 h-5" />,
                    access: [ADMIN, OWNER, MEMBER],
                    id: 4,
                  },
                ],
              }}
              showcaret={true}
            >
              <div className="flex items-center gap-1 cursor-pointer">
                <div className="flex flex-col gap-1">
                  <div className="flex items-center text-sm font-medium	 text-white">
                    {NG_CURRENCY_SYMBOL}
                    {walletDetails?.accountBalance || 0}
                  </div>
                </div>
              </div>
            </Dropdown>
          </div>
          <div className="min-w-[70px] text-white  max-w-[70px] bg-green p-2 rounded-md flex items-center justify-center">
            <Dropdown
              caretComponent={Chevron}
              extraClassName="min-w-[80px]  max-w-[80px]  bg--black-200"
              closeOnClickOutside={true}
              dropList={{
                component: ({ item }) => {
                  if (
                    item?.access?.length === 0 ||
                    !item.access.includes(user?.role_id)
                  ) {
                    return;
                  }
                  return <DropdownList key={item?.id} item={item} />;
                },
                data: [
                  {
                    title: "English",
                    value: "english",
                    onclick: () => i18n.changeLanguage("en"),
                    // icon: <UserCircle className="text-grey-700 w-5 h-5" />,
                    access: [ADMIN, OWNER, MEMBER],
                    id: 1,
                  },
                  {
                    title: "French",
                    value: "french",
                    onclick: () => i18n.changeLanguage("fr"),
                    // icon: <Card className="text-grey-700 w-[18px] h-[18px]" />,
                    access: [ADMIN, OWNER, MEMBER],
                    id: 2,
                  },
                ],
              }}
              showcaret={true}
            >
              <div className="flex items-center gap-1 cursor-pointer">
                <div className="flex flex-col gap-1">
                  {i18n.language === "en" ? "EN" : "FR"}
                </div>
              </div>
            </Dropdown>
          </div>
        </div>
        {showWelcomeModal ? (
          <Modal
            handleClose={() => handleCloseWelcomeModal()}
            headerComponent={null}
            footerComponent={null}
            shouldCloseOnClickOutside={true}
          >
            <WelcomeFonuModal
              handleCloseWelcomeModal={handleCloseWelcomeModal}
            />
          </Modal>
        ) : null}
        <PhoneSystem isDialpadOpen={isDialpadOpen} openDialpad={openDialpad} />
      </div>
    </>
  );
}
