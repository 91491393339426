import { ArrowsLeftRight, MenuIcon, NoRecord } from "assets/images";
import { AsideModal, Button, Dropdown, Modal, TableManager } from "components";
import React, { useState } from "react";
import {
  NG_CURRENCY_SYMBOL,
  OWNER,
  formatDateTime,
  formatPhoneNumber,
} from "shared/resources";
import { useAuth } from "hooks/useAuth";
import { dropdownActions, initialModalState } from "../constants";
import PortNumberModal from "./PortNumberModal";
import { useTranslation } from "react-i18next";

const NoRecordItem = () => {
  const { t } = useTranslation();
  return (
    <div className="w-full grid place-content-center text-grey-600 gap-3 ">
      <div className="flex items-center justify-center flex-col sm:h-[calc(100vh_-_309px)] xs:h-[calc(100vh_-_400px)] ">
        <NoRecord />
        <span className="text-grey-600">{t("overview.noPortNum")}</span>
      </div>
    </div>
  );
};

const filterInitialValues = {
  filter: [
    {
      phone: "",
      a_filter_type: "",
      placeholder: "Enter phone",
      type: "text",
      shouldFilterOptionDisabled: false,
      label: "Phone",
      filterName: "phone",
      position: 1,
    },
  ],
};

const PortNumberListing = () => {
  const [showModal, setShowModal] = useState(initialModalState);
  const [openFilter, setOpenFilter] = useState(false);
  const [filters, setFilters] = useState([]);
  const { user } = useAuth();
  const { t } = useTranslation();

  const handleDropListClick = ({ value = "" }, element = {}) => {
    console.log(element);
    switch (value) {
      default:
        break;
    }
  };

  const columns = [
    {
      accessorKey: "created_at",
      header: () => t("overview.date"),
      cell: (props) => {
        const [date] = formatDateTime(props?.getValue());
        return date;
      },
    },
    {
      accessorKey: "did_number",
      header: () => t("overview.phoneNumber"),
      cell: (props) => formatPhoneNumber(props?.getValue()),
    },
    {
      accessorKey: "forward_call_actions",
      header: () => t("overview.businessHour"),
      cell: (props) => {
        const element = props?.getValue();
        return <div>{element ? "24 hours" : "---"}</div>;
      },
    },
    {
      accessorKey: "ns",
      header: () => t("overview.number"),
    },
    {
      accessorKey: "plan",
      header: () => t("overview.plan"),
    },

    {
      accessorKey: "setup_cost",
      header: () => t("overview.cost"),
      cell: (props) => `${NG_CURRENCY_SYMBOL}${props?.getValue()}`,
    },
    {
      accessorKey: "action",
      header: () => "",
      cell: (props) => {
        const element = props?.row?.original || {};
        const initialOptions = [
          //   {
          //     id: 1,
          //     title: 'Assign to',
          //     value: dropdownActions.ASSIGN_TO,
          //     icon: <UserGrey />,
          //   },
        ];

        return (
          <div className="flex items-center gap-2">
            <Dropdown
              menuWidth="w-max"
              closeOnClickOutside={true}
              dropList={{
                component: ({ item }) => {
                  const Icon = item?.icon ?? null;
                  return (
                    <span
                      className="w-full h-full flex  justify-start gap-2 items-center py-2 px-3  "
                      onClick={() => handleDropListClick(item, element)}
                    >
                      {Icon}
                      {item?.title}
                    </span>
                  );
                },
                data: initialOptions,
              }}
              showcaret={false}
            >
              <div className="cursor-pointer w-1">
                <MenuIcon />
              </div>
            </Dropdown>
          </div>
        );
      },
      meta: {
        colType: "action",
        width: "5%",
      },
    },
  ];

  const tableActions = [
    {
      id: 1,
      label: "Port number",
      access: [OWNER]?.includes(user?.role_id) || true,
      component: (
        <div className=" w-64">
          <Button
            extraClasses="gap-2"
            height="h-10"
            type="button"
            onClick={() => {
              setShowModal({
                isShow: true,
                modalType: dropdownActions.PORT_NUMBER_MODAL,
                type: "center",
              });
            }}
          >
            {t("overview.portExistingNumber")}
            <div className="text-white">
              <ArrowsLeftRight width={20} height={20} />
            </div>
          </Button>
        </div>
      ),
    },
  ];

  const ModalLookup = {
    [dropdownActions.PORT_NUMBER_MODAL]: (
      <PortNumberModal handleClose={handleCloseModal} />
    ),
  };

  function handleCloseModal() {
    setShowModal(initialModalState);
  }
  const { isShow, modalType, type } = showModal;

  return (
    <>
      <TableManager
        fetcherFn={() => null}
        fetcherKey={"getPortNumbers"}
        searchPlaceholder={t("search.searchByNNM")}
        searchLabel={t("search.manageNum")}
        isSearchable={true}
        shouldFilter={true}
        columns={columns}
        setOpenFilter={setOpenFilter}
        openFilter={openFilter}
        tableActions={tableActions}
        noRecordItem={NoRecordItem}
        filterInitialValues={filterInitialValues}
        setFilters={setFilters}
        filters={filters}
      />
      {isShow && type === "center" ? (
        <Modal
          handleClose={handleCloseModal}
          headerComponent={null}
          footerComponent={null}
          shouldCloseOnClickOutside={false}
        >
          {ModalLookup[modalType]}
        </Modal>
      ) : null}
      {isShow && type === "side" ? (
        <AsideModal
          handleClose={handleCloseModal}
          footerComponent={null}
          headerComponent={null}
          shouldCloseOnClickOutside={false}
        >
          {ModalLookup[modalType]}
        </AsideModal>
      ) : null}
    </>
  );
};

export default PortNumberListing;
