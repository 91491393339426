/* eslint-disable no-unused-vars */
import { Input } from "components";
import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import InboxListItem from "../InboxListItem";
import { useAuth } from "hooks/useAuth";
import axios from "axios";
import { getToken } from "shared/resources";
import { routes } from "api/routes";

const InboxList = () => {
  const [search, setSearch] = useState("");
  const { user } = useAuth();
  const [conversations, setConversations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filteredConversations, setFilteredConversations] = useState([]);

  const fetchConversations = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ZIGI_BASE_API_URL}${routes.LIST_CONVERSATION.URL}?companyUuid=${user?.company_uuid}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      setConversations(response.data);
      const filteredConversation = response.data
        .map(filterAndUpdateConversation)
        .filter((conversation) => {
          // If responder, exclude Zigi Bot conversations
          if (conversation.role !== "responder" && conversation.isZigiBot) {
            return false;
          }
          return true;
        });
      setFilteredConversations([...filteredConversation]);
    } catch (error) {
      console.error("Error fetching conversations:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchConversations();
  }, []);

  const filterAndUpdateConversation = (conversation) => {
    if (conversation.initiator === user.uuid) {
      // If the logged-in user is the initiator, leave as it is.
      return {
        ...conversation,
        displayName: "Zigi Bot",
        isZigiBot: true,
        role: "initiator",
      };
    } else {
      // If the logged-in user is the responder, replace "Zigi Bot" with the initiator's name.
      return {
        ...conversation,
        displayName:
          conversation?.metadata?.company_name ||
          conversation?.metadata?.name ||
          "Unknown User",
        isZigiBot: true,
        role: "responder",
      };
    }
  };

  useEffect(() => {
  }, [filteredConversations]);

  if (loading) return <div>Loading conversations...</div>;

  return (
    <div className="flex">
      <div className="bg-white  border-grey-300 border-r">
        <div className="pt-6 px-4 pb-4">
          <Input
            type="text"
            name={"search"}
            value={search}
            height="h-10"
            onWhitebg={true}
            onChange={(e) => setSearch(e.target.value)}
            extraClasses="pl-10 mb-0 placeholder:text-grey-600 placeholder:font-normal placeholder:text-sm "
            placeholder={"Search conversation"}
            withSearchIcon={true}
          />
        </div>
        <span className="flex border-grey-300 border-b p-0 w-full "></span>

        <div className="w-96 flex flex-col gap-2 bg-white pt-2.5 h-[calc(100vh_-_161px)] overflow-auto">
          <span>
            {Array.from({ length: 1 }, (_, i) => i).map((val) => {
              return <InboxListItem key={val} item={val} />;
            })}
          </span>
          <div>
            {user.company_uuid === "f583db32-2fe3-48e4-8955-019cd18cdb28" &&
            filteredConversations &&
            filteredConversations.length ? (
              filteredConversations.map((conversation) => (
                <InboxListItem
                  key={conversation.uuid}
                  item={conversation}
                  isZigiBot={conversation.isZigiBot}
                  filteredConversations={filteredConversations}
                  setFilteredConversations={setFilteredConversations}
                />
              ))
            ) : (
              <div>
                <InboxListItem isZigiBot={true} item={{ role: "initiator" }} />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="w-full">
        <Outlet />
      </div>
    </div>
  );
};

export default InboxList;
