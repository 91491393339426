import { yupResolver } from "@hookform/resolvers/yup";
import { X } from "assets/images";
import {
  Avatar,
  Button,
  CustomSelectServer,
  EllipsisTextWithTooltip,
  Input,
  Spinner,
} from "components";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { validationSchema } from "../constants";
import useCreateTeam from "hooks/useCreateTeam";

import useTeamUpdate from "hooks/useTeamUpdate";
import { getMemberListing } from "api";
import { useAuth } from "hooks/useAuth";
import { useTranslation } from "react-i18next";

const ItemComponent = ({ item }) => {
  const { user } = useAuth();
  return (
    <div className="flex items-center gap-3 ">
      <Avatar
        name={item?.first_name}
        mediaUrl={`${process.env.REACT_APP_BASE_API_URL}${
          user?.company_uuid || user?.uuid
        }/profile/${item?.profile_pic}`}
      />
      <EllipsisTextWithTooltip
        string={`${item?.first_name} ${item?.last_name ?? ""}`}
        charLength={20}
        position="b"
      />
    </div>
  );
};

const initialValues = {
  name: "",
  members: [],
};
const CreateTeamModal = ({ showModal = {}, handleClose = () => null }) => {
  const { elementData = {} } = showModal;
  const isEdit = elementData && Object.keys(elementData)?.length > 0;

  const handleSuccess = () => {
    handleClose();
  };
  const { mutateAsync: createTeam, isPending: creatingTeam } = useCreateTeam({
    handleSuccess,
  });
  const { mutateAsync: updateTeam, isPending: updatingTeam } = useTeamUpdate({
    handleSuccess,
  });

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: "onChange",
  });

  useEffect(() => {
    if (isEdit) {
      const { name = "", members = [] } = elementData;
      reset({
        name,
        members: members?.map((member) => member?.uuid),
      });
    }
  }, [elementData]);

  const onSubmit = async (values) => {
    const payload = values;
    if (isEdit) {
      payload.uuid = elementData?.uuid;
      await updateTeam(payload);
    } else {
      await createTeam(payload);
    }
  };
  const { t } = useTranslation();
  return (
    <div className="min-w-[500px]">
      <div className="flex justify-between items-start py-4 px-6">
        <div className="flex flex-col gap-1">
          <div className="text-xl font-semibold text-black">
            {isEdit
              ? `${t("overview.update")} ${elementData?.name ?? ""} ${t(
                  "overview.update"
                )}`
              : t("overview.createTeam")}
          </div>
          <div className="text-sm  text-grey-700">
            {isEdit ? t("overview.editInfo") : t("overview.enterInfo")}
          </div>
        </div>
        <button type="button" onClick={() => handleClose()}>
          <X className="text-grey-600 w-6 h-6" />
        </button>
      </div>
      <div className=" px-6 pb-5 pt-6 ">
        <form
          className="flex flex-col gap-[60px]"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="flex flex-col gap-4">
            <div>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    onWhitebg={true}
                    label={t("overview.name")}
                    error={errors?.name?.message}
                    maxLength={50}
                  />
                )}
              />
            </div>
            <div>
              <Controller
                name="members"
                control={control}
                render={({ field }) => (
                  <CustomSelectServer
                    {...field}
                    height="xxl:h-14 xl:h-12"
                    onWhitebg={true}
                    label={t("overview.members")}
                    disableCheckBoxCondition={(item) => item?.status === 0}
                    placeholder={t("overview.selectMember")}
                    error={errors?.members?.message}
                    ItemComponent={ItemComponent}
                    labelKey={"first_name"}
                    valueKey={"uuid"}
                    isMulti={true}
                    actionFn={getMemberListing}
                    queryKey={"getMemberListing"}
                  />
                )}
              />
            </div>
          </div>
          <Button disabled={creatingTeam || updatingTeam} type="submit">
            {creatingTeam || updatingTeam ? <Spinner /> : t("overview.submit")}
          </Button>
        </form>
      </div>
    </div>
  );
};

export default CreateTeamModal;
