import { Avatar, EllipsisTextWithTooltip, Skeletons } from "components";
import { useDebounce } from "hooks";
import { useAuth } from "hooks/useAuth";
import { useEffect, useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { getMemberListing } from "api";

const loader = (
  <ul>
    <li className="flex gap-3 items-center rounded-md p-1">
      <div className="w-10 h-10 rounded-full">
        <Skeletons type="circle" />
      </div>
      <div className="flex flex-col gap-1 w-full">
        <Skeletons />
      </div>
    </li>
    <li className="flex gap-3 items-center rounded-md p-1">
      <div className="w-10 h-10 rounded-full">
        <Skeletons type="circle" />
      </div>
      <div className="flex flex-col gap-1 w-full">
        <Skeletons />
      </div>
    </li>
    <li className="flex gap-3 items-center rounded-md p-1">
      <div className="w-10 h-10 rounded-full">
        <Skeletons type="circle" />
      </div>
      <div className="flex flex-col gap-1 w-full">
        <Skeletons />
      </div>
    </li>
  </ul>
);

const ItemComponent = ({
  item,
  currentIndex,
  itemIndex,
  handleClick = () => null,
}) => {
  const { user } = useAuth();
  const itemRef = useRef(null);

  useEffect(() => {
    if (currentIndex === itemIndex) {
      itemRef?.current?.scrollIntoView({ block: "end" });
    }
  }, [itemRef?.current, currentIndex]);

  return (
    <li
      ref={itemRef}
      className={`flex items-center gap-3 cursor-pointer rounded-md p-1 ${
        currentIndex === itemIndex ? "bg-green-200" : ""
      }`}
      onClick={() => handleClick(itemIndex)}
    >
      <Avatar
        name={item?.first_name}
        mediaUrl={`${process.env.REACT_APP_BASE_API_URL}${
          user?.company_uuid || user?.uuid
        }/profile/${item?.profile_pic}`}
      />
      <EllipsisTextWithTooltip
        string={`${item?.first_name} ${item?.last_name ?? ""}`}
        charLength={20}
        position="r"
      />
    </li>
  );
};

const InboxMentions = ({
  setMessage,
  message,
  setTryingToMention,
  textAreaRef,
}) => {
  const [searchMention, setSearchMention] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const debouncedValue = useDebounce(searchMention, 800);

  const { data: memberList, isLoading: memberListLoad } = useQuery({
    queryFn: () => getMemberListing({ search: debouncedValue }),
    queryKey: ["getMemberListingfromMentions", debouncedValue],
    refetchOnMount: false,
    select: (data) => data?.data?.data ?? [],
  });

  const handleKeyDown = (event) => {
    if (event.key === "ArrowDown") {
      event.preventDefault();
      setCurrentIndex((prevIndex) =>
        Math.min(
          prevIndex + 1,
          memberList.filter((value) =>
            value?.first_name
              ?.toLowerCase()
              .includes(searchMention?.toLowerCase())
          ).length - 1
        )
      );
    } else if (event.key === "ArrowUp") {
      event.preventDefault();
      setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    }
    if (event.key === "Enter") {
      setCurrentIndex((prevIndex) => {
        handleClick(prevIndex);
        return prevIndex;
      });
      event.preventDefault();
      event.stopPropagation();
    }
  };

  useEffect(() => {
    if (memberList?.length) {
      document.addEventListener("keydown", handleKeyDown);
      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [memberList?.length, memberListLoad]);

  useEffect(() => {
    if (message) {
      const lastToken = message?.trim()?.split(" ")?.pop();
      if (lastToken?.charAt(0) === "@") {
        setSearchMention(lastToken?.replace("@", ""));
      }
    }
  }, [message]);

  function handleClick(index) {
    if (memberListLoad || !memberList?.length) return;
    const name =
      memberList?.[index]?.first_name + " " + memberList?.[index]?.last_name;
    const currentValueTokens = textAreaRef?.current?.innerText?.split(" ");

    if (currentValueTokens && currentValueTokens.length === 0) {
      currentValueTokens[0] = "@" + name?.split(" ")?.join("&nbsp;");
    }

    if (currentValueTokens && currentValueTokens.length > 0) {
      currentValueTokens[currentValueTokens.length - 1] =
        "@" + name?.split(" ")?.join("&nbsp;");
    }

    const newValueTokens = currentValueTokens.join(" ");

    if (textAreaRef?.current) {
      textAreaRef.current.innerText = newValueTokens;
      setMessage(newValueTokens);
      setTryingToMention(false);
    }
  }
  return (
    <ul
      id="mentionList"
      className="absolute bg-white w-full border border-grey-400 bottom-12 rounded-md p-3 max-h-96 overflow-auto"
    >
      {memberListLoad ? (
        <>{loader}</>
      ) : memberList && memberList?.length > 0 ? (
        memberList.map((item, index) => (
          <ItemComponent
            key={item.id}
            item={item}
            itemIndex={index}
            currentIndex={currentIndex}
            handleClick={handleClick}
          />
        ))
      ) : (
        <li
          className={`flex items-center justify-center cursor-pointer rounded-md p-1 text-grey-600`}
        >
          No one to&nbsp;<i className="text-green-300">@mention</i>
        </li>
      )}
    </ul>
  );
};

export default InboxMentions;
