import { Button, Input, Spinner } from "components";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { addMemberInitialValues, validationSchema } from "../constants";
import { useMemberSignup, useMemberUpdate, useServerValidator } from "hooks";
import { Chair, Crown, X, Star } from "assets/images";
import { yupResolver } from "@hookform/resolvers/yup";
import { ADMIN, ayt, rolesArray } from "shared/resources";
import CustomSelect from "components/CustomSelect";
import { useAuth } from "hooks/useAuth";
import { useTranslation } from "react-i18next";

const roleIconLookup = {
  4: <Chair width={18} height={18} />,
  2: <Crown width={18} height={18} />,
  3: <Star width={18} height={18} />,
};

const ItemComponent = ({ item }) => {
  const { t } = useTranslation();
  const descriptionLookup = {
    4: t("overview.roleManageBilling"),
    2: t("overview.roleFullAccess"),
    3: t("overview.roleManageAccount"),
  };
  return (
    <div
      key={item.value}
      value={item?.value}
      className="flex items-start gap-3 py-2 px-3"
    >
      <div className="mt-1 text-grey-600">
        {roleIconLookup[item.value] || null}
      </div>
      <div className="flex flex-col items-start gap-1.5 ">
        <div className="text-black font-medium text-base">{item.label}</div>
        <div className="text-grey-700 text-sm leading-5">
          {descriptionLookup[item.value]}
        </div>
      </div>
    </div>
  );
};

const AddMemberModal = ({ showModal = {}, handleClose = () => null }) => {
  const { elementData = {} } = showModal;
  const { user } = useAuth();
  const isEdit = elementData && Object.keys(elementData)?.length > 0;
  const { t } = useTranslation();
  const handleSuccess = () => {
    handleClose();
  };
  const { mutateAsync: memberSignupMutate, isPending: memberSignupLoad } =
    useMemberSignup({ handleSuccess });

  const { mutateAsync: updateMember, isPending: isMemberUpdating } =
    useMemberUpdate({ handleSuccess });

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    clearErrors,
    setError,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: addMemberInitialValues,
    mode: "onChange",
  });

  useEffect(() => {
    if (isEdit) {
      const {
        first_name = "",
        last_name = "",
        email = "",
        role_id,
        phone = "",
      } = elementData;
      reset({
        email: email,
        first_name,
        last_name,
        role_id,
        phone: ayt(phone),
      });
    }
  }, [elementData]);

  const onSubmit = async (values) => {
    const { first_name, last_name, role_id, email, phone } = values;
    const payload = {
      first_name,
      last_name,
      email,
      phone,
      role_id: parseInt(role_id),
    };
    if (isEdit) {
      payload.uuid = elementData?.uuid;
      await updateMember(payload);
    } else {
      await memberSignupMutate(payload);
    }
  };
  const [watchEmail, watchPhone] = watch(["email", "phone"]);

  const {
    loadingServerError: serverEmailErrorLoading,
    isValid: isValidServerEmail,
  } = useServerValidator({
    payload: {
      watch: watchEmail,
      value: watchEmail,
      type: "email",
      key: "email",
    },
    successAction: clearErrors,
    errorAction: setError,
    delay: 700,
    shouldValidate: !isEdit && watchEmail && watchEmail?.length > 0,
  });
  const {
    loadingServerError: serverPhoneErrorLoading,
    isValid: isValidServerPhone,
  } = useServerValidator({
    payload: {
      watch: watchPhone,
      value: watchPhone,
      type: "phone",
      key: "phone",
    },
    successAction: clearErrors,
    errorAction: setError,
    delay: 700,
    shouldValidate: watchPhone && watchPhone.length > 4 && !isEdit,
  });

  const isLoading = memberSignupLoad || isMemberUpdating;
  return (
    <div className="min-w-[500px]">
      <div className="flex justify-between items-start py-4 px-6">
        <div className="flex flex-col gap-1">
          <div className="text-xl font-semibold text-black">
            {isEdit ? t("overview.updateMember") : t("overview.addMember")}
          </div>
          <div className="text-sm  text-grey-700">
            {isEdit ? t("overview.editInfo") : t("overview.enterInfoMem")}
          </div>
        </div>
        <button type="button" onClick={() => handleClose()}>
          <X className="text-grey-600 w-6 h-6" />
        </button>
      </div>
      <div className=" pb-5 pt-6 ">
        <form className="overflow-auto px-6 ">
          <div className="flex flex-col gap-4">
            <div>
              <Controller
                name="first_name"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    onWhitebg={true}
                    label={t("overview.firstName")}
                    error={errors?.first_name?.message}
                    maxLength={50}
                    required={true}
                  />
                )}
              />
            </div>
            <div>
              <Controller
                name="last_name"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    onWhitebg={true}
                    label={t("overview.lastName")}
                    error={errors?.last_name?.message}
                    maxLength={50}
                    required
                  />
                )}
              />
            </div>
            <div>
              <Controller
                name="phone"
                control={control}
                render={({ field }) => {
                  const { onChange } = field;
                  return (
                    <Input
                      {...field}
                      onWhitebg={true}
                      label={t("overview.phone")}
                      error={errors?.phone?.message}
                      autoComplete="off"
                      maxLength="17"
                      onChange={(e) => {
                        const aytNumber = ayt(e.target.value);
                        onChange(aytNumber);
                      }}
                      isServerValidationLoading={serverPhoneErrorLoading}
                      disabled={isEdit}
                      required={true}
                    />
                  );
                }}
              />
            </div>
            <div>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    onWhitebg={true}
                    label={t("overview.email")}
                    error={errors?.email?.message}
                    isServerValidationLoading={serverEmailErrorLoading}
                    maxLength={50}
                    disabled={isEdit}
                    required
                  />
                )}
              />
            </div>
            <div className="mb-2">
              <Controller
                name="role_id"
                control={control}
                render={({ field }) => (
                  <CustomSelect
                    {...field}
                    height="xxl:h-14 xl:h-12"
                    placeholder={t("overview.selectRole")}
                    onWhitebg={true}
                    label={t("overview.role")}
                    error={errors?.role_id?.message}
                    ItemComponent={ItemComponent}
                    valueKey={"value"}
                    labelKey={"label"}
                    isMulti={false}
                    maxHeight={false}
                    optionsList={
                      [ADMIN].includes(user?.role_id)
                        ? rolesArray({
                            Admin: t("overview.admin"),
                            Owner: t("overview.owner"),
                            Member: t("overview.member"),
                          }).filter((role) => role?.value !== 2)
                        : rolesArray({
                            Admin: t("overview.admin"),
                            Owner: t("overview.owner"),
                            Member: t("overview.member"),
                          })
                    }
                    required={true}
                  />
                )}
              />
              <div className="text-xs text-grey-600">
                {t("overview.limitedUser")}
              </div>
            </div>
          </div>
        </form>
        <div className="px-6 mt-4">
          <Button
            disabled={
              isLoading ||
              (watch("email") && !isValidServerEmail) ||
              !isValidServerPhone ||
              !watch("role_id")
            }
            type="button"
            onClick={handleSubmit(onSubmit)}
          >
            {isLoading ? <Spinner /> : t("overview.submit")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddMemberModal;
