/* global FB */
import { useEffect } from "react";

const EmbeddedSignup = () => {
  useEffect(() => {
    // Load the Facebook SDK
    const loadFacebookSDK = () => {
      if (!window.FB) {
        const script = document.createElement("script");
        script.src = "https://connect.facebook.net/en_US/sdk.js";
        script.async = true;
        script.defer = true;
        script.crossOrigin = "anonymous";
        script.onload = () => {
          window.fbAsyncInit = () => {
            FB.init({
              appId: "9343719928976278", // Replace with your app ID
              autoLogAppEvents: true,
              xfbml: true,
              version: "v21.0", // Replace with your Graph API version
            });
          };
        };
        document.body.appendChild(script);
      }
    };

    loadFacebookSDK();

    // Add a listener for the "message" event
    const handleMessageEvent = (event) => {
      if (
        event.origin !== "https://www.facebook.com" &&
        event.origin !== "https://web.facebook.com"
      )
        return;

      try {
        const data = JSON.parse(event.data);
        if (data.type === "WA_EMBEDDED_SIGNUP") {
          console.log("message event good: ", data); // Replace with your logic
        }
      } catch {
        console.log("message event bad: ", event.data); // Replace with your logic
      }
    };

    window.addEventListener("message", handleMessageEvent);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("message", handleMessageEvent);
    };
  }, []);

  const fbLoginCallback = (response) => {
    if (response.authResponse) {
      console.log("WhatsApp Signup Response: ", response);
    } else {
      console.log("WhatsApp Signup Failed");
    }
  };

  const launchWhatsAppSignup = () => {
    FB.login(fbLoginCallback, {
      config_id: "490887133306473",
      response_type: "code",
      override_default_response_type: true,
      extras: {
        setup: {},
        featureType: "",
        sessionInfoVersion: "3",
      },
    });
  };

  const fbGeneralLoginCallback = (response) => {
    if (response.authResponse) {
      console.log("General Login Successful: ", response);
    } else {
      console.log("General Login Failed");
    }
  };

  const launchGeneralFacebookLogin = () => {
    FB.login(fbGeneralLoginCallback, {
      scope:
        "public_profile,email,pages_messaging,instagram_basic,instagram_manage_messages",
    });
  };

  return (
    <div>
      <button
        onClick={launchWhatsAppSignup}
        style={{
          backgroundColor: "#25D366",
          border: 0,
          borderRadius: "4px",
          color: "#fff",
          cursor: "pointer",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontSize: "16px",
          fontWeight: "bold",
          height: "40px",
          marginRight: "16px",
          padding: "0 24px",
        }}
      >
        WhatsApp Signup
      </button>
      <button
        onClick={launchGeneralFacebookLogin}
        style={{
          backgroundColor: "#1877f2",
          border: 0,
          borderRadius: "4px",
          color: "#fff",
          cursor: "pointer",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontSize: "16px",
          fontWeight: "bold",
          height: "40px",
          padding: "0 24px",
        }}
      >
        General Facebook Login
      </button>
    </div>
  );
};

export default EmbeddedSignup;
