import {
  Refresh,
  Chevron,
  WhatsappBusiness,
  TelegramIcon,
  FacebookMessenger,
  InstagramMessenger,
  TwitterIcon,
  LinkedIn,
  SmsIcon,
} from "assets/images";
import React, { useRef, useState, useEffect } from "react";
import {
  Avatar,
  Button,
  Dropdown,
  EllipsisTextWithTooltip,
  Skeletons,
  Spinner,
  SubHeader,
} from "components";
import { useChart, useGetCompanyDetails } from "hooks";
import ListManager from "components/ListManager";
import { getMemberListing } from "api";
import {
  OWNER,
  formatPhoneNumber,
  roleIconLookup,
  rolesArray,
} from "shared/resources";
import { useAuth } from "hooks/useAuth";
import useDashboardStats from "hooks/useDashboardStats";
import All from "pages/Calls/All";
import { handleSipStatus } from "components/Header";
import CallStatusModal from "components/CallStatusModal";
import { useTranslation } from "react-i18next";
import "i18n";
import { Link } from "react-router-dom";

export const LoadingItem = () => {
  return (
    <div className="flex items-center justify-between border-grey-300 py-3 border-b">
      <div className="flex gap-3 items-center">
        <div className="w-9 h-9 rounded-full">
          <Skeletons type="circle" />
        </div>
        <div className="flex flex-col gap-1">
          <div className="flex items-center text-sm font-medium	 text-grey-900">
            <div className="w-24">
              <Skeletons />
            </div>
            &nbsp;&nbsp;
            <div className="text-grey-600 w-8">
              <Skeletons />
            </div>
          </div>
          <div className="text-grey-600">
            <Skeletons />
          </div>
        </div>
      </div>
      <div className="w-12 h-5 text-grey-600">
        <Skeletons />
      </div>
    </div>
  );
};

const BotsByChannel = [
  {
    id: 1,
    label: "Whatsapp Business",
    value: 1,
    icon: WhatsappBusiness,
    path: "/settings/whatsapp",
  },
  {
    id: 2,
    label: "Telegram",
    value: 0,
    icon: TelegramIcon,
    path: "/settings/telegram",
  },
  {
    id: 3,
    label: "Facebook Messenger",
    value: 0,
    icon: FacebookMessenger,
    path: "/settings/facebook",
  },
  {
    id: 4,
    label: "Instagram",
    value: 0,
    icon: InstagramMessenger,
    path: "/settings/instagram",
  },
  {
    id: 5,
    label: "Twitter",
    value: 0,
    icon: TwitterIcon,
    path: "/settings/twitter",
  },
  {
    id: 6,
    label: "LinkedIn",
    value: 0,
    icon: LinkedIn,
    path: "/settings/linkedin",
  },
  {
    id: 7,
    label: "SMS",
    value: 0,
    icon: SmsIcon,
    path: "/settings/sms-socials",
  },
];

const MemberListItem = ({ item }) => {
  const { user, onlineUsers, callData } = useAuth();
  const [selectedCallDetails, setSelectedCallDetails] = useState(null);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    setSelectedCallDetails(callData?.[selectedEmail] || null);
  }, [callData, selectedEmail]);

  const handleCallStatusClick = (email) => {
    setSelectedCallDetails(callData?.[email] || null);
    setSelectedEmail(email || null);
    setIsModalOpen(true);
  };

  const closeCallStatusModal = () => {
    setIsModalOpen(false);
    setSelectedCallDetails(null);
  };
  return (
    <>
      <div className="flex items-center justify-between border-grey-300 py-3 border-b">
        <div className="flex gap-3">
          <div className="relative">
            <Avatar
              name={`${item?.first_name} ${item?.last_name ?? ""}`}
              mediaUrl={`${process.env.REACT_APP_BASE_API_URL}${
                user?.company_uuid || user?.uuid
              }/profile/${item?.profile_pic}`}
            />
            <div className="absolute left-7 bottom-[1px]">
              {user?.email === item?.email
                ? handleSipStatus(
                    user?.default_status === "online"
                      ? "registered"
                      : user?.default_status
                  )
                : item?.default_status === "online"
                ? handleSipStatus(
                    onlineUsers.includes(item?.email)
                      ? "registered"
                      : item?.default_status
                  )
                : handleSipStatus(item?.default_status)}
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <div className="flex items-center text-sm font-medium	 text-grey-900 ">
              <div className="flex items-center gap-2">
                <EllipsisTextWithTooltip
                  string={`${item?.first_name} ${item?.last_name ?? ""}`}
                  charLength={25}
                  position="b"
                />

                <span>{item?.uuid === user?.uuid ? "(You)" : ""}</span>
              </div>
            </div>
            <div className="text-xs text-grey-600">
              {formatPhoneNumber(item?.phone)}
            </div>
          </div>
        </div>
        <div
          className={`flex text-sm text-grey-600 gap-1 ${
            callData?.[item?.email]?.status &&
            callData[item.email].status !== t("overview.idle")
              ? "cursor-pointer"
              : ""
          }`}
          onClick={() =>
            callData?.[item?.email] && handleCallStatusClick(item?.email)
          }
        >
          {callData?.[item?.email]?.status || t("overview.idle")}
        </div>
        <div
          className={`flex text-xs ${
            item?.role_id === OWNER ? "text-grey-600" : "text-grey-600"
          }  gap-1`}
        >
          {roleIconLookup[item?.role_id] || null}
          {
            rolesArray({
              Admin: t("overview.admin"),
              Owner: t("overview.owner"),
              Member: t("overview.member"),
            }).find((role) => role.value === item?.role_id)?.label
          }
        </div>
      </div>
      {isModalOpen && (
        <div
          className="fixed inset-0 z-50 animate-modalAnimation flex items-center justify-center bg-black bg-opacity-50"
          onClick={(e) => e.stopPropagation()}
        >
          <div
            className="bg-white p-10 rounded-lg w-full sm:w-[350px] h-[350px] overflow-hidden"
            onClick={(e) => e.stopPropagation()}
          >
            <CallStatusModal
              callDetails={selectedCallDetails}
              onClose={closeCallStatusModal}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default function Dashboard() {
  const { t } = useTranslation();
  const { getChart } = useChart();
  const [ismemberLoading, setIsmemberLoading] = useState(false);
  const refreshRef = useRef(null);
  const [statsType, setStatsType] = useState("weekly");
  const { isLoading, isFetching, data, refetch } = useDashboardStats({
    statsType,
  });
  const companyDetails = useGetCompanyDetails();

  function makeMemberLoading() {
    setIsmemberLoading(true);
    setTimeout(() => {
      setIsmemberLoading(false);
    }, 3000);
  }
  useEffect(() => {
    setStatsType("weekly");
  }, [t]);
  const graphData = data?.data?.data?.graph ?? [];

  return (
    <div className="bg-grey-100 body-height overflow-auto">
      <div className="">
        <SubHeader
          isLoading={isLoading}
          data={data}
          statsType={statsType}
          setStatsType={setStatsType}
        />
      </div>
      <div className="xxl:px-24 xl:px-24 lg:px-[88px] md:px-12 sm:px-3 xs:px-3  pt-5 pb-8 ">
        <div>
          <div className="flex gap-7 xxl:flex-row xl:flex-row lg:flex-row md:flex-row sm:flex-col xs:flex-col ">
            <div className="xxl:w-9/12 xl:w-[68%] lg:w-[68%] md:w-[65%]">
              <div className="flex items-center justify-between  xs:gap-1 sm:gap-6 mb-2">
                <div className="xs:text-md sm:text-xl  font-medium	 text-grey-900 flex gap-2 items-center">
                  {t("overview.callSummary")} {}
                </div>
                <div className="sm:w-32 xs:w-28">
                  <Button
                    rounded={true}
                    height="h-10"
                    extraClasses="xs:px-3  sm:px-5 max-w-[120px]"
                    background="grey-200 "
                    border="none"
                    onClick={refetch}
                  >
                    <div className="text-black flex xs:text-sm  items-center gap-2">
                      {/* {isLoading || isFetching ? (
                        <Spinner />
                      ) : ( */}
                      <span className="flex items-center gap-2">
                        {isLoading || isFetching ? (
                          <Spinner />
                        ) : (
                          <>
                            {t("overview.refresh")} <Refresh />
                          </>
                        )}
                      </span>
                      {/* )} */}
                    </div>
                  </Button>
                </div>
              </div>

              <div className="border-grey-400 border  rounded-lg  py-4 px-5 bg-white min-h-400 xxl:max-h-[450px] xxl:min-h-[450px] xl:max-h-[350px] xl:min-h-[350px] lg:max-h-[350px] lg:min-h-[350px] md:max-h-[350px] md:min-h-[350px]">
                <div className="flex items-center justify-between xxl:mb-8 xl:mb-8	lg:mb-8 md:mb-2 sm:mb-2 xs:mb-2  gap-4 relative">
                  <div className="flex items-center gap-5 ">
                    <div className="flex items-center gap-2">
                      <div className=" w-3 h-3 rounded-full bg-green-300"></div>
                      {t("overview.incoming")}
                    </div>
                    <div className="flex items-center gap-2">
                      <div className=" w-3 h-3 rounded-full bg-green"></div>
                      {t("overview.outgoing")}
                    </div>
                    <div className="flex items-center gap-2">
                      <div className=" w-3 h-3 rounded-full bg-danger"></div>
                      {t("overview.missed")}
                    </div>
                  </div>

                  <div className="relative">
                    <div className="sm:relative xs:absolute xs:-top-20 xs:right-24 md:top-0 md:right-0">
                      <Dropdown
                        dropList={{
                          component: ({ item }) => (
                            <span
                              key={item?.id}
                              className="w--full d--flex  justify-content--start py-2 px-3 "
                              onClick={() => setStatsType(item?.value)}
                            >
                              {item?.title}
                            </span>
                          ),
                          data: [
                            {
                              title: "Weekly",
                              value: "weekly",
                              id: 1,
                            },
                            {
                              title: "Monthly",
                              value: "monthly",
                              id: 2,
                            },
                          ],
                        }}
                        closeOnClickOutside={true}
                      >
                        <div className="flex text-sm	 items-center gap-5 font-medium	 bg-grey-300 cursor-pointer text-grey-900 px-4 py-2 rounded-full  capitalize md:relative  xs:mt-1">
                          {statsType}
                          <Chevron />
                        </div>
                      </Dropdown>
                    </div>
                  </div>
                </div>

                {isLoading ? (
                  <Spinner />
                ) : statsType === "weekly" ? (
                  getChart("ColumnChart", [
                    [
                      t("overview.day"),
                      t("overview.outgoing"),
                      t("overview.incoming"),
                      t("overview.missed"),
                    ],
                    [
                      t("overview.monday"),
                      graphData?.[0]?.["Outbound"] ?? 0,
                      graphData?.[0]?.["Inbound"] ?? 0,
                      graphData?.[0]?.["Missed"] ?? 0,
                    ],
                    [
                      t("overview.tuesday"),
                      graphData?.[1]?.["Outbound"] ?? 0,
                      graphData?.[1]?.["Inbound"] ?? 0,
                      graphData?.[1]?.["Missed"] ?? 0,
                    ],
                    [
                      t("overview.wednesday"),
                      graphData?.[2]?.["Outbound"] ?? 0,
                      graphData?.[2]?.["Inbound"] ?? 0,
                      graphData?.[2]?.["Missed"] ?? 0,
                    ],
                    [
                      t("overview.thursday"),
                      graphData?.[3]?.["Outbound"] ?? 0,
                      graphData?.[3]?.["Inbound"] ?? 0,
                      graphData?.[3]?.["Missed"] ?? 0,
                    ],
                    [
                      t("overview.friday"),
                      graphData?.[4]?.["Outbound"] ?? 0,
                      graphData?.[4]?.["Inbound"] ?? 0,
                      graphData?.[4]?.["Missed"] ?? 0,
                    ],
                    [
                      t("overview.saturday"),
                      graphData?.[5]?.["Outbound"] ?? 0,
                      graphData?.[5]?.["Inbound"] ?? 0,
                      graphData?.[5]?.["Missed"] ?? 0,
                    ],
                    [
                      t("overview.sunday"),
                      graphData?.[6]?.["Outbound"] ?? 0,
                      graphData?.[6]?.["Inbound"] ?? 0,
                      graphData?.[6]?.["Missed"] ?? 0,
                    ],
                  ])
                ) : (
                  getChart("ColumnChart", [
                    [
                      t("overview.day"),
                      t("overview.outgoing"),
                      t("overview.incoming"),
                      t("overview.missed"),
                    ],
                    [
                      t("overview.jan"),
                      graphData?.[0]?.["Outbound"] ?? 0,
                      graphData?.[0]?.["Inbound"] ?? 0,
                      graphData?.[0]?.["Missed"] ?? 0,
                    ],
                    [
                      t("overview.feb"),
                      graphData?.[1]?.["Outbound"] ?? 0,
                      graphData?.[1]?.["Inbound"] ?? 0,
                      graphData?.[1]?.["Missed"] ?? 0,
                    ],
                    [
                      t("overview.mar"),
                      graphData?.[2]?.["Outbound"] ?? 0,
                      graphData?.[2]?.["Inbound"] ?? 0,
                      graphData?.[2]?.["Missed"] ?? 0,
                    ],
                    [
                      t("overview.apr"),
                      graphData?.[3]?.["Outbound"] ?? 0,
                      graphData?.[3]?.["Inbound"] ?? 0,
                      graphData?.[3]?.["Missed"] ?? 0,
                    ],
                    [
                      t("overview.may"),
                      graphData?.[4]?.["Outbound"] ?? 0,
                      graphData?.[4]?.["Inbound"] ?? 0,
                      graphData?.[4]?.["Missed"] ?? 0,
                    ],
                    [
                      t("overview.jun"),
                      graphData?.[5]?.["Outbound"] ?? 0,
                      graphData?.[5]?.["Inbound"] ?? 0,
                      graphData?.[5]?.["Missed"] ?? 0,
                    ],
                    [
                      t("overview.jul"),
                      graphData?.[6]?.["Outbound"] ?? 0,
                      graphData?.[6]?.["Inbound"] ?? 0,
                      graphData?.[6]?.["Missed"] ?? 0,
                    ],
                    [
                      t("overview.aug"),
                      graphData?.[7]?.["Outbound"] ?? 0,
                      graphData?.[7]?.["Inbound"] ?? 0,
                      graphData?.[7]?.["Missed"] ?? 0,
                    ],
                    [
                      t("overview.sep"),
                      graphData?.[8]?.["Outbound"] ?? 0,
                      graphData?.[8]?.["Inbound"] ?? 0,
                      graphData?.[8]?.["Missed"] ?? 0,
                    ],
                    [
                      t("overview.oct"),
                      graphData?.[9]?.["Outbound"] ?? 0,
                      graphData?.[9]?.["Inbound"] ?? 0,
                      graphData?.[9]?.["Missed"] ?? 0,
                    ],
                    [
                      t("overview.nov"),
                      graphData?.[10]?.["Outbound"] ?? 0,
                      graphData?.[10]?.["Inbound"] ?? 0,
                      graphData?.[10]?.["Missed"] ?? 0,
                    ],
                    [
                      t("overview.dec"),
                      graphData?.[11]?.["Outbound"] ?? 0,
                      graphData?.[11]?.["Inbound"] ?? 0,
                      graphData?.[11]?.["Missed"] ?? 0,
                    ],
                  ])
                )}
              </div>
            </div>
            <div className="xxl:w-3/12 xl:w-[30%] lg:w-[30%] md:w-[35%]">
              <div className="flex items-center xs:justify-between md:justify-start gap-6 mb-2">
                <div className="xs:text-md sm:text-xl  font-medium	 text-grey-900">
                  {t("overview.members")}
                </div>
                <div className="sm:w-32 xs:w-28">
                  <Button
                    rounded={true}
                    height="h-10"
                    extraClasses=" xs:px-3  sm:px-5 max-w-[120px]"
                    background="grey-200 "
                    border="none"
                    onClick={() => {
                      refreshRef?.current?.refresh();
                      makeMemberLoading();
                    }}
                  >
                    <div className="text-black flex xs:text-sm  items-center gap-2">
                      {ismemberLoading ? (
                        <Spinner />
                      ) : (
                        <>
                          {t("overview.refresh")} <Refresh />
                        </>
                      )}
                    </div>
                  </Button>
                </div>
                {companyDetails?.data?.plan_renew_date && (
                  <span
                    style={{
                      position: "absolute",
                      right: "10px",
                      backgroundColor: "rgb(79 119 45)",
                      color: "white",
                      padding: ".8rem",
                      borderRadius: "2rem",
                      marginBottom: ".5rem",
                      fontSize: ".875rem",
                    }}
                  >
                    {t("overview.serviceExpiry")}:{" "}
                    {companyDetails?.data?.plan_renew_date?.split("T")[0]}
                  </span>
                )}
              </div>
              <div className="border-grey-400 border  rounded-lg  px-6 py-3 bg-white min-h-400 xxl:max-h-[450px] xxl:min-h-[450px] xl:max-h-[350px] xl:min-h-[350px] lg:max-h-[350px] lg:min-h-[350px] md:max-h-[350px] md:min-h-[350px] overflow-auto">
                <ListManager
                  ref={refreshRef}
                  actionFn={getMemberListing}
                  queryKey="getMemberListing"
                  listItem={MemberListItem}
                  loadingComponent={LoadingItem}
                />
              </div>
            </div>
          </div>
          <div className="mt-4 flex sm:flex-row flex-col gap-4">
            <div className="h-[280px] flex flex-col justify-center bg-white border-grey-400 border sm:w-[600px] p-4 rounded-md">
              <div className="flex justify-between items-center">
                <p className="font-bold">Current Plan</p>
                <div className="bg-green-300/40 text-green text-[12px] rounded-full px-2 py-1">
                  <p>Pro Free Trial</p>
                </div>
              </div>
              <p className="text-[12px] text-black/50 pt-1">
                Updated at 2024-11-28 10:30:37
              </p>
              <div className="flex flex-col gap-2 mt-5">
                <div className="flex justify-between items-center font-bold">
                  <p>Members</p>
                  <p>1 / 1</p>
                </div>
                <div className="flex justify-between items-center font-bold">
                  <p>Bot Users</p>
                  <p>0 / 200</p>
                </div>
                <div className="flex justify-between items-center font-bold">
                  <p>Bots</p>
                  <p>1 / 1</p>
                </div>
              </div>
              <div className="mt-7">
                <Button>Subscribe</Button>
              </div>
            </div>
            <div className="w-full rounded-md p-4 bg-white border-grey-400 border">
              <div className="flex justify-between">
                <p className="font-bold">Bots By Channel</p>
                <Link
                  to={"/settings/all-bots"}
                  className="text-[12px] text-black/50"
                >
                  View All
                </Link>
              </div>
              <div className="grid grid-cols-2 md:grid-cols-3 gap-2 mt-3">
                {BotsByChannel.map((bot, index) => {
                  return (
                    <Link
                      key={index}
                      to={bot.path}
                      className="w-full hover:shadow-md hover:bg-white py-4 rounded-md duration-300 hover:border border-grey-400 px-2"
                    >
                      <div className="flex gap-2 items-center">
                        <bot.icon className="w-6 h-5" />
                        <p className="text-[12px] text-black">{bot.value}</p>
                      </div>
                      <p>{bot.label}</p>
                    </Link>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="mt-4">
            <div className="xs:text-md sm:text-xl font-medium	 text-grey-900 mb-4">
              {t("overview.recentCallHistory")}
            </div>
            <All />
          </div>
        </div>
      </div>
    </div>
  );
}
