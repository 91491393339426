import Input from "components/Input";
import Spinner from "components/Spinner";
import useCreateBatch from "hooks/useCreateBatch";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

function AddNewBatch({ handleBatchModalClose, batchMutate }) {
  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm({
    mode: "onSubmit",
  });

  const { mutateAsync: createBatchMutate, isPending: createBatchLoading } =
    useCreateBatch();
  const { t } = useTranslation();

  return (
    <div>
      <h2 className="text-[20px] font-bold text-center mb-6">
        {t("overview.createNewBatch")}
      </h2>
      <p className="text-[18px] font-semibold">{t("overview.batchName")}</p>
      <form className="flex flex-col gap-4 my-8">
        <div>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                onWhitebg={true}
                label={t("overview.enterBatch")}
                maxLength={50}
                error={errors?.name?.message}
                required={true}
                onChange={(event) => {
                  setValue("name", event.target.value);
                }}
              />
            )}
          />
        </div>
        <div className="flex gap-4 ml-auto mt-6 justify-end items-center">
          <button
            onClick={handleBatchModalClose}
            className="border-danger border text-danger hover:bg-danger hover:text-white duration-500 w-[100px] h-[40px] rounded"
          >
            {t("overview.close")}
          </button>
          <button
            type="submit"
            className="bg-green-400 w-[100px] h-[40px] rounded flex items-center justify-center"
            onClick={(event) => {
              event.preventDefault();
              const submitForm = async (values) => {
                await createBatchMutate({
                  ...values,
                });
                handleBatchModalClose();
                batchMutate();
              };
              handleSubmit(submitForm)();
            }}
          >
            {createBatchLoading ? <Spinner /> : t("overview.submit")}
          </button>
        </div>
      </form>
    </div>
  );
}

export default AddNewBatch;
